import { TableCell, TableHead, TableRow } from '@mui/material';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const BaseTableHead = (props) => {
  const { headCells, noActions } = props;
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, headCellIndex) => (
          <Fragment key={'head-cell-' + headCell.code}>
            <TableCell
              align={headCell?.numeric ? 'right' : 'left'}
              padding={headCell?.disablePadding ? 'none' : 'normal'}
              sx={{ backgroundColor: '#F9F9F9', fontSize: '14px' }}
            >
              <div>{headCell?.label}</div>
            </TableCell>
          </Fragment>
        ))}
        {!noActions && (
          <TableCell padding='normal' align='right'>
            {t('common.table.actions')}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default BaseTableHead;
