import React from 'react';

import { useTranslation } from 'react-i18next';

const ActivePipe = ({
  percentBorder,
  percentLine,
  percent,
  percentDate,
  day,
  color,
  percentSpace,
  countLead,
  name,
}) => {
  const { t } = useTranslation();

  return (
    <div className={'flex w-full items-center'}>
      <div className={'flex items-end w-[85%] h-[51px]'}>
        <div
          style={{
            width: `${percentSpace}%`,
          }}
        />
        <div
          className={'border-l border-l-[#eaeaea] h-full border-b'}
          style={{
            width: `${percentDate}%`,
            borderBottomColor: color,
            borderBottomWidth: percentDate > 0 ? '4px' : 0,
          }}
        />
        <div
          className={
            'flex h-full text-[16px] items-end border-b border-dashed border-b-[#eaeaea] pb-[8px] text-[#838383]'
          }
          style={{
            width: `calc(${percentBorder}% - 50px)`,
          }}
        >
          {day} {t('common.fields.day')}
        </div>
        <div
          className={
            'flex items-center text-[#464255] w-[50px] pr-[7px] justify-end text-[16px] h-full font-medium'
          }
        >
          {percent}%
        </div>
        <div
          style={{
            width: `${percentLine}%`,
          }}
          className={'flex items-center w-[50%] h-full'}
        >
          <div
            style={{
              backgroundColor: color,
            }}
          />
        </div>
      </div>
      <div className={'mr-[1%] w-[14%]'}>
        <div
          className={
            'text-[#464255] text-[13px] mb-[4px] leading-[16px] font-medium'
          }
        >
          {name}
        </div>
        <div>
          <span
            className={'text-[#0061ff] text-[13px] leading-[16px] font-normal'}
          >
            {countLead}
          </span>
          <span
            className={
              'text-[##464255] text-[13px] ml-[3px] leading-[16px] font-normal'
            }
          >
            {t('common.fields.contract')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ActivePipe;
