import React, { useState } from 'react';
import { Button } from '@mui/material';
import FormTextField from 'components/ui/form/FormTextField';
import { useFormik } from 'formik';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useNotification from 'hooks/useNotification';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const validationSchema = yup.object({
  discount: yup.number(),
});

export default function Discount() {
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const sendNotification = useNotification();
  const setRefetch = () => {};
  const formik = useFormik({
    initialValues: {
      discount: '100',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      //   try {
      //     setIsSubmitting(true);
      //     const response = await axiosPrivate.post(
      //       '/admin/block/store',
      //       JSON.stringify(values),
      //       { headers: { 'Content-Type': 'application/json' } }
      //     );
      //     if (response.data && response.data.status) {
      //       sendNotification({
      //         msg: t('block.addModal.alerts.success', { value: values.name }),
      //         variant: 'success',
      //       });
      //       setIsSubmitting(false);
      //       setRefetch(true);
      //     }
      //   } catch (error) {
      //     sendNotification({
      //       msg: error?.response?.data?.message || error?.message,
      //       variant: 'error',
      //     });
      //     setIsSubmitting(false);
      //   }
    },
  });

  return (
    <div className='base-border p-5 bg-white'>
      <div className='flex justify-between items-center'>
        <div>
          <div>{t('settings.discounts.maxDiscountPercent')}</div>
          <div>{t('settings.discounts.commentForCustomer')}</div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className='flex items-center justify-end gap-2'>
            <FormTextField
              delay={0}
              fieldName='discount'
              type='number'
              readOnly
              formik={formik}
            />
            <Button variant='base' size='small'>
              <i className='bi bi-check-circle ' />
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
