import {
  CircularProgress,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import moment from 'moment';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import { Link } from 'react-router-dom';

const IncomeCalls = () => {
  const { t } = useTranslation();
  const [isCallsDataLoading, setIsCallsDataLoading] = useState(true);
  const [incomeCallsData, setIncomeCallsData] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const intObserver = useRef();

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    'incomeCalls',
    ({ pageParam = 1 }) => getCallsByPage(pageParam),
    {
      onSuccess: (data) => {
        if (data && data.pages.length > 0) {
          setIncomeCallsData([...data.pages.flat(2)]);
        }
        setIsCallsDataLoading(false);
      },
      enabled: true,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length ? allPages.length + 1 : undefined;
      },
    }
  );

  const getCallsByPage = async (pageParam = 1, options = {}) => {
    const response = await axiosPrivate.get(
      `/crm/call-history/calls?page=${pageParam}&type=income`
    );
    return response.data?.data?.data;
  };

  const lastCallRef = useCallback(
    (callD) => {
      if (isFetchingNextPage) return;

      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver((calls) => {
        if (calls[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });

      if (callD) intObserver.current.observe(callD);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  return (
    <div className='calls-history-tab-item'>
      <TableContainer className='tab-table-container' component='div'>
        <Table stickyHeader component='div'>
          <TableHead component='div'>
            <TableRow component='div'>
              <TableCell component='div'>Qo'ng'iroqlar</TableCell>
              <TableCell component='div'>Nomi</TableCell>
              <TableCell component='div'>Telefon raqami</TableCell>
              <TableCell component='div'>Davomiyligi</TableCell>
              <TableCell component='div'>Xodim</TableCell>
              <TableCell component='div'>Status</TableCell>
            </TableRow>
          </TableHead>
          {isCallsDataLoading ? (
            <TableBody className='overflow-hidden' component='div'>
              <TableRow component='div'>
                <TableCell colSpan={6}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : incomeCallsData && incomeCallsData.length > 0 ? (
            <TableBody className='overflow-hidden' component='div'>
              {incomeCallsData.map((row, rowIndex) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={'row-' + rowIndex}
                    component={Link}
                    to={`/admin/crm/lead/edit/${row?.crm_lead_id}`}
                    // target="_blank"
                    className='no-underline'
                  >
                    <TableCell size='small' component='div'>
                      <div className='flex items-center'>
                        <div className='call-icon text-base leading-4 border-2 border-blue-700 text-blue-600 rounded-full w-8 h-8 min-w-[2rem] min-h-[2rem] flex items-center justify-center mr-2'>
                          <i className='bi bi-telephone-inbound leading-4' />
                        </div>
                        <div className='text-[12px] text-gray-500'>
                          {moment(row?.created_at).format('DD/MM/YYYY HH:mm')}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell size='small' component='div'>
                      {row?.lead?.contactone?.name}
                    </TableCell>
                    <TableCell size='small' component='div'>
                      {row?.destination_number}
                    </TableCell>
                    <TableCell size='small' component='div'>
                      {moment.utc(row?.user_talk_time * 1000).format('mm:ss')}
                    </TableCell>
                    <TableCell size='small' component='div'>
                      {row?.crm_block_item?.operator?.user?.name}
                    </TableCell>
                    <TableCell size='small' component='div'>
                      {row?.status}
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow tabIndex={-1} component='div'>
                <TableCell size='small' colSpan={6}>
                  {isFetchingNextPage ? (
                    <div className='flex items-center justify-center bg-transparent rounded-lg py-2'>
                      <CircularProgress size={20} color='inherit' />
                    </div>
                  ) : (
                    <div
                      className='observable-item h-0.5 opacity-0'
                      id='observable-item-all-calls'
                      ref={lastCallRef}
                    ></div>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody component='div'>
              <TableRow component='div'>
                <TableCell colSpan={6}>
                  <span className='text-gray-400 flex items-center justify-center'>
                    <i className='bi bi-exclamation-octagon text-xl leading-4 mr-1' />{' '}
                    {t('common.global.noDataFound')}
                  </span>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
};
export default IncomeCalls;
