import React, { memo } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useFormSubmit from 'hooks/useFormSubmit';
import FormTextField from 'components/ui/form/FormTextField';
import { useAppInfo } from 'context';
import FormActionButtons from 'components/ui/form/FormActionButtons';
import { t } from 'i18next';

const validationSchema = yup.object({
  reason: yup.number().required('tasks.complete.validation.reason'),
});

export default memo(function CloseTaskForm({ data, refetch, setOpen }) {
  const [{ refetchTaskCount }] = useAppInfo();
  const { submit, isSubmitting } = useFormSubmit();
  const formik = useFormik({
    initialValues: {
      reason: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (data?.length) {
        const body = { ...values, ids: data };
        submit(
          { type: 'post', contentType: 'simple' },
          body,
          '/crm/task/done',
          'complete task',
          null,
          true,
          () => {
            refetch();
            refetchTaskCount?.();
          }
        );
      }
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='flex justify-center flex-col gap-4'>
        <FormTextField
          delay={0}
          duration={0.5}
          label={t('tasks.complete.reason')}
          fieldName={'reason'}
          formik={formik}
          multiline
          rows={3}
        />
        <div className='flex justify-end'>
          <FormActionButtons
            delay={0.5}
            isSubmitting={isSubmitting}
            formType='dialog'
            setOpen={setOpen}
            reset={formik.resetForm}
          />
        </div>
      </div>
    </form>
  );
});
