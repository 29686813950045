import { memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useTopPanel from 'hooks/useTopPanel';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { useState } from 'react';
import { useQuery } from 'react-query';
import DateSelectBox from '../../residential-complex/items/DateSelectBox';
import moment from 'moment';
import DoughnutChart from 'components/ui/charts/DoughnutChart';
import { CircularProgress, Tab, Tabs } from '@mui/material';
import StaffFilter from 'components/ui/filters/items/StaffFilter';
import { useLocation } from 'react-router-dom';

function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const Tasks = () => {
  const { t, i18n } = useTranslation();
  const { setComponent } = useTopPanel();
  const axiosPrivate = useAxiosPrivate();
  const { search } = useLocation();
  const [data, setData] = useState([]);
  const [tabValue, setTabValue] = useState('all');
  const [startAndFinishDate, setStartAndFinishDate] = useState({
    startDate: moment()
      .set('years', 2010)
      .startOf('years')
      .format('YYYY-MM-DD'),
    finishDate: moment().format('YYYY-MM-DD'),
  });

  useEffect(() => {
    setComponent(<div className='component-title'>{t('boss.crm.title')}</div>);
  }, [i18n.language]);

  const { isLoading, isFetching, isError } = useQuery({
    queryKey: ['/analytic/crm/tasks', startAndFinishDate, tabValue, search],
    queryFn: async function () {
      const response = await axiosPrivate.get(
        `/analytic/crm/tasks${search || '?'}from=${
          startAndFinishDate.startDate
        }&till=${startAndFinishDate.finishDate}${
          tabValue != 'all' ? '&status=' + tabValue : ''
        }`
      );
      return response.data.data;
    },
    onSuccess: (data) => {
      if (data && data.length > 0) {
        const formattedData = data?.map((data) => ({
          ...data,
          color: getRandomColor(),
        }));
        setData(formattedData);
      }
    },
    // enabled: !hasError,
    // onError: (error) => {
    // 	setHasError(true)
    // },
    retry: false,
  });
  const taskLabels = useMemo(() => {
    if (!data?.length) return [];
    return data?.map(({ name }) => name);
  }, [data]);
  const taskValues = useMemo(() => {
    if (!data?.length) return [];
    return data?.map(({ number }) => number);
  }, [data]);

  const taskLabelsColors = useMemo(() => {
    if (!data?.length) return [];
    return data?.map(({ color }) => color);
  }, [data]);

  const totalTask = useMemo(() => {
    if (!data?.length) return 0;
    return data?.reduce((acc, { number }) => acc + number || 0, 0);
  }, [data]);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <div>
      <div className='my-shadow-1 p-2 rounded-md'>
        <div className='flex justify-end mb-2'>
          <div className='w-[150px]'>
            <DateSelectBox
              setStartAndFinishDate={setStartAndFinishDate}
              disabled={isLoading || isFetching}
            />
          </div>
          <div className='w-[150px] ml-1'>
            <StaffFilter
              disabled={isLoading}
              label='common.fields.staff'
              query='user_id'
              size='small'
            />
          </div>
        </div>
        <div className='flex items-center justify-between mb-4'>
          <div className='residential-complex-item-title income-by-residential-complex-title'>
            Topshiriqlar - xodimlar kesimida
          </div>
          <div>
            <Tabs
              value={tabValue}
              onChange={handleChangeTab}
              sx={{ minHeight: 30 }}
            >
              <Tab
                label='Barchasi'
                value={'all'}
                sx={{ minHeight: 30, padding: '2px 16px' }}
              />
              <Tab
                label='Bajarilgan'
                value={'done'}
                sx={{ minHeight: 30, padding: '2px 16px' }}
              />
              <Tab
                label="O'tib ketgan"
                value={'left'}
                sx={{ minHeight: 30, padding: '2px 16px' }}
              />
              <Tab
                label='Kutilayotgan'
                value={'future'}
                sx={{ minHeight: 30, padding: '2px 16px' }}
              />
            </Tabs>
          </div>
        </div>

        <div className='income-by-residential-body'>
          {isError ? (
            <div className='no-data-found-wrapper h-full'>
              <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
              {t('common.global.noDataFound')}
            </div>
          ) : data.length > 0 ? (
            <div className='flex gap-4'>
              <div className='relative h-96 w-96'>
                <DoughnutChart
                  chartLabels={taskLabels}
                  chartDatasets={[
                    {
                      data: taskValues,
                      backgroundColor: taskLabelsColors,
                      borderWidth: 2,
                      hoverOffset: 15,
                      hoverBorderWidth: 0,
                      borderRadius: 4,
                    },
                  ]}
                  suffix=''
                  optionPlugins={{
                    legend: {
                      display: false,
                    },
                  }}
                  options={{
                    layout: {
                      padding: {
                        top: 10,
                        bottom: 10,
                      },
                    },
                  }}
                />
                {totalTask > 0 && (
                  <div className='text-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                    <h1 className='text-[35px]'>{totalTask}</h1>
                    <p className='text-sm text-gray-500'>Jami</p>
                  </div>
                )}
              </div>
              {/* <div className='max-h-[50vh] my-scroll overflow-y-auto'> */}
              <div className='text-sm overflow-x-auto my-scroll'>
                {data?.map((item, i) => (
                  <div
                    key={`${i}hodimlar`}
                    className='grid my-1.5 hover:bg-gray-50'
                    style={{
                      gridTemplateColumns:
                        'minmax(120px, 250px) repeat(6, 90px)',
                    }}
                  >
                    <div className='flex items-center whitespace-nowrap'>
                      <div
                        className={`min-w-[12px] mr-1 h-[12px] border-4 rounded-full`}
                        style={{ borderColor: item?.color }}
                      />
                      {item?.name}
                    </div>
                    {item?.tasks?.map((task, j) => (
                      <div key={j}>
                        <div className='flex gap-1 items-center bg-gray-100 py-0.5 px-2 rounded-md w-fit'>
                          <i
                            className={`${task?.icon} text-sm`}
                            style={{
                              color:
                                j == 0
                                  ? '#0652DD'
                                  : j == 1
                                  ? '#079992'
                                  : j == 2
                                  ? '#EA2027'
                                  : '#2f3542',
                            }}
                          />
                          {task?.number}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className='h-[260px] flex items-center justify-center'>
              <CircularProgress size={30} color='inherit' />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default memo(Tasks);
