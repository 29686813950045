import { Tab, Tabs } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useTopPanel from 'hooks/useTopPanel';
import PersonalInfo from './PersonalInfo';
import Security from './Security';

const Profile = () => {
  const { setComponent } = useTopPanel();
  const { t, i18n } = useTranslation();
  const [tab, setTab] = useState(1);

  useEffect(() => {
    setComponent(<div className='component-title'>{t('profile.title')}</div>);
  }, [i18n.language]);

  const onChange = (e, i) => setTab(i);

  return (
    <div className='w-full'>
      <div className='base-border bg-white px-4 py-1 mb-4'>
        <Tabs onChange={onChange} value={tab}>
          <Tab value={1} label={t('profile.personalInfo')} />
          <Tab value={2} label={t('profile.security')} />
        </Tabs>
      </div>
      {tab == 1 && <PersonalInfo />}
      {tab == 2 && <Security />}
    </div>
  );
};

export default memo(Profile);
