import { Button } from '@mui/material';
import BaseTable from 'components/ui/tables/BaseTable';
import useTopPanel from 'hooks/useTopPanel';
import React, { useEffect, useState } from 'react';
import StaffAddEditModal from './StaffAddEditModal';
import SimpleSearchInput from 'components/SearchInputNavbar';
import { useTranslation } from 'react-i18next';

const Staff = () => {
  const { setComponent } = useTopPanel();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setComponent(
      <div className='component-title'>{t('settings.staff.title')}</div>
    );
  }, [i18n.language]);

  const [refetch, setRefetch] = useState(false);
  const [openStaffModal, setOpenStaffModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [itemId, setItemId] = useState('');

  return (
    <div className='component-list-wrapper'>
      <div className='component-list-header mb-2'>
        <div className='header-actions-container py-3 flex flex-row items-center'>
          <div className='header-actions filter-box flex items-center px-4 w-full'>
            <Button
              variant='base'
              color='primary'
              startIcon={<i className='bi bi-filter' />}
              className='!mr-2'
            >
              {t('common.button.filter')}
            </Button>
            <SimpleSearchInput inputKey='name' />
          </div>
          <div className='header-actions action-buttons-box py-3 px-4 flex items-center justify-center'>
            <Button
              variant='base'
              color='info'
              onClick={() => {
                setRefetch(true);
              }}
              disable={`${refetch}`}
            >
              <i
                className={`bi bi-arrow-repeat${
                  refetch ? ' animate-spin' : ''
                }`}
              />
            </Button>
            <Button
              variant='base'
              color='success'
              className='!mx-2'
              onClick={() => setOpenStaffModal(true)}
            >
              <i className='bi bi-plus-circle' />
            </Button>
            <Button
              variant='base'
              color='default'
              onClick={() => setOpen(true)}
            >
              <i className='bi bi-gear' />
            </Button>
          </div>
        </div>
      </div>

      <div className='component-table-wrapper h-[calc(100vh-180px)]'>
        <BaseTable
          emitRefetch={{ refetch, setRefetch }}
          dataPath='admin/staff/index'
          emitTableColumns={{ open, setOpen }}
          tableName='staff'
          headCells={[
            { code: 'name', label: t('common.table.staffName') },
            { code: 'login', label: t('common.table.login') },
            { code: 'role', label: t('common.table.staffRole') },
          ]}
          columns={[
            { code: 'name' },
            { code: 'login' },
            {
              code: 'roles',
              type: 'nestedBadge',
              childStr: 'name',
            },
          ]}
          actionModalEdit={{
            setOpen: setOpenStaffModal,
            setItemId: setItemId,
          }}
        />
      </div>
      {openStaffModal && (
        <StaffAddEditModal
          open={openStaffModal}
          setOpen={setOpenStaffModal}
          itemId={itemId}
          setItemId={setItemId}
          setRefetch={setRefetch}
        />
      )}
    </div>
  );
};

export default Staff;
