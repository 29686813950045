import moment from 'moment';
import { memo } from 'react';

const CRMLeadCommentMessage = ({ data = {} }) => {
  return (
    <div className='lead-comment-wrapper flex items-center'>
      <div className='lead-comment-icon text-base leading-4 border-2 border-base-color text-base-color rounded-full w-[42px] h-[42px] min-w-[42px] min-h-[42px] flex items-center justify-center mr-3'>
        <i className='bi bi-person text-xl'></i>
      </div>
      <div className='lead-comment-details w-full'>
        <div className='text-[12px] text-gray-500'>
          {moment(data?.created_at).format('DD.MM.YYYY HH:mm')},{' '}
          <span className='font-semibold text-gray-700'>{data?.name}</span>
        </div>
        <div>{data?.comment}</div>
      </div>
    </div>
  );
};
export default memo(CRMLeadCommentMessage);
