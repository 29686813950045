import { Button, CircularProgress, Divider, Grid } from '@mui/material';
// import BackButton from 'components/ui/BackButton';
// import PaymentHistoryTable from 'components/ui/tables/PaymentHistoryTable';
import CurrencyFormat from 'components/ui/text-formats/CurrencyFormat';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useTopPanel from 'hooks/useTopPanel';
import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useQueries } from 'react-query';
import { Link, useParams } from 'react-router-dom';
// import CLIENT_TYPE from 'shared/clientTypeList';
import REPAIR_TYPE from 'shared/repairTypeList';
import getLabelByTypeList from 'utils/getLabelByTypeList';
// import PhoneFormat from 'components/ui/text-formats/PhoneFormat';
import ContractCancellationModal from './ContractCancellationModal';
import InfoTooltip from 'components/ui/tooltips/InfoTooltip';
import DangerTooltip from 'components/ui/tooltips/DangerTooltip';
import BaseTooltipCustomWidth from 'components/ui/tooltips/BaseTooltipCustomWidth';
// import ContractPaymentSimpleListModal from './ContractPaymentSimpleListModal';
import ImagePreviewDialog from 'components/ui/dialogs/ImagePreviewDialog';
import { Trans, useTranslation } from 'react-i18next';
import PaymentAddModal from '../payment/PaymentAddModal';
import usePermission from 'hooks/usePermission';
import ContractDownloaderByLang from './ContractDownloaderByLang';
import ContractInfoTables from './ContractInfoTables';
import ContractViewClientInfo from './ContractViewClientInfo';
import { NoImageIcon } from 'customIcons';
import AnimatedDiscountInfo from './AnimatedDiscountInfo';

const ContractView = () => {
  const { id } = useParams();

  const { t, i18n } = useTranslation();
  const { hasPermission } = usePermission();
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  // const [open, setOpen] = useState(false);
  const [totalLeftByMonth, setTotalLeftByMonth] = useState(0);
  const [cancellationOpen, setCancellationOpen] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openHomeLayoutImageDialog, setOpenHomeLayoutImageDialog] =
    useState(false);
  const { setComponent } = useTopPanel();

  const [contractQuery, paymentsListQuery] = useQueries([
    {
      queryKey: 'contractSingle',
      queryFn: async function () {
        const response = await axiosPrivate.get(`/admin/contract/edit/${id}`);
        return response.data.data;
      },
      onSuccess: (data) => {},
      enabled: !hasError && !!id,
      onError: (error) => {
        setHasError(true);
      },
      retry: false,
    },
    {
      queryKey: 'contractPaymentList',
      queryFn: async function () {
        const response = await axiosPrivate.get(`/admin/contract/list/${id}`);
        return response.data.data;
      },
      onSuccess: (data) => {
        let leftTotal = 0;
        data.forEach((item, index) => {
          if (moment(item.payment_date, 'YYYY-MM-DD').isBefore(moment())) {
            leftTotal += +item.left;
          }
        });
        setTotalLeftByMonth(leftTotal);
      },
      enabled: !hasError && !!id,
      onError: (error) => {
        setHasError(true);
      },
      retry: false,
    },
  ]);

  useEffect(() => {
    setComponent(
      <div className='flex flex-row items-center'>
        {/* <BackButton /> */}
        <div className='component-title flex flex-row'>
          {t('contract.view.title')} №{' '}
          <BaseTooltipCustomWidth
            arrow={true}
            placement='bottom'
            enterDelay={1000}
            leaveTouchDelay={0}
            title={contractQuery?.data?.name}
            width={'800px'}
            fontSize={'1rem'}
          >
            <span className='text-line-1 max-w-[800px] ml-1'>
              {contractQuery?.data?.name}
            </span>
          </BaseTooltipCustomWidth>
        </div>
      </div>
    );
  }, [contractQuery?.data?.name, i18n.language]);

  return (
    <div className='component-add-edit-wrapper mx-4'>
      {contractQuery.isLoading || contractQuery.isFetching ? (
        <div className='circular-progress-box py-5'>
          <CircularProgress size={35} />
        </div>
      ) : (
        contractQuery?.data && (
          <Fragment>
            <div className='contract-comment rounded-lg px-4 pb-3 pt-0 w-full flex gap-2 items-center justify-end'>
              {contractQuery?.data?.status != '3' &&
                contractQuery?.data?.status != '2' &&
                hasPermission('MAKE_PAYMENT') && (
                  <Button
                    // size='small'
                    color='primary'
                    variant='contained'
                    onClick={() => setOpenPaymentModal(true)}
                    startIcon={
                      <i className='bi bi-plus-circle-fill !text-sm' />
                    }
                  >
                    {t('common.button.pay')}
                  </Button>
                )}

              <Link
                className='no-underline'
                to={`/admin/client/view/${contractQuery.data?.custom?.id}`}
              >
                <Button
                  size='small'
                  variant='base'
                  startIcon={<i className='bi bi-journal-check  !text-sm' />}
                >
                  CRM
                </Button>
              </Link>

              <ContractDownloaderByLang contractQuery={contractQuery} />

              {contractQuery?.data?.status !== '3' &&
                hasPermission('CANCEL_CONTRACT') && (
                  <div className='flex gap-2 items-center'>
                    <InfoTooltip
                      arrow={true}
                      placement='top'
                      title={t('contract.view.action.refresh')}
                    >
                      <Button
                        variant='base'
                        color='info'
                        size='small'
                        sx={{ width: 40 }}
                        onClick={contractQuery.refetch}
                      >
                        <i className='bi bi-arrow-repeat' />
                      </Button>
                    </InfoTooltip>

                    {contractQuery?.data?.status == '2' && (
                      <Button color='error' variant='contained'>
                        <span>{t('contract.view.completed')}</span>
                      </Button>
                    )}
                    <DangerTooltip
                      arrow={true}
                      placement='top'
                      color='error'
                      title={t('contract.view.action.delete')}
                    >
                      <Button
                        color='error'
                        sx={{ width: 40, minWidth: 40, height: 40 }}
                        variant='contained'
                        onClick={() => setCancellationOpen(true)}
                      >
                        <i className='bi bi-x  !text-sm' />
                        {/* <span>{t('common.button.cancel')}</span> */}
                      </Button>
                    </DangerTooltip>
                  </div>
                )}
            </div>
            <div className='component-add-edit-header my-3'>
              <Grid container spacing={2} columns={{ xs: 12, sm: 12, lg: 12 }}>
                <Grid item={true} lg={2} md={2} sm={6} xs={12}>
                  <Button
                    variant='base'
                    sx={{
                      borderRadius: '15px',
                      paddingLeft: '7px',
                      paddingRight: '7px',
                      '&:hover .leading-5': {
                        color: '#fff',
                      },
                      position: 'relative',
                      overflow: 'hidden',
                      justifyContent: 'flex-start',
                    }}
                    className='!h-full'
                    fullWidth
                  >
                    <AnimatedDiscountInfo
                      data={contractQuery?.data}
                      from='sum'
                    />
                  </Button>
                </Grid>
                <Grid item={true} lg={2} md={2} sm={6} xs={12}>
                  <Button
                    variant='base'
                    sx={{
                      borderRadius: '15px',
                      paddingLeft: '7px',
                      paddingRight: '7px',
                      '&:hover .leading-5': {
                        color: '#fff',
                      },
                    }}
                    className='!h-full'
                    fullWidth
                  >
                    <div className='flex flex-col p-2 w-full items-start'>
                      <span className='text-[14px] text-[#979797] leading-5 mb-1'>
                        {t('contract.view.header.startPrice')}
                      </span>
                      <span className='text-[18px] text-black'>
                        <CurrencyFormat
                          value={contractQuery?.data?.start_price}
                          suffix={
                            contractQuery?.data?.isvalute == '1' ? ' $' : ' UZS'
                          }
                        />
                      </span>
                    </div>
                  </Button>
                </Grid>
                <Grid item={true} lg={2} md={2} sm={6} xs={12}>
                  <Button
                    variant='base'
                    sx={{
                      borderRadius: '15px',
                      paddingLeft: '7px',
                      paddingRight: '7px',
                      '&:hover .leading-5': {
                        color: '#fff',
                      },
                    }}
                    className='!h-full'
                    fullWidth
                  >
                    <div className='flex flex-col p-2 w-full items-start'>
                      <span className='text-[14px] text-[#979797] leading-5 mb-1'>
                        {t('payment.add.contract.paidSum')}
                      </span>

                      {contractQuery?.data?.isvalute == '2' ? (
                        <>
                          <span className='text-[18px] text-black'>
                            <CurrencyFormat
                              value={
                                contractQuery?.data?.totalsum -
                                contractQuery?.data?.leftsum
                              }
                              suffix={' UZS'}
                            />
                          </span>
                          <span className='text-[18px] text-black'>
                            <CurrencyFormat
                              value={
                                contractQuery?.data?.totalvalute -
                                contractQuery?.data?.leftvalute
                              }
                              suffix={' $'}
                            />
                          </span>
                        </>
                      ) : (
                        <span className='text-[18px] text-black'>
                          <CurrencyFormat
                            value={
                              contractQuery?.data?.sum -
                              contractQuery?.data?.left -
                              contractQuery?.data?.discount
                            }
                            suffix={
                              contractQuery?.data?.isvalute == '1'
                                ? ' $'
                                : ' UZS'
                            }
                          />
                        </span>
                      )}
                    </div>
                  </Button>
                </Grid>
                <Grid item={true} lg={2} md={2} sm={6} xs={12}>
                  <Button
                    variant='base'
                    sx={{
                      borderRadius: '15px',
                      paddingLeft: '7px',
                      paddingRight: '7px',
                      '&:hover .leading-5': {
                        color: '#fff',
                      },
                      position: 'relative',
                      overflow: 'hidden',
                      justifyContent: 'flex-start',
                    }}
                    className='!h-full'
                    fullWidth
                  >
                    <AnimatedDiscountInfo
                      data={contractQuery?.data}
                      from='square'
                    />
                  </Button>
                </Grid>
                <Grid item={true} lg={2} md={2} sm={6} xs={12}>
                  <Button
                    variant='base'
                    sx={{
                      borderRadius: '15px',
                      paddingLeft: '7px',
                      paddingRight: '7px',
                      '&:hover .leading-5': {
                        color: '#fff',
                      },
                    }}
                    fullWidth
                    className='!h-full'
                  >
                    <div className='flex flex-col p-2 w-full items-start'>
                      <span className='text-[14px] text-[#979797] leading-5 mb-1'>
                        {t('contract.view.header.monthlyLeft')}
                      </span>
                      <span className='text-[18px] text-black'>
                        {totalLeftByMonth ? (
                          <CurrencyFormat
                            value={totalLeftByMonth}
                            allowNegative={true}
                            suffix={
                              contractQuery?.data?.isvalute == '0'
                                ? ' UZS'
                                : ' $'
                            }
                          />
                        ) : (
                          t('contract.view.header.noMonthlyLeft')
                        )}
                      </span>
                    </div>
                  </Button>
                </Grid>
                <Grid item={true} lg={2} md={2} sm={6} xs={12}>
                  <Button
                    variant='base'
                    sx={{
                      borderRadius: '15px',
                      paddingLeft: '7px',
                      paddingRight: '7px',
                      '&:hover .leading-5': {
                        color: '#fff',
                      },
                    }}
                    className='!h-full'
                    fullWidth
                  >
                    <div className='flex flex-col p-2 w-full items-start'>
                      <span className='text-[14px] text-[#979797] leading-5 mb-1'>
                        {t('contract.view.header.leftPrice')}
                      </span>
                      {contractQuery?.data?.isvalute == '2' ? (
                        <>
                          <span className='text-[18px] text-black'>
                            <CurrencyFormat
                              value={contractQuery?.data?.leftsum}
                              suffix={' UZS'}
                            />
                          </span>
                          <span className='text-[18px] text-black'>
                            <CurrencyFormat
                              value={contractQuery?.data?.leftvalute}
                              suffix={' $'}
                            />
                          </span>
                        </>
                      ) : (
                        <span className='text-[18px] text-black'>
                          <CurrencyFormat
                            value={contractQuery?.data?.left}
                            allowNegative={true}
                            suffix={
                              contractQuery?.data?.isvalute == '1'
                                ? ' $'
                                : ' UZS'
                            }
                          />
                        </span>
                      )}
                    </div>
                  </Button>
                </Grid>
              </Grid>
            </div>
            <Grid container spacing={2} columns={{ xs: 12, sm: 12, lg: 12 }}>
              <Grid item lg={7} md={7} sm={7} xs={7}>
                <ContractViewClientInfo contractQuery={contractQuery} />
              </Grid>
              <Grid item lg={5} md={5} sm={5} xs={5}>
                <div className='component-add-edit-body h-[calc(100%-14px)]'>
                  <div className='base-border bg-white h-full rounded-[15px] p-4 my-3'>
                    <div className='text-[18px] leading-[25px] mb-5'>
                      {t('order.modal.add.title')}
                    </div>
                    <div className='base-data w-full flex gap-1 p-2 px-3 rounded-md'>
                      <div className='home-data-item flex flex-col'>
                        <span className='data-item-value'>
                          <Button
                            type='button'
                            // variant='contained'
                            className='!-mb-[10px]'
                            fullWidth
                            disabled={
                              !(
                                contractQuery?.data?.homes?.plan &&
                                contractQuery?.data?.homes?.plan?.link
                              )
                            }
                            onClick={() => setOpenHomeLayoutImageDialog(true)}
                          >
                            {contractQuery?.data?.homes?.plan &&
                            contractQuery?.data?.homes?.plan?.link ? (
                              <img
                                src={`${process.env.REACT_APP_BACKEND_URL}/${contractQuery?.data?.homes?.plan?.link}`}
                                className='!h-[233px] !w-full object-contain'
                                alt=''
                              />
                            ) : (
                              <NoImageIcon />
                            )}
                          </Button>
                        </span>
                      </div>
                      <div className='home-data flex flex-col justify-between grow'>
                        <div className='home-data-item flex gap-2 items-center rounded-md p-1 text-[14px] leading-[16px]'>
                          <span className='data-item-title font-medium text-[#979797]'>
                            {t('contract.view.homeDetail.objectName')}:
                          </span>
                          <span className='data-item-value'>
                            {contractQuery?.data?.homes?.blocks?.objects?.name}
                          </span>
                        </div>
                        <div className='home-data-item flex gap-2 items-center rounded-md p-1 text-[14px] leading-[16px]'>
                          <span className='data-item-title font-medium text-[#979797]'>
                            {t('contract.view.homeDetail.blockName')}:
                          </span>
                          <span className='data-item-value text-base'>
                            {contractQuery?.data?.homes?.blocks?.name}
                          </span>
                        </div>
                        <div className='home-data-item flex gap-2 items-center rounded-md p-1 text-[14px] leading-[16px]'>
                          <span className='data-item-title font-medium text-[#979797]'>
                            {t('contract.view.homeDetail.homeNumber')}:
                          </span>
                          <span className='data-item-value'>
                            {contractQuery?.data?.homes?.number}
                          </span>
                        </div>
                        <div className='home-data-item flex gap-2 items-center rounded-md p-1 text-[14px] leading-[16px]'>
                          <span className='data-item-title font-medium text-[#979797]'>
                            {t('contract.view.homeDetail.stage')}:
                          </span>
                          <span className='data-item-value'>
                            {contractQuery?.data?.homes?.stage}
                          </span>
                        </div>
                        <div className='home-data-item flex gap-2 items-center rounded-md p-1 text-[14px] leading-[16px]'>
                          <span className='data-item-title font-medium text-[#979797]'>
                            {t('contract.view.homeDetail.rooms')}:
                          </span>
                          <span className='data-item-value'>
                            {contractQuery?.data?.homes?.rooms}
                          </span>
                        </div>
                        <div className='home-data-item flex gap-2 items-center rounded-md p-1 text-[14px] leading-[16px]'>
                          <span className='data-item-title font-medium text-[#979797]'>
                            {t('contract.view.homeDetail.area')}:
                          </span>
                          <span className='data-item-value'>
                            {contractQuery?.data?.homes?.square}{' '}
                            <Trans i18nKey='common.global.meter'>
                              m<sup>2</sup>
                            </Trans>
                          </span>
                        </div>
                        <div className='home-data-item flex gap-2 items-center rounded-md p-1 text-[14px] leading-[16px]'>
                          <span className='data-item-title font-medium text-[#979797]'>
                            {t('contract.view.homeDetail.isrepaired')}:
                          </span>
                          <span className='data-item-value'>
                            {getLabelByTypeList(
                              REPAIR_TYPE,
                              contractQuery?.data?.isrepaired
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div>
                  <div className='mt-4 mb-6'>
                    {id && (
                      <ContractInfoTables
                        id={id}
                        contractQuery={contractQuery}
                        paymentsListQuery={paymentsListQuery}
                      />
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Fragment>
        )
      )}
      {openHomeLayoutImageDialog &&
        contractQuery?.data?.homes?.plan &&
        contractQuery?.data?.homes?.plan?.link && (
          <ImagePreviewDialog
            open={openHomeLayoutImageDialog}
            setOpen={setOpenHomeLayoutImageDialog}
            url={contractQuery?.data?.homes?.plan?.link}
          />
        )}

      {cancellationOpen && hasPermission('CANCEL_CONTRACT') && (
        <ContractCancellationModal
          open={cancellationOpen}
          setOpen={setCancellationOpen}
          data={contractQuery?.data}
          refetch={contractQuery.refetch}
        />
      )}

      {openPaymentModal && hasPermission('MAKE_PAYMENT') && (
        <PaymentAddModal
          open={openPaymentModal}
          setOpen={setOpenPaymentModal}
          refetch={() => {
            contractQuery.refetch();
            paymentsListQuery.refetch();
          }}
          data={contractQuery?.data}
        />
      )}
    </div>
  );
};

export default ContractView;
