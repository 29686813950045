import React, { memo, useEffect, useState } from 'react';
import { t } from 'i18next';
import { useQuery } from 'react-query';

import NavBarLinkItem from 'components/navbar-link/NavBarLinkItem';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { useAppInfo } from 'context';

export default memo(function TaskMenuItem({ item }) {
  const [hasError, setHasError] = useState(false);
  const [, dispatch] = useAppInfo();

  const axiosPrivate = useAxiosPrivate();
  const { data, refetch } = useQuery({
    queryKey: '/dictionary/tasksnum',
    queryFn: async function () {
      const response = await axiosPrivate.get('/dictionary/tasksnum');
      return response.data.data;
    },
    enabled: !hasError,
    onError: () => {
      setHasError(true);
    },
    retry: false,
  });

  useEffect(() => {
    dispatch({ type: 'setRefetchTaskCount', payload: refetch });
    return () => {};
  }, []);

  return (
    <NavBarLinkItem
      linkData={{
        path: item.linkData.path,
        title: (
          <div className='flex items-center justify-between w-full'>
            {t(item.linkData.title)}
            <span className='border px-1 rounded-full border-red-500 bg-red-500 text-white text-[10px] leading-[15px] !h-fit'>
              {data}
            </span>
          </div>
        ),
      }}
      iconName={item.iconName}
      delay={item.ind * 0.1}
      key={`menu-${item.ind}`}
    />
  );
});
