import { Button, ButtonBase, Menu } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import CRMFileUploadModal from './CRMFileUploadModal';
import CRMOperatorSync from './operator/CRMOperatorSync';
import useSetQuery from 'hooks/useSetQuery';
import useSearch from 'hooks/useSearch';

const CRMActionsDropDown = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const setQuery = useSetQuery();
  const query = useSearch();
  let anchorEl = document.getElementById('crm-actions-dropdown-btn');

  const menuToggle = () => {
    setOpen((prev) => !prev);
  };

  const ordering = (name) => {
    if (query.get('order_field') === name) {
      if (query.get('order') === 'DESC') {
        setQuery('order', 'ASC');
      } else {
        setQuery('order');
        setQuery('order_field');
      }
    } else {
      setQuery('order', 'DESC');
      setQuery('order_field', name);
    }
  };

  return (
    <div className='crm-actions-wrapper'>
      <div className='bg-base-color-light-f-15 rounded-lg overflow-hidden'>
        <ButtonBase
          className='crm-actions-dropdown-btn w-6 h-10 min-w-[24px]'
          id='crm-actions-dropdown-btn'
          onClick={() => menuToggle()}
        >
          <i className='bi bi-three-dots-vertical' />
        </ButtonBase>
      </div>
      <Menu
        open={open}
        anchorEl={anchorEl}
        disableScrollLock={true}
        onClose={menuToggle}
        className='crm-actions-dropdown'
        PaperProps={{
          sx: {
            marginTop: '0.5rem',
          },
        }}
      >
        <div className='px-2 flex flex-col'>
          <div
            onClick={() => setOpenImport(!openImport)}
            className='hover:bg-gray-100 cursor-pointer rounded-lg p-1'
          >
            <Button variant='action' color='success' size='small'>
              <i className='bi bi-upload' />
            </Button>
            <span className='ml-1 text-sm'>Import</span>
          </div>

          <Link
            to={'#'}
            //   to={`${process.env.REACT_APP_BACKEND_URL}/excel/debitors`}
            //   target='_blank'
            className='no-underline hover:bg-gray-100 cursor-pointer rounded-lg p-1'
          >
            <Button variant='action' color='success' size='small'>
              <i className='bi bi-download' />
            </Button>
            <span className='ml-1 text-sm'>Export</span>
          </Link>

          <div
            className='hover:bg-gray-100 cursor-pointer rounded-lg p-1'
            onClick={() => {
              navigate('/admin/crm/staff-connection');
            }}
          >
            <Button variant='action' color='info' size='small'>
              <i className='bi bi-link-45deg !text-base' />
            </Button>
            <span className='ml-1 text-sm'>
              {t('crm.block.connection.title')}
            </span>
          </div>

          <CRMOperatorSync />

          <div
            className='hover:bg-gray-100 cursor-pointer rounded-lg p-1'
            onClick={() => {
              ordering('DATE');
            }}
          >
            <Button variant='action' color='info' size='small'>
              {query.get('order_field') !== 'DATE' ? (
                <i className='bi bi-arrow-down-up !text-base' />
              ) : (
                <i
                  className={`bi ${
                    query.get('order') === 'DESC'
                      ? 'bi-arrow-down'
                      : 'bi-arrow-up'
                  } !text-base`}
                />
              )}
            </Button>
            <span className='ml-1 text-sm'>{t('crm.filter.created')}</span>
          </div>
          <div
            className='hover:bg-gray-100 cursor-pointer rounded-lg p-1'
            onClick={() => {
              ordering('CONTACT');
            }}
          >
            <Button variant='action' color='info' size='small'>
              {query.get('order_field') !== 'CONTACT' ? (
                <i className='bi bi-arrow-down-up !text-base' />
              ) : (
                <i
                  className={`bi ${
                    query.get('order') === 'DESC'
                      ? 'bi-arrow-down'
                      : 'bi-arrow-up'
                  } !text-base`}
                />
              )}
            </Button>
            <span className='ml-1 text-sm'>{t('crm.filter.lastContact')}</span>
          </div>
          <div
            className='hover:bg-gray-100 cursor-pointer rounded-lg p-1'
            onClick={() => {
              ordering('CHANGES');
            }}
          >
            <Button variant='action' color='info' size='small'>
              {query.get('order_field') !== 'CHANGES' ? (
                <i className='bi bi-arrow-down-up !text-base' />
              ) : (
                <i
                  className={`bi ${
                    query.get('order') === 'DESC'
                      ? 'bi-arrow-down'
                      : 'bi-arrow-up'
                  } !text-base`}
                />
              )}
            </Button>
            <span className='ml-1 text-sm'>{t('crm.filter.lastChange')}</span>
          </div>
        </div>
      </Menu>

      {openImport ? (
        <CRMFileUploadModal open={openImport} setOpen={setOpenImport} />
      ) : null}
    </div>
  );
};
export default CRMActionsDropDown;
