export const pipesData = [
  {
    id: 820,
    name: '',
    order: 1,
    color: '#FF7E7E',
    countLead: 0,
    countLeadPercent: 0.0,
    day: 0,
  },
  {
    id: 1305,
    name: 'Янги хает архив',
    order: 2,
    color: '#2C2DA0',
    countLead: 0,
    countLeadPercent: 0.0,
    day: 0,
  },
  {
    id: 962,
    name: 'TARMOQDA MAJVUD EMAS',
    order: 3,
    color: '#87898E',
    countLead: 0,
    countLeadPercent: 0.0,
    day: 0,
  },
  {
    id: 963,
    name: 'XONADON XARID QILGAN',
    order: 4,
    color: '#87898E',
    countLead: 6,
    countLeadPercent: 0.019,
    day: 4,
  },
  {
    id: 821,
    name: 'ЗАЯВКА',
    order: 5,
    color: '#8D8FFF',
    countLead: 136,
    countLeadPercent: 0.43,
    day: 0,
  },
  {
    id: 822,
    name: 'ЗАЯВКА ОТ СОЦ СЕТИ',
    order: 6,
    color: '#8D8FFF',
    countLead: 0,
    countLeadPercent: 0.0,
    day: 0,
  },
  {
    id: 823,
    name: 'НЕДОЗВОН',
    order: 7,
    color: '#3772FF',
    countLead: 66,
    countLeadPercent: 0.209,
    day: 4,
  },
  {
    id: 880,
    name: 'НЕДОЗВОН АНДИЖАН',
    order: 8,
    color: '#00DDBA',
    countLead: 0,
    countLeadPercent: 0.0,
    day: 0,
  },
  {
    id: 881,
    name: 'НЕДОЗВОН ФЕРГАНА',
    order: 9,
    color: '#FFC682',
    countLead: 0,
    countLeadPercent: 0.0,
    day: 0,
  },
  {
    id: 882,
    name: 'КВАЛА ПОЙТУГ',
    order: 10,
    color: '#FFC682',
    countLead: 1,
    countLeadPercent: 0.003,
    day: 14,
  },
  {
    id: 883,
    name: 'КВАЛА ЭКО СИТИ',
    order: 11,
    color: '#A799FF',
    countLead: 4,
    countLeadPercent: 0.013,
    day: 12,
  },
  {
    id: 884,
    name: 'КВАЛА СОКИН СИТИ',
    order: 12,
    color: '#F42C2C',
    countLead: 5,
    countLeadPercent: 0.016,
    day: 13,
  },
  {
    id: 885,
    name: 'КВАЛА ЯНГИ ХАЁТ',
    order: 13,
    color: '#A799FF',
    countLead: 6,
    countLeadPercent: 0.019,
    day: 9,
  },
  {
    id: 1797,
    name: 'КВАЛА АМИР ТЕМУР',
    order: 14,
    color: '#87898E',
    countLead: 57,
    countLeadPercent: 0.18,
    day: 9,
  },
  {
    id: 824,
    name: 'ВСТРЕЧА НАЗНАЧЕНА',
    order: 1001,
    color: '#1DB954',
    countLead: 8,
    countLeadPercent: 0.025,
    day: 0,
  },
  {
    id: 825,
    name: 'ЗАКРЫТО И НЕ РЕАЛИЗОВАНО',
    order: 1002,
    color: '#2C2DA0',
    countLead: 89,
    countLeadPercent: 0.282,
    day: 9,
  },
];

export const filedReasons = {
  data: {
    total: 26386,
    result: {
      'Byudjet yetarli emas': 1586,
      'Sharoitlardan qoniqmadi': 331,
      'Raqobatchidan sotib olgan': 743,
      Sababsiz: 23726,
    },
  },
};
export const filedEmployees = {
  data: {
    total: 26386,
    result: {
      '070': 0,
      745: 0,
      ABDULATIF: 17,
      Dilshod: 0,
      'Сураё call centre': 2338,
      Akmal: 0,
      'Муниса call centre': 3919,
      'Reverem House': 141,
      'Poytug`': 1174,
      'Лайло 2 call centre': 2778,
      SULTAN: 21,
      'Янги Хает': 797,
      Хуршидабону: 2827,
      '01': 2646,
      'Abrorxo`ja': 2810,
      'maali ': 0,
      Sherzod: 0,
      Jaxongir: 0,
      'Лайло 1 call centre': 438,
      'Xushnudbek 1.6': 374,
      Ulugbek: 4,
      'Marjona 1.6': 231,
      'Sugdiyona 1.6': 880,
      'Yusro 1.6': 674,
      Avazxon: 291,
      'Medina 1.6': 385,
      '007': 1,
      Abdusomat: 15,
      'Ситора call centre': 1,
      'Komola call center': 9,
      Sababsiz: 0,
    },
  },
};

export const planData = {
  data: {
    id: 29,
    pipeId: 197,
    pipeName: 'Amir Temur Avenue',
    planAmount: 0.0,
    actualAmount: 442561208245.0,
    factPipeStatusList: [
      {
        id: 6358,
        pipeStatusResponse: {
          name: 'Incoming Leads',
          orders: 1,
          color: '#87898E',
          type: 'FIRST',
          pipeId: 197,
          pipeName: 'Amir Temur Avenue',
          id: 1680,
        },
        planLeadCount: 0,
        actualLeadCount: 1,
        planFactPipeId: 29,
        startDate: '01.09.2024',
        finishDate: '30.09.2024',
      },
      {
        id: 6360,
        pipeStatusResponse: {
          name: 'PASTKI QAVAT',
          orders: 2,
          color: '#FF5722',
          type: 'SIMPLE',
          pipeId: 197,
          pipeName: 'Amir Temur Avenue',
          id: 1681,
        },
        planLeadCount: 0,
        actualLeadCount: 0,
        planFactPipeId: 29,
        startDate: '01.09.2024',
        finishDate: '30.09.2024',
      },
      {
        id: 6361,
        pipeStatusResponse: {
          name: 'ВСТРЕЧА НАЗНАЧЕНА',
          orders: 3,
          color: '#00A389',
          type: 'SIMPLE',
          pipeId: 197,
          pipeName: 'Amir Temur Avenue',
          id: 1682,
        },
        planLeadCount: 0,
        actualLeadCount: 1504,
        planFactPipeId: 29,
        startDate: '01.09.2024',
        finishDate: '30.09.2024',
      },
      {
        id: 6362,
        pipeStatusResponse: {
          name: 'ВСТРЕЧА ПРОВЕДНА',
          orders: 4,
          color: '#3772FF',
          type: 'SIMPLE',
          pipeId: 197,
          pipeName: 'Amir Temur Avenue',
          id: 1683,
        },
        planLeadCount: 0,
        actualLeadCount: 387,
        planFactPipeId: 29,
        startDate: '01.09.2024',
        finishDate: '30.09.2024',
      },
      {
        id: 6359,
        pipeStatusResponse: {
          name: 'ПОМЕЩЕНИЕ ЗАБРОНИРОВАНО',
          orders: 5,
          color: '#1DB954',
          type: 'SIMPLE',
          pipeId: 197,
          pipeName: 'Amir Temur Avenue',
          id: 1686,
        },
        planLeadCount: 0,
        actualLeadCount: 11,
        planFactPipeId: 29,
        startDate: '01.09.2024',
        finishDate: '30.09.2024',
      },
      {
        id: 6363,
        pipeStatusResponse: {
          name: 'УСПЕШНО РЕАЛИЗОВАНО',
          orders: 1001,
          color: '#4F1F9F',
          type: 'SUCCESS',
          pipeId: 197,
          pipeName: 'Amir Temur Avenue',
          id: 1684,
        },
        planLeadCount: 0,
        actualLeadCount: 195,
        planFactPipeId: 29,
        startDate: '01.09.2024',
        finishDate: '30.09.2024',
      },
      {
        id: 6364,
        pipeStatusResponse: {
          name: 'ЗАКРЫТО И НЕ РЕАЛИЗОВАНО',
          orders: 1002,
          color: '#FFC682',
          type: 'FAILED',
          pipeId: 197,
          pipeName: 'Amir Temur Avenue',
          id: 1685,
        },
        planLeadCount: 0,
        actualLeadCount: 1638,
        planFactPipeId: 29,
        startDate: '01.09.2024',
        finishDate: '30.09.2024',
      },
    ],
    factEmployeeDtoList: [
      {
        employeeCompactDto: {
          id: 1190,
          fullName: '070',
          avatar: null,
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 0,
          '#3772FF': 0,
          '#1DB954': 0,
          '#4F1F9F': 0,
          '#FFC682': 0,
        },
      },
      {
        employeeCompactDto: {
          id: 652,
          fullName: '745',
          avatar: null,
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 0,
          '#3772FF': 0,
          '#1DB954': 0,
          '#4F1F9F': 0,
          '#FFC682': 0,
        },
      },
      {
        employeeCompactDto: {
          id: 933,
          fullName: 'Ситора call centre',
          avatar: null,
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 0,
          '#3772FF': 0,
          '#1DB954': 0,
          '#4F1F9F': 0,
          '#FFC682': 0,
        },
      },
      {
        employeeCompactDto: {
          id: 457,
          fullName: 'Янги Хает',
          avatar: null,
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 0,
          '#3772FF': 0,
          '#1DB954': 0,
          '#4F1F9F': 0,
          '#FFC682': 1,
        },
      },
      {
        employeeCompactDto: {
          id: 844,
          fullName: 'Abdusomat',
          avatar: null,
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 1,
          '#3772FF': 10,
          '#1DB954': 0,
          '#4F1F9F': 1,
          '#FFC682': 2,
        },
      },
      {
        employeeCompactDto: {
          id: 934,
          fullName: 'Komola call center',
          avatar: null,
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 0,
          '#3772FF': 0,
          '#1DB954': 0,
          '#4F1F9F': 0,
          '#FFC682': 2,
        },
      },
      {
        employeeCompactDto: {
          id: 678,
          fullName: 'Xushnudbek 1.6',
          avatar: null,
        },
        factPipeStatusMap: {
          '#87898E': 1,
          '#FF5722': 0,
          '#00A389': 83,
          '#3772FF': 54,
          '#1DB954': 4,
          '#4F1F9F': 25,
          '#FFC682': 122,
        },
      },
      {
        employeeCompactDto: {
          id: 747,
          fullName: 'Yusro 1.6',
          avatar: null,
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 287,
          '#3772FF': 36,
          '#1DB954': 0,
          '#4F1F9F': 52,
          '#FFC682': 254,
        },
      },
      {
        employeeCompactDto: {
          id: 451,
          fullName: 'Reverem House',
          avatar: null,
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 0,
          '#3772FF': 0,
          '#1DB954': 0,
          '#4F1F9F': 0,
          '#FFC682': 0,
        },
      },
      {
        employeeCompactDto: {
          id: 420,
          fullName: '01',
          avatar: 'files/com-76/thumbnail/230731-112027-1ed15d76-72d2-4316.jpg',
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 2,
          '#3772FF': 2,
          '#1DB954': 1,
          '#4F1F9F': 0,
          '#FFC682': 0,
        },
      },
      {
        employeeCompactDto: {
          id: 454,
          fullName: 'Abrorxo`ja',
          avatar: 'files/com-76/thumbnail/230828-102046-f8c0e81b-9f04-4589.jpg',
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 19,
          '#3772FF': 4,
          '#1DB954': 0,
          '#4F1F9F': 1,
          '#FFC682': 24,
        },
      },
      {
        employeeCompactDto: {
          id: 456,
          fullName: 'Лайло 2 call centre',
          avatar: null,
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 27,
          '#3772FF': 3,
          '#1DB954': 0,
          '#4F1F9F': 1,
          '#FFC682': 3,
        },
      },
      {
        employeeCompactDto: {
          id: 690,
          fullName: 'Ulugbek',
          avatar: null,
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 2,
          '#3772FF': 0,
          '#1DB954': 0,
          '#4F1F9F': 0,
          '#FFC682': 0,
        },
      },
      {
        employeeCompactDto: {
          id: 643,
          fullName: 'Jaxongir',
          avatar: null,
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 0,
          '#3772FF': 0,
          '#1DB954': 0,
          '#4F1F9F': 0,
          '#FFC682': 0,
        },
      },
      {
        employeeCompactDto: {
          id: 458,
          fullName: 'Poytug`',
          avatar: null,
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 0,
          '#3772FF': 0,
          '#1DB954': 0,
          '#4F1F9F': 0,
          '#FFC682': 0,
        },
      },
      {
        employeeCompactDto: {
          id: 749,
          fullName: '007',
          avatar: null,
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 2,
          '#3772FF': 0,
          '#1DB954': 0,
          '#4F1F9F': 0,
          '#FFC682': 0,
        },
      },
      {
        employeeCompactDto: {
          id: 461,
          fullName: 'Хуршидабону',
          avatar: null,
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 44,
          '#3772FF': 2,
          '#1DB954': 0,
          '#4F1F9F': 0,
          '#FFC682': 22,
        },
      },
      {
        employeeCompactDto: {
          id: 745,
          fullName: 'Sugdiyona 1.6',
          avatar: null,
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 377,
          '#3772FF': 128,
          '#1DB954': 0,
          '#4F1F9F': 41,
          '#FFC682': 562,
        },
      },
      {
        employeeCompactDto: {
          id: 453,
          fullName: 'Сураё call centre',
          avatar: null,
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 167,
          '#3772FF': 2,
          '#1DB954': 0,
          '#4F1F9F': 5,
          '#FFC682': 16,
        },
      },
      {
        employeeCompactDto: {
          id: 648,
          fullName: 'Лайло 1 call centre',
          avatar: null,
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 13,
          '#3772FF': 1,
          '#1DB954': 0,
          '#4F1F9F': 1,
          '#FFC682': 3,
        },
      },
      {
        employeeCompactDto: {
          id: 423,
          fullName: 'ABDULATIF',
          avatar:
            'files/com-76/thumbnail/240320-192220-51637b21-f7da-4fa6.jpeg',
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 4,
          '#3772FF': 0,
          '#1DB954': 0,
          '#4F1F9F': 0,
          '#FFC682': 1,
        },
      },
      {
        employeeCompactDto: {
          id: 421,
          fullName: 'SULTAN',
          avatar: 'files/com-76/thumbnail/231214-093041-8967ac53-1c3b-418b.jpg',
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 0,
          '#3772FF': 0,
          '#1DB954': 0,
          '#4F1F9F': 0,
          '#FFC682': 0,
        },
      },
      {
        employeeCompactDto: {
          id: 411,
          fullName: 'Dilshod',
          avatar: null,
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 0,
          '#3772FF': 0,
          '#1DB954': 0,
          '#4F1F9F': 0,
          '#FFC682': 0,
        },
      },
      {
        employeeCompactDto: {
          id: 746,
          fullName: 'Marjona 1.6',
          avatar: null,
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 99,
          '#3772FF': 20,
          '#1DB954': 6,
          '#4F1F9F': 12,
          '#FFC682': 158,
        },
      },
      {
        employeeCompactDto: {
          id: 798,
          fullName: 'Medina 1.6',
          avatar: null,
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 39,
          '#3772FF': 31,
          '#1DB954': 0,
          '#4F1F9F': 28,
          '#FFC682': 221,
        },
      },
      {
        employeeCompactDto: {
          id: 445,
          fullName: 'Akmal',
          avatar: 'files/com-76/thumbnail/230731-112027-1ed15d76-72d2-4316.jpg',
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 0,
          '#3772FF': 0,
          '#1DB954': 0,
          '#4F1F9F': 0,
          '#FFC682': 0,
        },
      },
      {
        employeeCompactDto: {
          id: 649,
          fullName: 'maali ',
          avatar: 'files/com-76/thumbnail/231216-124431-f6efb599-2427-4d12.jpg',
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 0,
          '#3772FF': 0,
          '#1DB954': 0,
          '#4F1F9F': 0,
          '#FFC682': 0,
        },
      },
      {
        employeeCompactDto: {
          id: 714,
          fullName: 'Avazxon',
          avatar: null,
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 247,
          '#3772FF': 77,
          '#1DB954': 0,
          '#4F1F9F': 16,
          '#FFC682': 114,
        },
      },
      {
        employeeCompactDto: {
          id: 452,
          fullName: 'Муниса call centre',
          avatar: 'files/com-76/thumbnail/230825-115049-76e62a0f-8bc3-45e2.jpg',
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 3,
          '#3772FF': 1,
          '#1DB954': 0,
          '#4F1F9F': 0,
          '#FFC682': 5,
        },
      },
      {
        employeeCompactDto: {
          id: 653,
          fullName: 'Sherzod',
          avatar: null,
        },
        factPipeStatusMap: {
          '#87898E': 0,
          '#FF5722': 0,
          '#00A389': 0,
          '#3772FF': 0,
          '#1DB954': 0,
          '#4F1F9F': 0,
          '#FFC682': 0,
        },
      },
    ],
    startDate: '01.09.2024',
    finishDate: '30.09.2024',
  },
};

export const dataStatistics = {
  data: {
    companyLeadFieldId: 86,
    companyLeadFieldName: 'Источник',
    totalCount: 35032,
    fieldCountList: [
      {
        name: 'Холодный обзвон',
        count: 858,
        progress: 85700.0,
      },
      {
        name: 'Агент',
        count: 1566,
        progress: 156500.0,
      },
      {
        name: 'Телеграм',
        count: 25566,
        progress: 2556500.0,
      },
      {
        name: 'Фейсбук',
        count: 1320,
        progress: 131900.0,
      },
      {
        name: 'Радио',
        count: 28,
        progress: 2700.0,
      },
      {
        name: 'Флаер',
        count: 809,
        progress: 80800.0,
      },
      {
        name: 'Инстаграм',
        count: 2163,
        progress: 216200.0,
      },
      {
        name: 'Сарафан',
        count: 2201,
        progress: 220000.0,
      },
      {
        name: 'Наружная реклама (мониторы)',
        count: 460,
        progress: 45900.0,
      },
    ],
  },
};

export const dataOne = {
  data: {
    averageSum: 0.0,
    oneLeadCostCountList: [
      {
        name: 'Холодный обзвон',
        averageAmount: 0.0,
        count: 0,
        totalAmount: 0,
      },
      {
        name: 'Агент',
        averageAmount: 0.0,
        count: 0,
        totalAmount: 0,
      },
      {
        name: 'Телеграм',
        averageAmount: 0.0,
        count: 0,
        totalAmount: 0,
      },
      {
        name: 'Фейсбук',
        averageAmount: 0.0,
        count: 0,
        totalAmount: 0,
      },
      {
        name: 'Радио',
        averageAmount: 0.0,
        count: 0,
        totalAmount: 0,
      },
      {
        name: 'Флаер',
        averageAmount: 0.0,
        count: 0,
        totalAmount: 0,
      },
      {
        name: 'Инстаграм',
        averageAmount: 0.0,
        count: 0,
        totalAmount: 0,
      },
      {
        name: 'Сарафан',
        averageAmount: 0.0,
        count: 0,
        totalAmount: 0,
      },
      {
        name: 'Наружная реклама (мониторы)',
        averageAmount: 0.0,
        count: 0,
        totalAmount: 0,
      },
    ],
  },
};
