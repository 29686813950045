import { Button, CircularProgress } from '@mui/material';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { Fragment, useEffect, useRef, useState } from 'react';
import { colors } from 'shared/colors';
import WaveSurfer from 'wavesurfer.js';
import moment from 'moment';
import { Link } from 'react-router-dom';

const formWaveSurferOptions = (ref) => ({
  container: ref,
  waveColor: colors.base2,
  progressColor: colors.baseColorLight,
  cursorColor: 'transparent',
  barWidth: 2,
  barRadius: 3,
  // barGap: 3,
  normalize: true,
  responsive: true,
  height: 30,
  partialRender: true,
  fillParent: true,
  backend: 'MediaElement',
  preservePitch: true,
  audioRate: 1,
});

const speeds = [1, 1.2, 1.5, 1.7, 2];

const CRMAudioWave = ({ callId = '' }) => {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [playing, setPlay] = useState(false);
  const [speed, setSpeed] = useState(1);
  const [hasError, setHasError] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [totalTime, setTotalTime] = useState('0.0');
  const [currentTime, setCurrentTime] = useState('0.0');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [audioURL, setAudioURL] = useState('');
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);

    return () => wavesurfer.current.destroy();
  }, []);

  const handlePlayPause = () => {
    setPlay(!playing);
    wavesurfer.current.playPause();
  };

  const handleSpeed = () => {
    const indexOfSpeed = speeds.indexOf(speed);

    const newSpeed =
      speeds[indexOfSpeed == speeds?.length - 1 ? 0 : indexOfSpeed + 1];
    wavesurfer.current.setPlaybackRate(newSpeed);
    setSpeed(newSpeed);
  };

  const handleLoadCall = async (from) => {
    if (callId) {
      setIsLoading(true);
      setIsLoaded(false);
      try {
        const response = await axiosPrivate.get(`/crm/call-history/${callId}`);
        /* let a =
					"https://www.mfiles.co.uk/mp3-downloads/brahms-st-anthony-chorale-theme-two-pianos.mp3"
				if (a && a.length > 0) {
					// setAudioURL(response.data.data)
					setAudioURL(a)
					if (from == "DOWNLOAD") handleDownload(a)
					if (from == "AUDIO") handleLoadAudio(a)
				} */
        if (response.data.data && response.data.data.length > 0) {
          setAudioURL(response.data.data);
          if (from == 'DOWNLOAD') handleDownload(response.data.data);
          if (from == 'AUDIO') handleLoadAudio(response.data.data);
        } else {
          setAudioURL('');
          setNotFound(true);
          setIsLoading(false);
        }
      } catch (error) {
        setHasError(true);
        setIsLoading(false);
      }
    }
  };

  const handleLoadAudio = async (url) => {
    if (url) {
      setIsLoaded(false);
      wavesurfer.current
        .load(`${process.env.REACT_APP_BACKEND_URL}/getaduio?url=` + url)
        .then((res) => {})
        .catch((err) => {
          setHasError(true);
          setIsLoading(false);
        });

      wavesurfer.current.on('ready', function (value) {
        setIsLoading(false);
        setIsLoaded(true);
        setTotalTime(value.toFixed(1));
      });

      wavesurfer.current.on('finish', function (value) {
        setPlay(false);
      });

      wavesurfer.current.on('audioprocess', function (value) {
        if (wavesurfer.current.isPlaying()) {
          setCurrentTime(wavesurfer.current.getCurrentTime().toFixed(1));
        }
      });
    } else {
      handleLoadCall('AUDIO');
    }
  };

  const handleDownload = (url) => {
    if (url) {
      const anchorElement = document.createElement('a');
      anchorElement.href = url;
      anchorElement.download = url.split('/').pop();

      document.body.appendChild(anchorElement);
      anchorElement.click();
      document.body.removeChild(anchorElement);
      handleLoadAudio(url);
      setIsLoaded(true);
    } else {
      handleLoadCall('DOWNLOAD');
    }
  };

  return (
    <div>
      <div className='w-full flex items-center'>
        {!notFound && !isLoaded && !hasError && (
          <Fragment>
            <Button
              variant='outlined'
              // color='success'
              onClick={() => handleLoadAudio(audioURL)}
              size='small'
              className='!px-4 !min-h-[32px] !text-gray-700'
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress size={15} color='inherit' className='mr-1' />
              ) : (
                <div className='flex items-center justify-center mr-1'>
                  <i className='bi bi-play-circle text-lg flex items-center justify-center leading-4' />
                </div>
              )}
              Eshitish
            </Button>
            <Button
              variant='action'
              color='secondary'
              className='!ml-2'
              onClick={() => handleDownload(audioURL)}
              disabled={isLoading}
            >
              <i className='bi bi-download' />
            </Button>
          </Fragment>
        )}

        <div
          id='waveform'
          ref={waveformRef}
          className={`waveform${
            isLoading || hasError || notFound
              ? ' opacity-0 w-0'
              : 'opacity-100 w-full'
          }`}
        />
        {hasError ? (
          <div className='text-red-600 text-sm'>Xatolik</div>
        ) : notFound ? (
          <div className='text-red-600 text-sm'>Audio topilmadi!</div>
        ) : isLoaded ? (
          <div className='controls ml-4 flex'>
            <Button
              variant='action'
              color='secondary'
              onClick={() => handlePlayPause()}
              disabled={isLoading}
            >
              {playing ? (
                <i className='bi bi-pause-fill' />
              ) : (
                <i className='bi bi-play-fill' />
              )}
            </Button>
            <Button
              variant='action'
              color='secondary'
              className='!ml-2'
              onClick={() => handleSpeed()}
              disabled={isLoading}
            >
              {speed}
            </Button>
            <Link to={audioURL} className='no-underline'>
              <Button
                variant='action'
                color='secondary'
                className='!ml-2'
                disabled={isLoading}
              >
                <i className='bi bi-download' />
              </Button>
            </Link>
          </div>
        ) : (
          ''
        )}
      </div>
      {isLoading || hasError || !isLoaded ? (
        ''
      ) : (
        <div className='text-[12px] text-gray-500'>
          {currentTime ? moment(currentTime * 1000).format('mm:ss') : ''} /{' '}
          {totalTime ? moment(totalTime * 1000).format('mm:ss') : ''}
        </div>
      )}
    </div>
  );
};
export default CRMAudioWave;
