import { memo } from 'react';
// import { FormControl } from '@mui/material';
// import { useTranslation } from 'react-i18next';

// import FilterAccordion from '../FilterAccordion';
import DateRangeFilter from 'components/ui/filters/items/DateRangeFilter';
const DateFromToFilter = ({
  label,
  queries = ['from', 'till'],
  disabled = false,
  labels = { start: '', end: '' },
}) => {
  // const { t } = useTranslation();

  return (
    // <FilterAccordion
    //   // title={t(`tasks.filter.date.custom`)}
    //   title={label || 'Sana'}
    //   code='home-repair-type'
    // >
    //   <FormControl fullWidth color='formColor' type='filterRadioGroup'>
    <div>
      {label ? <div className='text-[12px] text-gray-400'>{label}</div> : null}
      <DateRangeFilter queries={queries} labels={labels} />
    </div>
    // </FormControl>
    // </FilterAccordion>
  );
};
export default memo(DateFromToFilter);
