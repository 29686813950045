import { Chip } from '@mui/material';
import { t } from 'i18next';
import moment from 'moment';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import { taskStatusTypeVariants } from 'shared/tableColVariantsList';

export default memo(function ColumnTaskCard({ item }) {
  const setOrderStatus = (item) => {
    let result = taskStatusTypeVariants.filter(
      (variant) => variant.code == item
    );
    if (result.length > 0) {
      return (
        <Chip
          label={t(result?.[0]?.label)}
          variant='tableBadgeMini'
          color={result[0].color}
          sx={{
            height: '20px',
            fontSize: '0.65rem',
            borderRadius: '6px',
            '& .MuiChip-label': {
              padding: '0 4px',
            },
          }}
        />
      );
    }
    return '';
  };
  return (
    <Link
      to={`/admin/crm/lead/edit/${item?.crm_lead_id}#task-${item?.id}`}
      className='task-card no-underline'
    >
      <div className='flex items-center justify-between mb-2'>
        <span className='card-date text-end text-[13px]'>
          {item?.crm_lead?.name}
        </span>
        <span className='card-date text-end text-[13px]'>
          {item?.crm_lead?.name}
        </span>
        {/* <span className='card-date text-end text-[13px]'>
          {setOrderStatus(item?.status)}
        </span> */}
      </div>
      <div className='flex items-center justify-between mb-2'>
        <span className='card-date text-end text-[13px] text-gray-500'>
          {item?.crm_lead?.contacts?.[0]?.name || ''}{' '}
          {/* {t('tasks.columns.for')} */}
        </span>
        <span
          className='card-date text-end text-[13px]'
          style={{
            color: item?.status == 1 ? '#e53535' : 'rgb(107, 114, 128)',
          }}
        >
          {moment(item?.date, 'YYYY-MM-DD HH:mm').format('DD.MM.YYYY HH:mm')}{' '}
          {t('tasks.columns.sinceDay')}
        </span>
      </div>
      <div className='flex items-center justify-between'>
        <span className='card-date text-end text-[13px] font-semibold text-blue-600 mr-4'>
          {item?.subt?.name || ''}
        </span>
        <div className='card-title text-[13px] text-[#00a389] font-semibold whitespace-nowrap overflow-hidden text-ellipsis'>
          {item?.title}
        </div>
      </div>
    </Link>
  );
});
