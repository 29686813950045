import { Link } from 'react-router-dom';
import NavBarLinkItem from 'components/navbar-link/NavBarLinkItem';
import { motion } from 'framer-motion';
import { fade } from 'utils/motion';
import { IconButton } from '@mui/material';
import BaseTooltip from '../ui/tooltips/BaseTooltip';
import useAuth from 'hooks/useAuth';
import { Trans, useTranslation } from 'react-i18next';
import useMenu from 'hooks/useMenu';
import MENU_PERMISSIONS_LIST from 'shared/menuPermissionsList';
import { memo, useMemo } from 'react';
import TaskMenuItem from './items/TaskMenuItem';
import OperatorActiveLead from './items/OperatorActiveLead';
import { useAppInfo } from 'context';

const SidebarPanel = ({ sideBarToggle, setSideBarToggle }) => {
  const [{ info }] = useAppInfo();
  const [{ user }] = useAuth();
  const { t } = useTranslation();
  const { menus, hasMenu } = useMenu();

  const memoizedMenus = useMemo(() => {
    let topMenus = [],
      bottomMenus = [];

    for (let index = 0; index < MENU_PERMISSIONS_LIST.TOP.length; index++) {
      if (hasMenu(MENU_PERMISSIONS_LIST.TOP[index].code)) {
        topMenus.push({
          ...MENU_PERMISSIONS_LIST.TOP[index],
          ind: topMenus.length,
        });
      }
    }
    for (let index = 0; index < MENU_PERMISSIONS_LIST.BOTTOM.length; index++) {
      if (hasMenu(MENU_PERMISSIONS_LIST.BOTTOM[index].code)) {
        bottomMenus.push({
          ...MENU_PERMISSIONS_LIST.BOTTOM[index],
          ind: topMenus.length + bottomMenus.length,
        });
      }
    }
    return {
      TOP: topMenus.sort((a, b) => a.order - b.order),
      BOTTOM: bottomMenus.sort((a, b) => a.order - b.order),
    };
  }, [menus]);

  return (
    <div className='sidebar-panel-wrapper overflow-y-auto h-full bg-[#F8F9FB] border-r border-r-[#E5E9EB]'>
      <div className='sidebar-panel-header h-[100px] flex items-center justify-center xs:flex-row mt-3 p-3'>
        <Link
          to={`/${
            MENU_PERMISSIONS_LIST.TOP.find((item) => item.code == menus[0])
              ?.linkData?.path
          }`}
          className='no-underline'
        >
          <motion.img
            variants={fade({
              direction: 'left',
              positionHiddenX: '-30px',
              duration: 0.5,
            })}
            initial='hidden'
            animate='show'
            src={info?.image}
            alt='logo.png'
            className='w-[100px] xs:m-0 mx-auto'
          />
        </Link>
        <div className='md:hidden close-btn-wrapper'>
          <IconButton
            variant='onlyIcon'
            color='primary'
            onClick={() => setSideBarToggle(false)}
          >
            <i className='bi bi-x' />
          </IconButton>
        </div>
      </div>

      <ul className='sidebar-links-wrapper mt-2 p-3'>
        {memoizedMenus.TOP.map((item) =>
          item.code === 'TASKS' ? (
            <TaskMenuItem key={`menu-${item.ind}`} item={item} />
          ) : (
            <NavBarLinkItem
              linkData={{
                path: item.linkData.path,
                title: t(item.linkData.title),
              }}
              iconName={item.iconName}
              delay={item.ind * 0.1}
              key={`menu-${item.ind}`}
            />
          )
        )}
        {/* </ul>

      <ul className='sidebar-links-wrapper p-3'> */}
        {memoizedMenus.BOTTOM.map((item) => (
          <NavBarLinkItem
            linkData={{
              path: item.linkData.path,
              title: t(item.linkData.title),
            }}
            iconName={item.iconName}
            delay={item.ind * 0.1}
            key={`menu-${item.ind}`}
          />
        ))}

        {user.user?.role == 3 && <OperatorActiveLead />}
        {/* <NavBarLinkItem
          linkData={{
            path: 'profile',
            title: t('sidebar.profile'),
          }}
          iconName='bi bi-person'
          delay={0}
          customTextComponent={
            <div className='flex flex-col items-start leading-4 ml-2 overflow-hidden'>
              <span className='link-title text-[0.85rem] w-full overflow-hidden overflow-ellipsis whitespace-nowrap'>
                {user?.user?.name}
              </span>
              <span className='text-gray-400 text-[12px] leading-3'>
                {user?.user?.login}
              </span>
            </div>
          }
        />

        <Button
          variant='outlined'
          onClick={logout}
          className='w-full flex items-center'
        >
          <i className='bi bi-box-arrow-left text-base mr-2' />
          <div className='text-[0.85rem] leading-3'>
            {t('sidebar.actions.logout')}
          </div>
        </Button> */}
      </ul>

      <div className='toggle-btn-wrapper'>
        {sideBarToggle ? (
          <BaseTooltip
            enterDelay={2000}
            leaveTouchDelay={0}
            title={
              <div>
                <Trans i18nKey='sidebar.actions.close'>
                  Yopish uchun <code className='toggle-btn-sign'>[</code> ni
                  bosing
                </Trans>
              </div>
            }
            arrow={true}
            placement='right'
          >
            <IconButton
              variant='toggle'
              onClick={() => setSideBarToggle(false)}
              aria-label='sidebar-toggle-button'
              aria-describedby='sidebar-toggle-button'
            >
              <i className='bi bi-caret-left' />
            </IconButton>
          </BaseTooltip>
        ) : (
          <BaseTooltip
            enterDelay={2000}
            leaveTouchDelay={0}
            title={
              <div>
                <Trans i18nKey='sidebar.actions.open'>
                  Ochish uchun <code className='toggle-btn-sign'>]</code> ni
                  bosing
                </Trans>
              </div>
            }
            arrow={true}
            placement='right'
          >
            <IconButton
              variant='toggle'
              onClick={() => setSideBarToggle(true)}
              aria-label='sidebar-toggle-button'
              aria-describedby='sidebar-toggle-button'
            >
              <i className='bi bi-caret-right' />
            </IconButton>
          </BaseTooltip>
        )}
      </div>
    </div>
  );
};

export default memo(SidebarPanel);
