import React from 'react';

const ShapeIconCrm = ({ className, onClick, color }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width='11'
      height='11'
      viewBox='0 0 11 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.71208 8.78778C7.52788 8.78778 8.99988 7.31581 8.99988 5.50001C8.99988 3.68422 7.52788 2.21222 5.71208 2.21222C3.89629 2.21222 2.42432 3.68422 2.42432 5.50001C2.42432 7.31581 3.89629 8.78778 5.71208 8.78778Z'
        stroke={color}
        strokeWidth='3.47649'
      />
    </svg>
  );
};

export default ShapeIconCrm;
