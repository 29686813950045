import React from 'react';

import { useTranslation } from 'react-i18next';

const ClosedPipes = ({
  percentLine,
  percent,
  color,
  allDate,
  countLead,
  name,
}) => {
  const { t } = useTranslation();

  return (
    <div className={'flex w-full items-center'}>
      <div className={'flex items-end w-[85%] h-[51px]'}>
        <div className={'h-full w-[calc(100%-50px)]'}>
          {allDate !== undefined ? (
            <>
              <div
                className={
                  'text-[#464255] text-[24px] font-medium leading-[29px] mb-[3px]'
                }
              >
                {allDate} {t('common.fields.day')}
              </div>
              <div
                className={
                  'text-[#838383] text-[16px] font-normal leading-[19px]'
                }
              >
                {t('boss.crm.general.averageTime')}
              </div>
            </>
          ) : undefined}
        </div>
        <div
          className={
            'flex items-center text-[#464255] w-[50px] pr-[7px] justify-end text-[16px] h-full font-medium'
          }
        >
          {percent}%
        </div>
        <div
          style={{
            width: `${percentLine}%`,
          }}
          className={'flex items-center w-[50%] h-full'}
        >
          <div
            style={{
              backgroundColor: color,
            }}
          />
        </div>
      </div>
      <div className={'mr-[1%] w-[14%]'}>
        <div
          className={
            'text-[#464255] text-[13px] mb-[4px] leading-[16px] font-medium'
          }
        >
          {name}
        </div>
        <div>
          <span
            className={'text-[#0061ff] text-[13px] leading-[16px] font-normal'}
          >
            {countLead}
          </span>
          <span
            className={
              'text-[##464255] text-[13px] ml-[3px] leading-[16px] font-normal'
            }
          >
            {t('common.fields.contract')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ClosedPipes;
