import { Button } from '@mui/material';
import React, { useState } from 'react';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useNotification from 'hooks/useNotification';
import { t } from 'i18next';
export default function TerminateAll({ setRefetch }) {
  const axiosPrivate = useAxiosPrivate();
  const sendNotification = useNotification();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const terminateAll = async () => {
    try {
      setIsSubmitting(true);
      const response = await axiosPrivate.post(
        `admin/profile/deleteall`,
        {},
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (response.data && response.data.status) {
        sendNotification({
          msg: response?.data?.message,
          variant: 'success',
        });
        setRefetch(true);
      }
      setIsSubmitting(false);
    } catch (error) {
      sendNotification({
        msg: error?.response?.data?.message || error?.message,
        variant: 'error',
      });
      setIsSubmitting(false);
    }
  };
  return (
    <Button
      color='error'
      variant='contained'
      onClick={terminateAll}
      disabled={isSubmitting}
    >
      {t('common.button.terminateAll')}
    </Button>
  );
}
