import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { colors } from 'shared/colors';
// import FilterAccordion from '../FilterAccordion';
import { Trans } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const HomeAreaFilter = ({ min = 0, max = 1, disabled = false }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [marks, setMarks] = useState(
    Array.from({ length: max - min }, (value, index) => ({
      label: min + index,
      value: index + 1,
    }))
  );
  const [value, setValue] = useState([]);

  const handleChange = (event, val) => {
    if (JSON.stringify(value) != JSON.stringify(val)) {
      setValue(val);
    }
  };

  const handleChangeCommitted = (event, val) => {
    let entries = Object.fromEntries(searchParams);
    Object.keys(entries).forEach((item) => {
      if (item && (item == 'from' || item == 'till')) {
        searchParams.delete(item);
      }
    });
    searchParams.set('from', marks.find((item) => item.value == val[0])?.label);
    searchParams.set('till', marks.find((item) => item.value == val[1])?.label);
    setSearchParams(searchParams);
  };

  const setDefaultArea = () => {
    let entries = Object.fromEntries(searchParams);
    let newValue = [];
    Object.keys(entries).forEach((item) => {
      if (item && (item == 'from' || item == 'till')) {
        if (searchParams.get(item)) {
          newValue.push(parseInt(searchParams.get(item)));
        }
      }
    });
    if (newValue.length > 1) {
      setValue(
        newValue
          .sort((a, b) => a - b)
          .map((item) => marks.find((m) => m.label == item)?.value)
      );
    } else {
      setValue([1, marks.length]);
    }
  };

  useEffect(() => {
    setDefaultArea();
  }, [searchParams]);

  return (
    <div className='mt-3'>
      <div className='filter-title'>
        Maydoni (m<sup>2</sup>)
      </div>
      <div className='mt-5 mx-2 -mb-2'>
        <CustomSlider
          min={1}
          max={marks.length}
          valueLabelDisplay='on'
          marks={marks}
          step={1}
          value={value}
          onChange={handleChange}
          onChangeCommitted={handleChangeCommitted}
          disabled={disabled}
          disableSwap
          sx={{
            '.Mui-disabled .MuiSlider-valueLabel, .MuiSlider-valueLabelOpen': {
              backgroundColor: 'transparent',
              color: 'var(--base-color)',
              transform: 'translateY(-60%)',
            },
            '.Mui-disabled .MuiSlider-valueLabel': {
              color: 'gray',
            },
          }}
          getAriaValueText={(value) => (
            <span className='!text-base-color'>
              {value}
              <span>
                {' '}
                <Trans i18nKey='common.global.meter'>
                  m<sup>2</sup>
                </Trans>
              </span>
            </span>
          )}
          valueLabelFormat={(value) => (
            <span>
              {value}
              <span>
                {' '}
                <Trans i18nKey='common.global.meter'>
                  m<sup>2</sup>
                </Trans>
              </span>
            </span>
          )}
          scale={(value) => marks.find((item) => item.value == value)?.label}
        />
      </div>
    </div>
  );
};

const CustomSlider = styled(Slider)({
  color: colors.baseColor,
  height: 3,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 16,
    width: 16,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '&.Mui-disabled': {
    '.MuiSlider-valueLabel': {
      backgroundColor: colors.base3,
    },
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 10,
    backgroundColor: colors.baseColorLight,
    borderRadius: '4px',
    lineHeight: 1,
    paddingRight: '4px',
    paddingLeft: '4px',
  },
  '& .MuiSlider-mark': {
    backgroundColor: colors.base2,
    height: 0,
    width: 2,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
  },
  '& .MuiSlider-markLabel': {
    display: 'none',
  },
  marginBottom: 0,
});

export default HomeAreaFilter;
