import React, { useState } from 'react';
import { useQuery } from 'react-query';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { CircularProgress } from '@mui/material';
import SearchInputNavbar from './items/SearchInputNavbar';
import GenericSelectFilter from '../ui/filters/items/GenericSelectFilter';

export default function CrmFieldsFilter() {
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['/crm/crm-settings/fields'],
    queryFn: async function () {
      const response = await axiosPrivate.get('/crm/crm-settings/fields');
      return response.data.data;
    },
    enabled: !hasError,
    onSuccess: (res) => {},
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });
  return (
    <>
      {isLoading || isFetching ? (
        <div className='circular-progress-box my-4'>
          <CircularProgress size={25} />
        </div>
      ) : (
        data
          ?.sort((a, b) => (a?.type == 'SELECT' ? -1 : 1))
          ?.map((field, i) => (
            <div className='item-wrapper'>
              {field?.type === 'SELECT' && (
                <GenericSelectFilter
                  options={field?.options || []}
                  disabled={hasError}
                  query={`fields[${field?.id}]`}
                  label={field?.name || ''}
                  optionProp={{ label: 'name', value: 'name' }}
                  className='!min-h-[34px] !h-[34px] !my-[5px] !text-[12px]'
                  marginContainer='0'
                />
              )}
              {field?.type === 'TEXT' && (
                <SearchInputNavbar
                  inputKey={`fields[${field?.id}]`}
                  label={field?.name || ''}
                  size='small'
                  height='34px'
                  className='!my-[5px]'
                />
              )}
              {field?.type === 'NUMBER' && (
                <SearchInputNavbar
                  inputKey={`fields[${field?.id}]`}
                  label={field?.name || ''}
                  size='small'
                  type='number'
                  height='34px'
                  className='!my-[5px]'
                />
              )}
            </div>
          ))
      )}
    </>
  );
}
