import React from 'react';

const ProgressUpIcon = () => {
  return (
    <svg width="26" height="15" viewBox="0 0 26 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.07485 13.1642C0.774235 13.4491 0.761547 13.9238 1.04651 14.2244C1.33147 14.5251 1.80618 14.5378 2.10679 14.2528L1.07485 13.1642ZM9.13921 6.5531L9.67344 6.0267C9.38668 5.73568 8.91975 5.72771 8.62324 6.00879L9.13921 6.5531ZM14.6817 12.1781L14.1475 12.7045C14.2907 12.8498 14.487 12.9306 14.691 12.928C14.895 12.9255 15.0892 12.84 15.2288 12.6911L14.6817 12.1781ZM25.2495 1.68441C25.2362 1.27041 24.8898 0.94557 24.4758 0.958863L17.7293 1.17549C17.3153 1.18879 16.9905 1.53518 17.0038 1.94918C17.0171 2.36318 17.3635 2.68801 17.7775 2.67472L23.7744 2.48216L23.9669 8.47907C23.9802 8.89307 24.3266 9.21791 24.7406 9.20461C25.1546 9.19132 25.4794 8.84493 25.4662 8.43093L25.2495 1.68441ZM11.9105 9.3656L12.4447 8.8392L11.9105 9.3656ZM2.10679 14.2528L9.65518 7.09741L8.62324 6.00879L1.07485 13.1642L2.10679 14.2528ZM15.2288 12.6911L25.047 2.22151L23.9528 1.19544L14.1347 11.6651L15.2288 12.6911ZM8.60497 7.0795L11.3762 9.892L12.4447 8.8392L9.67344 6.0267L8.60497 7.0795ZM11.3762 9.892L14.1475 12.7045L15.216 11.6517L12.4447 8.8392L11.3762 9.892Z"
        fill="#0CAF61"
      />
    </svg>
  );
};

export default ProgressUpIcon;
