import React, { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Button, Box, Tabs, Tab } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import useAxiosPrivate from 'hooks/useAxiosPrivate';
import moment from 'moment';
import { MonthView, DayView, WeekView } from './CalendarTabs';
import TaskCompleteModal from './TaskCompleteModal';

// const dateFormat = 'DD-MM-YYYYTHH:mm:ss'
const dateFormat = 'YYYY-MM-DD';

export default function Calendar() {
  const { search } = useLocation();
  const { t, i18n } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  const [selected, setSelected] = useState(null);
  const [tabIndex, setTabIndex] = useState('day');
  const [dateRange, setDateRange] = useState({
    from: moment().startOf('day').format(dateFormat),
    till: moment().endOf('day').format(dateFormat),
  });

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
    const date = moment();
    if (newValue === 'day') {
      setDateRange(() => ({
        from: date.startOf('day').format(dateFormat),
        till: date.endOf('day').format(dateFormat),
      }));
    }
    if (newValue === 'week') {
      setDateRange(() => ({
        from: date.startOf('week').format(dateFormat),
        till: date.endOf('week').format(dateFormat),
      }));
    }
    if (newValue === 'month') {
      setDateRange(() => ({
        from: date.startOf('month').format(dateFormat),
        till: date.endOf('month').format(dateFormat),
      }));
    }
  };

  const params = new URLSearchParams(search);
  params.set('from', dateRange?.from);
  params.set('till', dateRange?.till);
  const newUrl = `?${params.toString()}`;

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['tasksList', search, dateRange?.from, dateRange?.till],
    queryFn: async function () {
      const response = await axiosPrivate.get(`/crm/task/daily${newUrl || ''}`);
      return response.data.data;
    },
    enabled: !hasError && Boolean(dateRange?.from) && Boolean(dateRange?.till),
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const titleOfTable = useMemo(() => {
    const from = moment(dateRange?.from, dateFormat);
    if (i18n.language === 'uz') moment.locale('uz-latn');
    if (i18n.language === 'ru') moment.locale('ru');
    if (i18n.language === 'uz_kr') moment.locale('uz');

    if (tabIndex === 'day') return from.format('MMMM YYYY');
    if (tabIndex === 'week') return from.add(3, 'days').format('MMMM YYYY');
    if (tabIndex === 'month') return from.format('MMMM YYYY');
  }, [tabIndex, i18n.language, dateRange]);

  const prevDate = useCallback(() => {
    const from = moment(dateRange?.from, dateFormat);
    if (tabIndex === 'day') {
      setDateRange({
        from: from.subtract(1, 'day').startOf('day').format(dateFormat),
        till: from.endOf('day').format(dateFormat),
      });
    }
    if (tabIndex === 'week') {
      setDateRange({
        from: from.subtract(1, 'week').startOf('week').format(dateFormat),
        till: from.endOf('week').format(dateFormat),
      });
    }
    if (tabIndex === 'month') {
      setDateRange({
        from: from.subtract(1, 'month').startOf('month').format(dateFormat),
        till: from.endOf('month').format(dateFormat),
      });
    }
  }, [dateRange, tabIndex]);

  const nextDate = useCallback(() => {
    const from = moment(dateRange?.from, dateFormat);
    if (tabIndex === 'day') {
      setDateRange({
        from: from.add(1, 'days').startOf('day').format(dateFormat),
        till: from.endOf('day').format(dateFormat),
      });
    }
    if (tabIndex === 'week') {
      setDateRange({
        from: from.add(1, 'week').startOf('week').format(dateFormat),
        till: from.endOf('week').format(dateFormat),
      });
    }
    if (tabIndex === 'month') {
      setDateRange({
        from: from.add(1, 'months').startOf('month').format(dateFormat),
        till: from.endOf('month').format(dateFormat),
      });
    }
  }, [dateRange, tabIndex]);

  return (
    <Box className='base-table w-full h-[calc(100%-75px)] flex flex-col tasks-wrapper'>
      <div className='flex justify-between items-center'>
        <div className='flex justify-between items-center gap-2'>
          <Button size='small' onClick={prevDate} sx={{ minWidth: 30 }}>
            <i className='bi bi-chevron-left' />
          </Button>
          <h1 className='capitalize'>{titleOfTable}</h1>
          <Button size='small' onClick={nextDate} sx={{ minWidth: 30 }}>
            <i className='bi bi-chevron-right' />
          </Button>
        </div>
        <Tabs
          value={tabIndex}
          onChange={handleChangeTab}
          sx={{ minHeight: 30, height: 30 }}
        >
          <Tab label='Kun' sx={{ minHeight: 30, height: 30 }} value={'day'} />
          <Tab
            label='Hafta'
            sx={{ minHeight: 30, height: 30 }}
            value={'week'}
          />
          <Tab label='Oy' sx={{ minHeight: 30, height: 30 }} value={'month'} />
        </Tabs>
      </div>
      {tabIndex === 'day' && (
        <DayView
          data={data?.tasks || []}
          setDateRange={setDateRange}
          setSelected={setSelected}
          date={dateRange.from}
          dateRange={dateRange}
          isLoading={isLoading || isFetching}
        />
      )}
      {tabIndex === 'week' && (
        <WeekView
          data={data?.tasks || []}
          setDateRange={setDateRange}
          setSelected={setSelected}
          date={dateRange.from}
          dateRange={dateRange}
          setTabIndex={setTabIndex}
          isLoading={isLoading || isFetching}
        />
      )}
      {tabIndex === 'month' && (
        <MonthView
          data={data?.tasks || []}
          date={dateRange.from}
          isLoading={isLoading || isFetching}
          setTabIndex={setTabIndex}
          setDateRange={setDateRange}
          setSelected={setSelected}
        />
      )}
      <TaskCompleteModal
        open={Boolean(selected)}
        data={selected}
        setData={setSelected}
      />
    </Box>
  );
}
