import { Button, CircularProgress, Divider } from '@mui/material';
import React, { memo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import useFormSubmit from 'hooks/useFormSubmit';
import LeadConnectorModal from './LeadConnectorModal';
import DisconnectButton from './DisconnectButton';

export default memo(function LeadConnector({ query, clientQuery }) {
  const [open, setOpen] = useState(false);
  const { submit, isSubmitting } = useFormSubmit();
  const { id } = useParams();

  const onClick = (lead, closeFn = null) => {
    // handle lead connection
    submit(
      { type: 'post', contentType: 'formData' },
      { crm_lead_id: lead?.id },
      `/admin/custom/leadconnect/${id}`,
      { title: `${lead?.name} muvaffaqqiyatli biriktirildi!` },
      id,
      true,
      closeFn ? closeFn : query?.refetch
    );
  };

  const openLeadsModal = () => {
    setOpen(true);
  };

  return (
    <div className='relative my-shadow-1 h-[calc(100vh-116px)] mt-4 rounded-lg p-6'>
      <div className='header'>Mijoz raqamlari o’xshash</div>
      <Divider className='!my-3' />
      {query.isLoading || query.isFetching ? (
        <div className='circular-progress-box py-5'>
          <CircularProgress size={35} />
        </div>
      ) : (
        <div className='!max-h-[calc(100%-56px)] h-[calc(100%-56px)] overflow-y-auto my-scroll'>
          {clientQuery?.data?.leads?.map((lead) => (
            <div
              key={lead?.id}
              className='lead-item my-3 border border-[#E5E9EB] rounded-lg p-4 hover:bg-[#F7F7F7] transition-all duration-300'
            >
              <div className='mb-2 flex justify-between items-center'>
                <span>
                  {lead?.lead?.name}
                  <Link
                    className='no-underline'
                    to={`/admin/crm/lead/edit/${lead?.id}`}
                  >
                    <Button
                      size='small'
                      sx={{ minWidth: '40px', width: '40px' }}
                    >
                      <i className='bi bi-box-arrow-up-right'></i>
                    </Button>
                  </Link>
                </span>
                <div>
                  <DisconnectButton
                    icon={<i className='bi bi-x text-lg'></i>}
                    link={`/admin/custom/deletconnect/${lead?.id}`}
                    refetch={() => {
                      query.refetch();
                      clientQuery.refetch();
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
          <Divider className='!my-3' />
          {query?.data?.map((lead) => (
            <div
              key={lead?.id}
              className='lead-item my-3 border border-[#E5E9EB] rounded-lg p-4 hover:bg-[#F7F7F7] transition-all duration-300'
            >
              <div className='mb-2 flex justify-between items-center'>
                <span>{lead?.name}</span>
                <Link
                  className='no-underline'
                  to={`/admin/crm/lead/edit/${lead?.id}`}
                >
                  <Button size='small' sx={{ minWidth: '40px', width: '40px' }}>
                    <i className='bi bi-box-arrow-up-right'></i>
                  </Button>
                </Link>
              </div>
              <div>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  variant='contained'
                  onClick={() => onClick(lead)}
                >
                  Biriktirish
                </Button>
              </div>
            </div>
          ))}

          <Button
            variant='base'
            className='!mt-3'
            fullWidth
            onClick={openLeadsModal}
          >
            Lead biriktirish
          </Button>
        </div>
      )}
      <LeadConnectorModal
        open={open}
        clientRefetch={clientQuery?.refetch}
        handleClose={() => setOpen(false)}
        connecting={onClick}
      />
    </div>
  );
});
