import { t } from 'i18next';
import moment from 'moment';
import { memo } from 'react';
import CRM_DATA_CHANGE_MESSAGE_TYPE from 'shared/crmDataChangeMessageTypeList';

const CRMDataChangeMessage = ({ data = {} }) => {
  return (
    <div className='data-change-wrapper flex items-center'>
      <div className='data-change-icon text-base leading-4 border-2 border-orange-400 text-orange-500 rounded-full w-[42px] h-[42px] min-w-[42px] min-h-[42px] flex items-center justify-center mr-3'>
        <i className='bi bi-pen text-lg leading-4'></i>
      </div>
      <div className='data-change-details w-full'>
        <div className='text-[12px] text-gray-500'>
          {moment(data?.created_at).format('DD.MM.YYYY HH:mm')}, {data?.name}{' '}
          {t('crm.leadHistory.fromUser')}
        </div>
        {data?.field && (data?.old_relation_value || data?.old_value) ? (
          <div className='mt-2'>
            {CRM_DATA_CHANGE_MESSAGE_TYPE[data?.field]?.label}
            <span className='change-value old-value py-1 px-1.5 mx-1 rounded-lg bg-orange-200 text-orange-900 text-[13px] leading-4'>
              {data[`old_${CRM_DATA_CHANGE_MESSAGE_TYPE[data?.field]?.value}`]}
            </span>
            {t('crm.leadHistory.from')}
            <span className='change-value new-value py-1 px-1.5 mx-1 rounded-lg bg-green-200 text-green-900 text-[13px] leading-4'>
              {data[`new_${CRM_DATA_CHANGE_MESSAGE_TYPE[data?.field]?.value}`]}
            </span>
            {t('crm.leadHistory.toChange')}!
          </div>
        ) : (
          <div className='mt-2'>
            {CRM_DATA_CHANGE_MESSAGE_TYPE[data?.field]?.label}
            <span className='change-value new-value py-1 px-1.5 mx-1 rounded-lg bg-green-200 text-green-900 text-[13px] leading-4'>
              {data[`new_${CRM_DATA_CHANGE_MESSAGE_TYPE[data?.field]?.value}`]}
            </span>
            {t('crm.leadHistory.toMark')}!
          </div>
        )}
      </div>
    </div>
  );
};
export default memo(CRMDataChangeMessage);
