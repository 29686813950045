import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import React from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { TextField } from '@mui/material';
import { motion } from 'framer-motion';
import { fadeUp } from 'utils/motion';
import formLocalizedHelperText from 'utils/formLocalizedHelperText';

const FormDateField2 = ({
  delay = 0,
  formik,
  label,
  fieldName,
  minDate = null,
  readOnly = false,
  disabled = false,
  variant = 'outlined',
}) => {
  const getFieldValue = () => {
    if (fieldName && fieldName.includes('.')) {
      let a = fieldName.split('.');
      let b = formik.values;
      for (let i = 0; i < a.length; i++) {
        b = b?.[a?.[i]];
      }
      return b;
    }
    return formik.values?.[fieldName] || '';
  };
  const getFieldValidation = (formikF) => {
    if (fieldName && fieldName.includes('.')) {
      let a = fieldName.split('.');
      let b = formik[formikF];
      for (let i = 0; i < a.length; i++) {
        if (b?.[a?.[i]]) {
          b = b?.[a?.[i]];
        } else {
          b = false;
        }
      }
      return b;
    }
    return formik?.[formikF]?.[fieldName] || '';
  };
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        id={`${fieldName}-date-picker`}
        openTo='day'
        value={getFieldValue() || ''}
        onChange={(newValue) =>
          formik.setFieldValue(
            fieldName,
            moment(newValue).format('YYYY-MM-DD'),
            true
          )
        }
        views={['year', 'month', 'day']}
        inputFormat='DD/MM/yyyy'
        minDate={minDate}
        readOnly={readOnly}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            component={motion.div}
            variants={fadeUp(30, 'tween', delay, 0.5)}
            initial='hidden'
            animate='show'
            viewport={{ once: true, amount: 0.25 }}
            color='formColor'
            variant={variant}
            fullWidth
            id={fieldName}
            name={fieldName}
            label={label}
            error={
              getFieldValidation('touched') &&
              Boolean(getFieldValidation('errors'))
            }
            helperText={
              getFieldValidation('touched') &&
              formLocalizedHelperText(getFieldValidation('errors'))
            }
            autoComplete='off'
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default FormDateField2;
