import { FormControl, FormControlLabel, FormGroup, Radio } from '@mui/material';
import FilterAccordion from '../FilterAccordion';
import { useTranslation } from 'react-i18next';
import useSetQuery from 'hooks/useSetQuery';
import useSearch from 'hooks/useSearch';

const data = [
  { key: 0, id: undefined, name: 'Barchasi' },
  { key: 1, id: 'NO_ANSWER', name: 'Javobsiz' },
  { key: 2, id: 'CONVERSATION', name: 'Gaplashilgan vaqt' },
  { key: 3, id: 'USER_BUSY', name: 'Foydalanuvchi band' },
  { key: 4, id: 'UNALLOCATED_NUMBER', name: "Mavjud bo'lmagan raqam" },
];

const CrmCallTypeFilter = ({ disabled = false }) => {
  const { t } = useTranslation();
  const setQuery = useSetQuery();
  const query = useSearch();

  const handleRepairTypeChange = (id, checked) => {
    if (checked) {
      setQuery('status', id);
    } else {
      setQuery('status');
    }
  };

  return (
    <FilterAccordion title={"Qo'ng'iroq turi"} code='home-repair-type'>
      <FormControl fullWidth color='formColor' type='filterRadioGroup'>
        <FormGroup
          aria-labelledby='repair-type-radio-buttons-group'
          name='repair-type-radio-buttons-group-name'
          onChange={(event) =>
            handleRepairTypeChange(event.target.value, event.target.checked)
          }
        >
          {data &&
            data?.length &&
            data?.map((option) => (
              <div className='mb-1 w-full' key={option.id}>
                <FormControlLabel
                  className='w-full'
                  value={option?.id}
                  checked={Boolean(query.get('status') == option?.id)}
                  control={<Radio />}
                  label={t(option.name)}
                  disabled={disabled}
                />
              </div>
            ))}
        </FormGroup>
      </FormControl>
    </FilterAccordion>
  );
};
export default CrmCallTypeFilter;
