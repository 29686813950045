import { Button } from '@mui/material';
import SimplePaginationTable from 'components/ui/tables/SimplePaginationTable';
import usePermission from 'hooks/usePermission';
import useTopPanel from 'hooks/useTopPanel';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SearchInputNavbar from 'components/SearchInputNavbar';
import ObjectAddModal from './ObjectAddModal';

const Objects = () => {
  const { setComponent } = useTopPanel();
  const { hasPermission } = usePermission();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setComponent(<div className='component-title'>{t('object.title')}</div>);
  }, [i18n.language]);

  const [refetch, setRefetch] = useState(false);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className='component-list-wrapper'>
      <div className='component-list-header mb-2'>
        <div className='header-actions-container py-3 flex flex-row items-center'>
          {/* <div className='header-actions filter-box flex items-center rounded-lg px-4 w-full h-14'></div> */}
          <div className='grow'>
            <SearchInputNavbar inputKey='name' fullWidth />
          </div>
          <div className='header-actions action-buttons-box  rounded-lg flex items-center justify-center ml-4'>
            {hasPermission('ADD_OBJECTS') && (
              <Button
                variant='contained'
                color='primary'
                className='!mr-2 !min-h-[45px] !h-[45px] !shadow-none'
                onClick={() => {
                  //   navigate('/admin/object/add');
                  setOpenModal(true);
                }}
                startIcon={<i className='bi bi-plus-circle-fill !text-lg' />}
              >
                {t('object.addTitle')}
              </Button>
            )}
            <Button
              variant='base'
              onClick={() => {
                setRefetch(true);
              }}
              disabled={refetch}
            >
              <i
                className={`bi bi-arrow-repeat ${
                  refetch ? 'animate-spin' : ''
                }`}
              />
            </Button>
            <Button
              variant='base'
              className='!ml-2'
              onClick={() => setOpen(true)}
              disabled={refetch}
            >
              <i className='bi bi-gear' />
            </Button>
          </div>
        </div>
      </div>

      <div className='component-table-wrapper'>
        <SimplePaginationTable
          emitRefetch={{ refetch, setRefetch }}
          dataPath='dictionary/objects'
          emitTableColumns={{ open, setOpen }}
          tableName='objects'
          headCells={[
            { code: 'id', label: '#' },
            { code: 'name', label: t('common.table.name') },
            { code: 'regions', label: t('common.table.regionName') },
            { code: 'companies', label: t('common.table.companyName') },
            { code: 'city', label: t('common.table.city') },
            { code: 'address', label: t('common.table.address') },
            { code: 'start', label: t('common.table.startDate') },
            { code: 'end', label: t('common.table.endDate') },
            { code: 'stage', label: t('common.table.homeFloor') },
            { code: 'padez', label: t('common.table.blocksNumber') },
          ]}
          columns={[
            { code: 'id' },
            { code: 'name' },
            { code: 'regions', type: 'nested', childStr: 'name' },
            { code: 'companies', type: 'nested', childStr: 'name' },
            { code: 'city' },
            { code: 'address' },
            { code: 'start', type: 'date' },
            { code: 'end', type: 'date' },
            { code: 'stage' },
            { code: 'padez' },
          ]}
        />
      </div>
      <ObjectAddModal
        open={openModal}
        setOpen={setOpenModal}
        refetch={() => setRefetch(true)}
      />
    </div>
  );
};

export default Objects;
