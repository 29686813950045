import { Badge, Button, Menu } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const NotificationMenu = () => {
  const [invisible, setInvisible] = useState(false);
  const { t } = useTranslation();
  // const axiosPrivate = useAxiosPrivate()
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className='notification-menu-wrapper ml-auto'>
      <Button
        variant='base'
        size='small'
        sx={{ padding: '6px 12px' }}
        rotate='true'
        onClick={handleClick}
      >
        <Badge badgeContent={0} invisible={invisible} color='secondary'>
          <i className='bi bi-bell'></i>
        </Badge>
      </Button>
      <Menu
        anchorEl={anchorEl}
        id='notification-menu'
        className='notification-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        disableScrollLock={true}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <div className='p-4 text-base text-gray-400'>
          {t('common.global.noNotifications')}
        </div>
      </Menu>
    </div>
  );
};

export default NotificationMenu;
