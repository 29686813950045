import { IconButton, InputAdornment, TextField } from '@mui/material';
import useDebounceSearch from 'hooks/useDebounceSearch';
import React from 'react';
import { colors } from 'shared/colors';

const SearchInputNavbar = ({ inputKey, ...props }) => {
  const [searchName, setSearchName] = useDebounceSearch(inputKey, '', 300);

  return (
    <TextField
      {...props}
      color='primary'
      variant='outlined'
      fullWidth
      id='search'
      name='search'
      autoComplete='off'
      className={`max-w-none ${props?.className}`}
      value={searchName}
      onInput={(event) => setSearchName(event.target?.value)}
      InputProps={{
        type: props?.type || 'text',
        endAdornment: (
          <InputAdornment
            position='end'
            className='custom-startAdornment ml-[8px]'
          >
            {searchName ? (
              <IconButton onClick={() => setSearchName('')} variant='onlyIcon'>
                <i className='bi bi-x end-adornment-close-button' />
              </IconButton>
            ) : (
              <IconButton variant='onlyIcon' disabled disableRipple>
                <i className='bi bi-search text-base leading-4' />
              </IconButton>
            )}
          </InputAdornment>
        ),
        sx: {
          height: props?.height || '45px',
        },
      }}
      sx={{
        '& .MuiOutlinedInput-root.Mui-focused i': {
          color: colors.baseColor,
        },
        '& .MuiOutlinedInput-root i': {
          color: colors.grayColor,
        },
        '& fieldset': {},
        background: 'white',
        borderRadius: '10px',
        '& .MuiInputBase-root': {
          height: props?.height || '36px',
          minHeight: props?.height || '36px',
        },
      }}
    />
  );
};

export default SearchInputNavbar;
