import React, { Fragment, memo } from 'react';
import ShaxmatkaRow from './ShaxmatkaRow';
import HOME_TYPE from 'shared/homeTypeList';
import { useTranslation } from 'react-i18next';
import ShaxmatkaPodezdItem from './ShaxmatkaPodezdItem';

const ShaxmatkaBlockItem = ({
  blockItem,
  blockIndex,
  toggleSelectionItem = () => {},
}) => {
  const block = {
    id: blockItem?.id,
    name: blockItem?.name,
    objects_id: blockItem?.objects_id,
    room_number: blockItem?.room_number,
  };
  return (
    <div>
      <div className='my-2 ml-10 leading-4'>
        <span className='font-medium text-lg text-line-1'>
          {blockItem?.name}
        </span>
      </div>
      <div className='sheet-column flex gap-0'>
        {blockItem &&
          blockItem?.padezd?.length > 0 &&
          blockItem?.padezd.map((padezd, index) => (
            <div
              className='sheet-column'
              key={`block-${padezd?.id}-columns`}
              id={`block-${padezd?.id}-columns`}
            >
              <ShaxmatkaPodezdItem
                block={block}
                padezdItem={padezd}
                padezdIndex={index}
                blockIndex={blockIndex}
                toggleSelectionItem={toggleSelectionItem}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default memo(ShaxmatkaBlockItem);
