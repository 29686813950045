import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chart.js/auto';
import { numericFormatter } from 'react-number-format';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const DoughnutChart = ({
  chartDatasets = [],
  chartLabels = [],
  options = {},
  optionPlugins = {},
  plugins = [],
  suffix = ' UZS',
}) => {
  return (
    <Doughnut
      options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: 'top',
            reverse: true,
            labels: {
              usePointStyle: true,
              pointStyle: 'rectRounded',
            },
          },
          tooltip: {
            usePointStyle: true,
            callbacks: {
              label: function (tooltipItem) {
                return ` ${numericFormatter(tooltipItem.raw.toString(), {
                  decimalScale: 3,
                  thousandSeparator: ' ',
                  allowNegative: false,
                  suffix,
                })}`;
              },
              labelPointStyle: function () {
                return {
                  pointStyle: 'rectRounded',
                  rotation: 0,
                };
              },
            },
          },
          ...optionPlugins,
        },
        ...options,
      }}
      plugins={plugins}
      data={{ labels: chartLabels, datasets: chartDatasets }}
    />
  );
};

export default DoughnutChart;
