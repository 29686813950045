/** @format */

import { useReducer, createContext, useContext } from 'react';
import { initialState, reducer } from './reducer';

export const AppInfoContext = createContext();

export const useAppInfo = () => useContext(AppInfoContext);

export const AppInfoProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppInfoContext.Provider value={[state, dispatch]}>
      {children}
    </AppInfoContext.Provider>
  );
};
