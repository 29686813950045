import { Fade } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { NumericFormat } from 'react-number-format';
import { Trans, useTranslation } from 'react-i18next';
import CurrencyFormat from 'components/ui/text-formats/CurrencyFormat';
import { formatMultiplies } from 'utils/formatOddDecimal';
import HOME_TYPE from 'shared/homeTypeList';
import { memo } from 'react';

const ShaxmatkaHomeTooltip = (props) => {
  const { t } = useTranslation();

  const priceForSquare =
    props?.home?.isrepaired == 0
      ? props?.home?.norepaired
      : props?.home?.isrepaired == 1
      ? props?.home?.repaired
      : props?.home?.deal_price;

  const price = formatMultiplies([priceForSquare, props?.home?.square], 2);

  return (
    <Tooltip
      {...props}
      placement='top'
      TransitionComponent={Fade}
      arrow
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -10],
              },
            },
          ],
        },
      }}
      title={
        <div className='shaxmatka-home-tooltip-details'>
          <div className={`home-rooms status-${props?.home?.status}`}>
            {props?.home?.rooms}x
          </div>
          <div className='home-title'>
            {props?.home?.status == HOME_TYPE.ACTIVE.code ? (
              <>
                {props?.home?.deal_price && props?.home?.square ? (
                  <CurrencyFormat
                    value={price}
                    suffix={props?.home?.isvalute == '1' ? ' $' : ' UZS'}
                    decimalScale={1}
                  />
                ) : (
                  ''
                )}
              </>
            ) : props?.home?.status == HOME_TYPE.TIME.code ? (
              t('shaxmatka.homeType.ordered')
            ) : props?.home?.status == HOME_TYPE.ORDERED.code ? (
              t('shaxmatka.homeType.sold')
            ) : props?.home?.status == HOME_TYPE.DISABLED.code ? (
              t('shaxmatka.homeType.disabled')
            ) : (
              ''
            )}
          </div>
          <div className='home-additional-details'>
            <div className='home-number'>№{props?.home?.number}</div>
            <div className='home-square-and-price-per-square'>
              {props?.home?.square ? (
                <>
                  <NumericFormat
                    value={props?.home?.square}
                    allowNegative={false}
                    displayType={'text'}
                    decimalScale={2}
                    className='w-full text-right'
                  />{' '}
                  <Trans i18nKey='common.global.meter'>
                    m<sup>2</sup>
                  </Trans>{' '}
                  -{' '}
                </>
              ) : (
                ''
              )}
              {priceForSquare ? (
                <>
                  <CurrencyFormat
                    value={priceForSquare}
                    suffix={props?.home?.isvalute == '1' ? ' $' : ' UZS'}
                    decimalScale={1}
                  />
                  /
                  <Trans i18nKey='common.global.meter'>
                    m<sup>2</sup>
                  </Trans>
                </>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      }
      classes={{
        tooltip: 'shaxmatka-home-tooltip-wrapper',
        arrow: 'shaxmatka-home-tooltip-arrow',
      }}
    />
  );
};

export default memo(ShaxmatkaHomeTooltip);
