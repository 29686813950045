import { Button, Drawer } from '@mui/material';
import HomeTypeFilter from './items/HomeTypeFilter';
import HomeAreaFilter from './items/HomeAreaFilter';
import HomeStageFilter from './items/HomeStageFilter';
import HomeRepairTypeFilter from './items/HomeRepairTypeFilter';
import HomeRoomsFilter from './items/HomeRoomsFilter';
import ObjectsFilter from './items/ObjectsFilter';
import { useLocation, useNavigate } from 'react-router-dom';
import { t } from 'i18next';

const DashboardFilterDrawer = ({
  open,
  setOpen,
  filterData = {},
  isLoading = false,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleResetFiler = () => {
    navigate(location.pathname, { replace: true });
  };

  return (
    <Drawer
      anchor='left'
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: { padding: '18px', background: 'transparent', boxShadow: 'none' },
      }}
    >
      <div className='dashboard-filter-drawer-wrapper !pt-2 first-letter bg-white h-full rounded-lg border border-[#E5E9EB]'>
        <div className='mb-3 text-end flex justify-between items-center border-b'>
          <div className='text-[16px] leading-[16px] font-semibold'>
            {t('common.button.filter')}
          </div>
          <Button
            color='error'
            // variant='outlined'
            className='!-mr-[5px]'
            size='small'
            disabled={isLoading}
            onClick={() => handleResetFiler()}
          >
            {t('common.button.clear')}
          </Button>
        </div>
        <HomeTypeFilter disabled={isLoading} />
        <HomeAreaFilter
          min={parseInt(filterData?.minsquare)}
          max={parseInt(filterData?.maxsquare)}
          disabled={isLoading}
        />
        <HomeStageFilter
          filterStages={parseInt(filterData?.maxstage)}
          disabled={isLoading}
        />
        <HomeRepairTypeFilter disabled={isLoading} />
        <HomeRoomsFilter filterRooms={filterData?.room} disabled={isLoading} />
        <ObjectsFilter objects={filterData?.objects} disabled={isLoading} />
      </div>
    </Drawer>
  );
};
export default DashboardFilterDrawer;
