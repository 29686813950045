import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import FormActionButtons from 'components/ui/form/FormActionButtons';
import FormTextField from 'components/ui/form/FormTextField';
import { useFormik } from 'formik';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useNotification from 'hooks/useNotification';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const validationSchema = yup.object({
  login: yup.string().required('settings.staff.validation.login'),
});

const LoginChangeModal = (props) => {
  const { open, setOpen, setRefetch, data } = props;
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const sendNotification = useNotification();

  const formik = useFormik({
    initialValues: {
      login: data?.login || '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);
        const response = await axiosPrivate.post(
          '/admin/profile/updatelogin',
          JSON.stringify(values),
          { headers: { 'Content-Type': 'application/json' } }
        );
        if (response.data && response.data.status) {
          sendNotification({
            msg: response?.data?.message,
            variant: 'success',
          });
          setIsSubmitting(false);
          setRefetch(true);
          handleClose();
        }
      } catch (error) {
        sendNotification({
          msg: error?.response?.data?.message || error?.message,
          variant: 'error',
        });
        setIsSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (data?.login) {
      formik.setFieldValue('login', data?.login);
    }
  }, [data]);

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      disableEscapeKeyDown={true}
      PaperProps={{
        className: '!base-border !shadow-none',
      }}
    >
      <DialogTitle id='alert-dialog-title' className='bg-gray-100'>
        <span>{t('profile.changeLogin')}</span>
      </DialogTitle>

      <DialogContent className='!mt-4'>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            spacing={{ xs: 2, sm: 3, lg: 3 }}
            rowSpacing={1}
            columns={{ xs: 12, sm: 12, lg: 12 }}
          >
            <Grid item={true} lg={12} sm={12} xs={12}>
              <FormTextField
                delay={0.1}
                label={t('profile.username')}
                fieldName='login'
                formik={formik}
                prefix={'@'}
              />
            </Grid>

            <Grid item={true} sm={12} xs={12}>
              <div className='flex justify-end'>
                <FormActionButtons
                  delay={0.2}
                  isSubmitting={isSubmitting}
                  formType='dialog'
                  setOpen={setOpen}
                  reset={formik.resetForm}
                />
              </div>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default LoginChangeModal;
