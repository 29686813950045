import React, { memo, useEffect, useState } from 'react';
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControl,
  Button,
  Divider,
} from '@mui/material';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useDebounce from 'hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import { colors } from 'shared/colors';
import { useParams } from 'react-router-dom';

export default memo(function SearchLeadModal({
  open,
  setOpen,
  checkedList,
  setCheckedList,
  setOrderModalOpen,
}) {
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [isNoDataFound, setIsNoDataFound] = useState(false);
  const [searchTxt, setSearchTxt] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const { id } = useParams();

  const searchTxtDebounced = useDebounce(searchTxt, 300);

  const handleKeyDown = async (event) => {
    if (event.keyCode == 13) {
      await handleSearch();
    }
  };

  const handleSearch = async () => {
    if (searchTxtDebounced && searchTxtDebounced.length >= 3) {
      setIsLoading(true);
      setIsNoDataFound(false);
      try {
        let response = await axiosPrivate.post(
          '/crm/crm-lead/search',
          JSON.stringify({ search: searchTxtDebounced }),
          {
            headers: { 'Content-Type': 'application/json' },
          }
        );
        if (
          response &&
          response.data &&
          response.data.status &&
          response.data.data
        ) {
          setSearchResults(response.data.data);
          setIsNoDataFound(response.data.data.length == 0 || false);
          setCheckedList([id]);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const getHighlightedValue = (value) => {
    return value && value.length > 0
      ? value.replaceAll(
          searchTxtDebounced,
          `<span class="bg-base-color-disabled text-white">${searchTxtDebounced}</span>`
        )
      : '';
  };

  useEffect(() => {
    if (searchTxtDebounced) {
      handleSearch();
    } else {
    }
  }, [searchTxtDebounced]);

  const handleClose = () => {
    setCheckedList([id]);
    setOpen(false);
  };
  const onCombine = () => {
    setOrderModalOpen(true);
    setOpen(false);
  };
  const onChange = (e, checked) => {
    const { value } = e?.target;
    if (checked) {
      setCheckedList((list) => [...list, value]);
    } else {
      setCheckedList((list) => list?.filter((id) => id != value));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      PaperProps={{
        sx: { minWidth: 720, boxShadow: 'none' },
        className: '!base-border',
      }}
      disableEscapeKeyDown={true}
    >
      <DialogTitle id='order-view-dialog-title' className='!bg-gray-100'>
        <div className='text-xl text-center'>Lid qidirish</div>
      </DialogTitle>

      <DialogContent>
        <div className='my-2 min-w-[500px]'>
          <div className='crm-lead-search-wrapper'>
            <TextField
              color='secondary'
              variant='outlined'
              fullWidth
              id='crm-search-field'
              name='crm-search-field'
              label={t('common.global.search')}
              value={searchTxt}
              onChange={(event) => setSearchTxt(event.target.value)}
              onKeyDown={handleKeyDown}
              autoComplete='off'
              onFocus={() => setOpen(true)}
              className='h-[40px]'
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position='end'
                    className='custom-endAdornment'
                  >
                    {searchTxt ? (
                      <IconButton
                        onClick={() => {
                          setSearchTxt('');
                          setSearchResults([]);
                        }}
                        variant='onlyIcon'
                      >
                        <i className='bi bi-x end-adornment-close-button' />
                      </IconButton>
                    ) : (
                      <IconButton variant='onlyIcon' disabled disableRipple>
                        <i className='bi bi-search text-base leading-4' />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiOutlinedInput-root.Mui-focused i': {
                  color: colors.baseColorLight,
                },
                '& .MuiOutlinedInput-root i': {
                  color: colors.grayColor,
                },
              }}
            />

            {isLoading ? (
              <div className='flex items-center justify-center my-[14px]'>
                <CircularProgress size={20} color='inherit' />
              </div>
            ) : searchResults && searchResults.length > 0 ? (
              <FormControl className='!w-full'>
                {searchResults.map(
                  (item) =>
                    item?.id != id && (
                      <label key={item?.id}>
                        <div className='flex gap-1 w-full'>
                          <Checkbox
                            value={item?.id}
                            id={item?.id}
                            onChange={onChange}
                          />

                          <MenuItem fullWidth className='!grow'>
                            <div className='text-gray-900 text-sm leading-5'>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: getHighlightedValue(item?.name),
                                }}
                              ></div>
                              {item?.contacts &&
                                item?.contacts.length > 0 &&
                                item?.contacts.map((contact, contactInd) => (
                                  <div
                                    key={`item-contact-${contactInd}`}
                                    className='text-gray-700 text-[12px] leading-4'
                                  >
                                    {contact?.phones &&
                                      contact?.phones.length > 0 &&
                                      contact?.phones.map((phone, phoneInd) => (
                                        <div
                                          key={`contact-${contactInd}-phone-${phoneInd}`}
                                          dangerouslySetInnerHTML={{
                                            __html: getHighlightedValue(
                                              phone?.phone
                                            ),
                                          }}
                                        ></div>
                                      ))}
                                  </div>
                                ))}
                            </div>
                          </MenuItem>
                        </div>
                      </label>
                    )
                )}
              </FormControl>
            ) : (
              isNoDataFound && (
                <div>
                  <span className='no-data-found-wrapper select-box my-2'>
                    <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
                    {t('common.global.noDataFound')}
                  </span>
                </div>
              )
            )}
          </div>
        </div>
        <div className='mb-4 mt-6'>
          <Divider />
        </div>
        <div className='flex justify-end items-center gap-2'>
          <Button variant='outlined' color='error' onClick={handleClose}>
            Bekor qilish
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={onCombine}
            disabled={checkedList?.length < 2}
          >
            Biriktirish
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
});
