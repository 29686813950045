import React, { memo, useEffect, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';

// import BaseTable from 'components/ui/tables/BaseTable';
import useTopPanel from 'hooks/useTopPanel';
// import SimpleSearchInput from 'components/SimpleSearchInput';
// import EventsFilterDrawer from 'components/dashboard/EventsFilterDrawer';
import Discount from './Tabs/Discount';
import DiscountSettings from './Tabs/DiscountSettings';
// import DiscountTerm from './Tabs/DiscountTerm';

const Discounts = () => {
  const { setComponent } = useTopPanel();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setComponent(
      <div className='component-title'>{t('settings.discounts.title')}</div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const [tabIndex, setTabIndex] = useState(1);
  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <div className='component-list-wrapper'>
      <div className='component-list-header mb-2'>
        <div className='grid grid-cols-[250px,1fr] gap-5'>
          <div>
            <Box
              sx={{
                background: '#fff',
                padding: '5px',
                borderRadius: '15px',
              }}
              className='!base-border'
            >
              <Tabs
                value={tabIndex}
                orientation='vertical'
                onChange={handleChangeTab}
                // className='custom-tab-fulfilled'
              >
                <Tab
                  label={t('settings.discounts.tabs.discounts')}
                  className='!whitespace-nowrap !items-start'
                  value={1}
                />
                <Tab
                  label={t('settings.discounts.tabs.discountSettings')}
                  className='!whitespace-nowrap !items-start'
                  value={2}
                />
                {/* <Tab
                  label={t('settings.discounts.tabs.termDiscount')}
                  className='!whitespace-nowrap !items-start'
                  value={3}
                /> */}
              </Tabs>
            </Box>
          </div>
          <div>
            {tabIndex == 1 && <Discount />}
            {tabIndex == 2 && <DiscountSettings />}
            {/* {tabIndex == 3 && <DiscountTerm />} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Discounts);
