import { Button } from '@mui/material';
import useTopPanel from 'hooks/useTopPanel';
import React, { memo, useEffect, useState } from 'react';
import SearchInput from 'components/SearchInput';
// import ContractFiltersComponent from 'components/ui/filters/ContractFiltersComponent';
import ContractTable from 'components/ui/tables/ContractTable';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import usePermission from 'hooks/usePermission';
import ContractFilterDrawer from 'components/dashboard/ContractFilterDrawer';

const Contract = () => {
  const { setComponent } = useTopPanel();
  const { hasPermission } = usePermission();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setComponent(<div className='component-title'>{t('contract.title')}</div>);
  }, [i18n.language]);

  const [refetch, setRefetch] = useState(false);
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);

  return (
    <div className='component-list-wrapper'>
      <div className='component-list-header mb-2'>
        <div className='header-actions-container py-3 flex flex-row items-center'>
          <div className='header-actions filter-box flex items-center my-shadow-2 rounded-lg px-4 w-full'>
            <Button
              variant='filterOutlined'
              color='primary'
              startIcon={<i className='bi bi-filter' />}
              className='!mr-2'
              onClick={() => setExpanded((prev) => !prev)}
            >
              {t('common.button.filter')}
            </Button>
            <SearchInput inputKey='name' />
            <div className='ml-auto flex flex-row'>
              <div className='flex items-center'>
                <div className='w-4 h-4 rounded-sm bg-[#23C840] mr-1'></div>
                {t('contract.completed')}
              </div>
              <div className='flex ml-4 items-center'>
                <div className='w-4 h-4 rounded-sm bg-[#FF6D6D] mr-1'></div>
                {t('contract.cancelled')}
              </div>
            </div>
          </div>
          <div className='header-actions action-buttons-box py-3 px-4 my-shadow-2 rounded-lg flex items-center justify-center ml-4'>
            <Button
              variant='action'
              color='info'
              onClick={() => {
                setRefetch(true);
              }}
              disable={`${refetch}`}
              className='!mr-2'
            >
              <i
                className={`bi bi-arrow-repeat${
                  refetch ? ' animate-spin' : ''
                }`}
              />
            </Button>
            <Link
              to={`${process.env.REACT_APP_BACKEND_URL}/excel/contracts`}
              className='no-underline !mr-2'
              // target='_blank'
            >
              <Button variant='action' color='success'>
                <i className='bi bi-download' />
              </Button>
            </Link>
            {hasPermission('ADD_CONTRACTS') && (
              <Button
                variant='action'
                color='success'
                className='!mr-2'
                onClick={() => {
                  navigate('/admin/contract/add');
                }}
              >
                <i className='bi bi-plus-circle' />
              </Button>
            )}

            <Button
              variant='action'
              color='default'
              onClick={() => setOpen(true)}
            >
              <i className='bi bi-gear' />
            </Button>
          </div>
        </div>
        {/* {expanded && (
          <div className='my-shadow-2 rounded-lg px-4 w-full mt-2'>
            <ContractFiltersComponent />
          </div>
        )} */}
      </div>

      <div className='component-table-wrapper'>
        <ContractTable
          emitRefetch={{ refetch, setRefetch }}
          dataPath='dictionary/contracts'
          emitTableColumns={{ open, setOpen }}
          tableName='contract'
          actionInfo={true}
        />
      </div>
      <ContractFilterDrawer open={expanded} setOpen={setExpanded} />
    </div>
  );
};

export default memo(Contract);
