import { Button, CircularProgress } from '@mui/material';
import React, { memo } from 'react';
import { useFormik } from 'formik';
import { t } from 'i18next';

import useFormSubmit from 'hooks/useFormSubmit';
import FormTextField from 'components/ui/form/FormTextField';
import { useAppInfo } from 'context';

export default memo(function CRMTaskForm({ data, refetch }) {
  const [{ refetchTaskCount }] = useAppInfo();
  const { submit, isSubmitting } = useFormSubmit();
  const formik = useFormik({
    initialValues: {
      result: '',
    },
    // validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (data?.id) {
        submit(
          { type: 'post', contentType: 'simple' },
          values,
          `/crm/task/done/${data?.id}`,
          values.result,
          null,
          true,
          () => {
            refetch();
            refetchTaskCount?.();
          }
        );
      }
    },
  });
  return (
    <form action='' onSubmit={formik.handleSubmit}>
      <div className='flex items-center gap-4 mr-2'>
        <FormTextField
          delay={0}
          duration={0}
          label=''
          fieldName={'result'}
          formik={formik}
          size='small'
        />
        <Button
          color='success'
          variant='contained'
          size='medium'
          type='submit'
          disabled={isSubmitting}
          startIcon={isSubmitting ? <CircularProgress size={18} /> : null}
        >
          {t('common.button.done')}
        </Button>
      </div>
    </form>
  );
});
