import { FormControl, InputAdornment, MenuItem, Select } from '@mui/material';
import usePermission from 'hooks/usePermission';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

const ShaxmatkaModeSelect = ({ value = 'VIEW', setValue = () => {} }) => {
  const { t } = useTranslation();
  const { hasPermission } = usePermission();

  const options = [
    {
      label: t('shaxmatka.mode.view'),
      code: 'VIEW',
      disabled: false,
    },
    {
      label: t('shaxmatka.mode.edit'),
      code: 'EDIT',
      disabled: !hasPermission('EDIT_HOMES'),
    },
    {
      label: t('shaxmatka.mode.priceEdit'),
      code: 'PRICE_EDIT',
      disabled: !hasPermission('EDIT_HOMES'),
    },
  ];

  return (
    <FormControl
      fullWidth
      color='formColor'
      sx={{ width: '180px', marginRight: 2, marginTop: 0, marginBottom: 0 }}
    >
      <Select
        className='shaxmatka-select-mode'
        labelId='select-mode-label'
        id='select-mode-select'
        onChange={(event) => setValue(event.target.value)}
        value={value}
        color='formColor'
        variant='outlined'
        role='presentation'
        MenuProps={{
          id: 'select-mode-select-menu',
          disableScrollLock: true,
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
        }}
        readOnly={!hasPermission('EDIT_HOMES')}
        disabled={!hasPermission('EDIT_HOMES')}
        size='small'
        startAdornment={
          <InputAdornment position='start'>
            {value == 'VIEW' ? (
              <i className='bi bi-eye adornment-icon view-icon' />
            ) : value == 'EDIT' ? (
              <i className='bi bi-pencil-square adornment-icon edit-icon' />
            ) : (
              <i className='bi bi-currency-dollar adornment-icon edit-icon' />
            )}
          </InputAdornment>
        }
      >
        {options
          .filter((item) => !item.disabled)
          .map((item, index) => (
            <MenuItem
              value={item.code}
              key={`mode-select-${index + 1}`}
              className='small-size'
            >
              {item.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default memo(ShaxmatkaModeSelect);
