import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ActivePipe from './activePipe/ActivePipe';
import ClosedPipes from './closedPipes/ClosedPipes';
import { pipesData } from '../mock';

export const CLOSED_SUCCESSFULLY = 1001;
export const CLOSED_FAIL = 1002;

export function mathFloor(value, precision) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

const Pipes = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    let percentSpace = 0;
    let days = 0;

    pipesData.forEach((item) => {
      days += item.day;
    });

    const newData = pipesData?.map((item) => {
      const percentDay = mathFloor(
        (30 * item.day) / (days === 0 ? 1 : days),
        0
      );
      const percentLead = mathFloor((60 * item.countLeadPercent) / 100, 0);
      const borderPercent = 100 - percentDay - percentLead - percentSpace;
      const data = {
        ...item,
        borderPercent,
        dayPercent: percentDay,
        percentLine: percentLead,
        spacePercent: percentSpace,
        countLeadPercent: mathFloor(item.countLeadPercent, 2),
      };
      percentSpace += percentDay;
      return data;
    });
    return setData(newData);
  }, [pipesData]);

  const { t } = useTranslation();

  return (
    <div>
      {data?.length === 0 && (
        <div className='no-data-found-wrapper h-full'>
          <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
          {t('common.global.noDataFound')}
        </div>
      )}
      {data?.map((item, index) => {
        if (item.order === CLOSED_SUCCESSFULLY) {
          return (
            <ClosedPipes
              name={item.name}
              countLead={item.countLead}
              key={index}
              percent={item.countLeadPercent}
              color={item.color}
              allDate={undefined}
              percentLine={item.percentLine}
            />
          );
        }
        if (item.order === CLOSED_FAIL) {
          return (
            <ClosedPipes
              name={item.name}
              countLead={item.countLead}
              key={index}
              percent={item.countLeadPercent}
              color={item.color}
              allDate={data?.averageDay || 6}
              percentLine={item.percentLine}
            />
          );
        }
        return (
          <ActivePipe
            countLead={item.countLead}
            name={item.name}
            key={index}
            percentSpace={item.spacePercent}
            percentLine={item.percentLine}
            percentBorder={item.borderPercent}
            color={item.color}
            day={item.day}
            percent={item.countLeadPercent}
            percentDate={item.dayPercent}
          />
        );
      })}
    </div>
  );
};

export default Pipes;
