import { Fab } from '@mui/material';
import React, { useState } from 'react';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useNotification from 'hooks/useNotification';
export default function TerminateById({ id, setRefetch }) {
  const axiosPrivate = useAxiosPrivate();
  const sendNotification = useNotification();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const terminateById = async () => {
    try {
      setIsSubmitting(true);
      const response = await axiosPrivate.post(
        `admin/profile/deletedevice/${id}`,
        {},
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (response.data && response.data.status) {
        sendNotification({
          msg: response?.data?.message,
          variant: 'success',
        });
        setRefetch(true);
      }
      setIsSubmitting(false);
    } catch (error) {
      sendNotification({
        msg: error?.response?.data?.message || error?.message,
        variant: 'error',
      });
      setIsSubmitting(false);
    }
  };
  return (
    <Fab
      color='error'
      variant='action'
      aria-label='delete'
      onClick={terminateById}
      disabled={isSubmitting}
    >
      <i className='bi bi-trash3' />
    </Fab>
  );
}
