import { Checkbox, Drawer, FormControlLabel, Switch } from '@mui/material';
import { t } from 'i18next';
import React from 'react';

const TableColumnToggleDrawer = ({
  open,
  setOpen,
  tableData,
  setTableHeadCells,
}) => {
  const handleChange = (event, code) => {
    let foundItem = tableData.find((item) => item.code == code);
    if (foundItem) {
      foundItem.isActive = event.target.checked;
    }
    setTableHeadCells(tableData);
  };

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: { padding: '18px', background: 'transparent', boxShadow: 'none' },
      }}
    >
      <div className='table-column-drawer-wrapper bg-white h-full rounded-lg border border-[#E5E9EB]'>
        <div className='font-semibold text-[16px] border-b border-b-[#F4F4F5] pb-[5px]'>
          {t('common.table.sortTable')}
        </div>
        {tableData &&
          tableData.map((column) => (
            <FormControlLabel
              key={column.code}
              value={column.code}
              control={
                <Checkbox
                  color='secondary'
                  checked={column.isActive}
                  onChange={(event) => handleChange(event, column.code)}
                />
              }
              // labelPlacement='start'
              label={
                <div
                  className='text-black'
                  dangerouslySetInnerHTML={{ __html: column.label }}
                />
              }
              className='table-column-drawer-item'
            />
          ))}
      </div>
    </Drawer>
  );
};

export default TableColumnToggleDrawer;
