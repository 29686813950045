import React from 'react';
import NotificationMenu from './NotificationMenu';
import useTopPanel from 'hooks/useTopPanel';
import LanguageMenu from './LanguageMenu';
import DailyCurrency from './DailyCurrency';
import CallsHistory from './calls-history/CallsHistory';
import Navigators from './Navigators';
import User from './User';

const TopPanel = () => {
  const { component } = useTopPanel();

  return (
    <div className='with-max-width h-16 border-b border-b-[#E5E9EB] bg-[#F8F9FB]'>
      <div
        id='top-panel'
        className='top-panel-wrapper py-4 px-4 flex items-center justify-between z-[10] h-16 with-max-width'
      >
        <div className='top-panel-tools flex items-center justify-between w-full mx-2 gap-[10px]'>
          <Navigators />
          {component}
          <div className='flex items-center ml-auto'>
            <div>
              <DailyCurrency />
            </div>
            <div className='ml-2'>
              <CallsHistory />
            </div>
            <div className='mx-2'>
              <LanguageMenu />
            </div>
            <div>
              <NotificationMenu />
            </div>
            <div className='ml-2'>
              <User />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopPanel;
