import { useEffect, useState } from 'react';
import FilterAccordion from '../FilterAccordion';
import { useSearchParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { t } from 'i18next';

const HomeRoomsFilter = ({ filterRooms = [], disabled = false }) => {
  const [rooms, setRooms] = useState(() =>
    filterRooms && filterRooms.length > 0
      ? filterRooms.map((item) => item.rooms)
      : []
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedRooms, setSelectedRooms] = useState([]);

  const handleRoomChange = (value) => {
    let entries = Object.fromEntries(searchParams);
    Object.keys(entries).forEach((item) => {
      if (item && item.includes('rooms')) {
        searchParams.delete(item);
      }
    });
    if (isSelected(value)) {
      selectedRooms.splice(selectedRooms.indexOf(value), 1);
    } else {
      selectedRooms.push(value);
    }
    selectedRooms.forEach((item, index) => {
      searchParams.set(`rooms[${index}]`, item);
    });
    setSearchParams(searchParams);
    setSelectedRooms(selectedRooms);
  };

  const isSelected = (room) => {
    return selectedRooms.includes(room);
  };

  const setDefaultRooms = () => {
    let entries = Object.fromEntries(searchParams);
    let newRooms = [];
    Object.keys(entries).forEach((item) => {
      if (item && item.includes('rooms')) {
        newRooms.push(searchParams.get(item));
      }
    });
    setSelectedRooms(newRooms);
  };

  useEffect(() => {
    setDefaultRooms();
  }, [searchParams]);

  return (
    <div className='mt-3'>
      <div className='filter-title mb-2'>{t('shaxmatka.homeDetail.rooms')}</div>
      {rooms && rooms.length > 0 && (
        <div className='home-rooms-wrapper flex flex-wrap gap-2'>
          {rooms.map((item) => (
            <Button
              className={`stage-item${
                isSelected(item?.toString())
                  ? ' item-selected !bg-base-color-light'
                  : ''
              } !bg-gray-100 !min-w-[35px] !h-[25px]`}
              color='primary'
              variant={isSelected(item?.toString()) ? 'contained' : 'outlined'}
              sx={{
                border: '0',
                borderRadius: '15px',
                minWidth: '35px',
                width: 35,
                maxWidth: 'auto',
              }}
              key={`home-filter-room-${item}`}
              onClick={() => handleRoomChange(item?.toString())}
              disabled={disabled}
            >
              {item}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};
export default HomeRoomsFilter;
