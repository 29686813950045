import { CircularProgress, Tab, Tabs } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';

import DoughnutChart from 'components/ui/charts/DoughnutChart';
import { filedEmployees, filedReasons } from '../mock';

function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export default memo(function FiledLeads() {
  const [tabValue, setTabValue] = useState('reasons');
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);
  const [colors, setColors] = useState([]);

  const isLoading = false,
    isFetching = false,
    totalTask = 100000;

  useEffect(() => {
    const data =
      tabValue === 'reasons' ? filedReasons.data : filedEmployees.data;
    const labels = Object.keys(data?.result);
    const values = Object.values(data?.result);
    const colors = Object.values(data?.result)?.map(() => getRandomColor());
    setData(
      Object.entries(data.result)?.map(([name, value], i) => ({
        value,
        name,
        color: colors[i],
      }))
    );
    setLabels(labels);
    setValues(values);
    setColors(colors);
  }, [tabValue]);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className='income-by-residential-body border border-[#eaeaea] mb-4 py-[24px] px-[32px] rounded-lg max-w-[950px]'>
      <div className=''>
        <div className='flex items-center justify-between mb-4'>
          <div className='residential-complex-item-title income-by-residential-complex-title'>
            Muvaffaqiyatsiz lidlar
          </div>
          <div>
            <Tabs
              value={tabValue}
              onChange={handleChangeTab}
              sx={{ minHeight: 30 }}
            >
              <Tab
                label='Sabablar'
                value={'reasons'}
                sx={{ minHeight: 30, padding: '2px 16px' }}
              />
              <Tab
                label='Xodimlar'
                value={'employees'}
                sx={{ minHeight: 30, padding: '2px 16px' }}
              />
            </Tabs>
          </div>
        </div>
        {isLoading || isFetching ? (
          <div className='h-[260px] flex items-center justify-center'>
            <CircularProgress size={30} color='inherit' />
          </div>
        ) : (
          <div className='flex gap-4'>
            <div className='relative h-96 w-96'>
              <DoughnutChart
                chartLabels={labels}
                chartDatasets={[
                  {
                    data: values,
                    backgroundColor: colors,
                    borderWidth: 2,
                    hoverOffset: 15,
                    hoverBorderWidth: 0,
                    borderRadius: 4,
                  },
                ]}
                suffix=''
                optionPlugins={{
                  legend: {
                    display: false,
                  },
                }}
                options={{
                  layout: {
                    padding: {
                      top: 10,
                      bottom: 10,
                    },
                  },
                }}
              />
              {/* {totalTask > 0 && ( */}
              <div className='text-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                <h1 className='text-[35px]'>{totalTask}</h1>
                <p className='text-sm text-gray-500'>Jami</p>
              </div>
              {/* )} */}
            </div>
            {/* <div className='max-h-[50vh] my-scroll overflow-y-auto'> */}
            <div className='text-sm overflow-x-auto my-scroll w-full max-h-[500px]'>
              {data?.map((item, i) => (
                <div
                  key={`${i}hodimlar`}
                  className='flex justify-between text-[16px] font-medium text-[#464255] w-full py-3 px-2 my-1.5 cursor-pointer hover:bg-gray-50 rounded-lg'
                >
                  <div className='flex items-center whitespace-nowrap'>
                    <div
                      className={`min-w-[18px] mr-1 h-[18px] border-4 rounded-full`}
                      style={{ borderColor: item?.color }}
                    />
                    {item?.name}
                  </div>
                  <div className='flex items-center whitespace-nowrap'>
                    {item?.value}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
