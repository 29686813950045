import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useNotification from 'hooks/useNotification';
import { useTranslation } from 'react-i18next';

const ContractChildAccordion = ({ item = {}, parentClassName = '' }) => {
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const sendNotification = useNotification();

  const handleChange = async (value, itemKey) => {
    if (value && itemKey) {
      try {
        const response = await axiosPrivate.get(
          `/analytic/contracts/intimes?number=${itemKey}`
        );
      } catch (error) {
        sendNotification({
          msg: error?.response?.data?.message || error?.message,
          variant: 'error',
        });
      }
    }
  };

  return (
    <Accordion
      sx={{ boxShadow: 'none' }}
      className={`contracts-wrapper-accordion contracts-${parentClassName}`}
      onChange={(event, expanded) => handleChange(expanded, item?.key)}
    >
      <AccordionSummary
        aria-controls={`contracts-wrapper-item-${parentClassName}-content`}
        id={`contracts-wrapper-item-${parentClassName}-header`}
        expandIcon={<i className='bi bi-chevron-right expand-icon' />}
        className={`contracts-wrapper-accordion-summary ${parentClassName}`}
      >
        <div className={`contracts-child-data ${parentClassName}`}>
          <div className='contracts-child-data-header'>
            <i className='bi bi-journals text-lg' />
            <div className='contracts-child-data-title'>
              <span className='contract-month'>{item?.key}</span> oylik
              shartnomalar
            </div>
          </div>
          <div className='contracts-number'>{item?.number}</div>
        </div>
      </AccordionSummary>
      <AccordionDetails className='contracts-wrapper-accordion-details'>
        <div className='no-data-found-wrapper h-[100px] small-size'>
          <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
          {t('common.global.noDataFound')}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
export default ContractChildAccordion;
