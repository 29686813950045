import React, { memo, useState } from 'react';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { Button, Menu, MenuItem } from '@mui/material';
import { motion } from 'framer-motion';

import { fadeUp } from 'utils/motion';
import useAuth from 'hooks/useAuth';

export default memo(function User({ animate = true }) {
  const [{ user, logout }] = useAuth();
  const [open, setOpen] = useState(false);

  const anchorEl = document.getElementById('user-profile-btn');

  const menuToggle = () => {
    setOpen((prev) => !prev);
  };

  return (
    <motion.div
      className='profile-wrapper'
      variants={fadeUp(30, 'tween', 0.1, 0.5)}
      initial={animate ? 'hidden' : ''}
      animate={animate ? 'show' : ''}
      viewport={{ once: true, amount: 0.25 }}
    >
      <Button
        onClick={menuToggle}
        id='user-profile-btn'
        className='!max-w-[210px] !min-w-[215px] flex items-center gap-1 !justify-start'
      >
        <div className='w-[44px] min-w-[44px] h-[44px] flex items-center justify-center rounded-full overflow-hidden bg-base-color-light'>
          <i className='bi bi-person text-lg text-white' />
        </div>
        <div className='flex flex-col items-start leading-5 ml-2 overflow-hidden'>
          <span className='text-black text-[16px] w-full overflow-hidden overflow-ellipsis whitespace-nowrap'>
            {user?.user?.name}
          </span>
          <span className='text-gray-400 text-[14px] leading-4'>
            {user?.user?.login}
          </span>
        </div>
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        disableScrollLock={true}
        onClose={menuToggle}
        className='language-menu !mt-0'
        PaperProps={{ sx: { marginTop: '5px', boxShadow: 'none' } }}
        MenuListProps={{ sx: { paddingTop: 0, paddingBottom: 0 } }}
      >
        <Link to='/admin/profile'>
          <MenuItem className={'w-[200px]'}>
            <i className='bi bi-person text-base mr-2' />
            <div className='text-[0.85rem] leading-3'>
              {t('sidebar.profile')}
            </div>
          </MenuItem>
        </Link>
        <MenuItem onClick={logout} className={'w-[200px]'}>
          <i className='bi bi-box-arrow-right text-base mr-2' />
          <div className='text-[0.85rem] leading-3'>
            {t('sidebar.actions.logout')}
          </div>
        </MenuItem>
      </Menu>
    </motion.div>
  );
});
