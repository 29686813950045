import { TextField } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

const EditableNumberField = ({
  initialValue = '',
  changeFn = () => {},
  className = '',
  decimalScale = 0,
  children,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [prevValue, setPrevValue] = useState(initialValue);
  const [value, setValue] = useState(initialValue);

  const handleClick = () => {
    setIsEditing(true);
    setPrevValue(initialValue);
    setValue(initialValue);
  };

  const handleChange = (event) => {
    const formattedValue =
      (event.target.value &&
        parseFloat(event.target.value.split(' ').join(''))) ||
      0;
    if (formattedValue) {
      setValue(() => formattedValue);
    } else {
      setValue(0);
    }
  };

  const handleBlur = (event) => {
    const formattedValue =
      (event.target.value &&
        parseFloat(event.target.value.split(' ').join(''))) ||
      0;
    // if (prevValue != formattedValue) {
    // }
    if (!isNaN(formattedValue)) {
      changeFn(formattedValue);
      setValue(formattedValue);
      setPrevValue(formattedValue);
    }
    setIsEditing(false);
  };

  useEffect(() => {
    setPrevValue(initialValue);
    setValue(initialValue);
  }, [initialValue]);

  return (
    <span onClick={handleClick} className={className}>
      {isEditing ? (
        <NumericFormat
          label=''
          value={value}
          color='formColor'
          variant='outlined'
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus={true}
          customInput={TextField}
          allowNegative={false}
          allowLeadingZeros={false}
          decimalScale={decimalScale}
          thousandSeparator={' '}
          size='small'
        />
      ) : (
        children
      )}
    </span>
  );
};

export default memo(EditableNumberField);
