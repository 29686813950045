import { Button, Drawer } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { memo } from 'react';
import DateRangeFilter from '../ui/filters/items/DateRangeFilter';
import StatusFilter from '../ui/filters/items/StatusFilter';
import ObjectBlockMultiFilter from '../ui/filters/items/ObjectBlockMultiFilter';
import GenericSelectFilter from '../ui/filters/items/GenericSelectFilter';
import SearchInputNavbar from './items/SearchInputNavbar';
import StaffFilter from '../ui/filters/items/StaffFilter';
import { currencyTypeSelectOptions } from 'shared/selectOptionsList';

const ContractFilterDrawer = ({ open, setOpen, isLoading = false }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleResetFiler = () => {
    navigate(location.pathname, { replace: true });
  };

  return (
    <Drawer
      anchor='left'
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: { padding: '18px', background: 'transparent', boxShadow: 'none' },
      }}
    >
      <div className='dashboard-filter-drawer-wrapper !pt-2 first-letter bg-white h-full rounded-lg border border-[#E5E9EB]'>
        <div className='mb-3 text-end flex justify-between items-center border-b'>
          <div className='text-[16px] leading-[16px] font-semibold'>
            {t('common.button.filter')}
          </div>
          <Button
            color='error'
            // variant='outlined'
            className='!-mr-[5px]'
            size='small'
            disabled={isLoading}
            onClick={() => handleResetFiler()}
          >
            {t('common.button.clear')}
          </Button>
        </div>
        <DateRangeFilter
          size='small'
          disabled={isLoading}
          labels={{ start: 'Sanadan', end: 'gacha' }}
        />
        <StatusFilter />
        <GenericSelectFilter
          options={[
            { id: 'true', name: 'Tasdiqlangan' },
            { id: 'false', name: 'Tasdiqlanmagan' },
          ]}
          query={'formal'}
          label={'Rasmiyligi'}
          size='small'
        />
        <SearchInputNavbar
          inputKey={'flat_number'}
          label={'Xonadon raqami' || ''}
          size='small'
          height='34px'
          className='!my-[5px]'
        />
        <ObjectBlockMultiFilter />
        <StaffFilter
          disabled={isLoading}
          label='common.fields.staff'
          query='staff'
          className='!min-h-[34px] !h-[34px] !my-[5px !text-[12px]'
        />
        <GenericSelectFilter
          options={currencyTypeSelectOptions}
          optionProp={{ label: 'label', value: 'code' }}
          query={'isvalute'}
          label={'Valyuta'}
          size='small'
        />
        <SearchInputNavbar
          inputKey={'contract_number'}
          label={'Shartnoma raqami' || ''}
          size='small'
          height='34px'
          className='!my-[5px]'
        />
        <GenericSelectFilter
          options={[
            { id: 'true', name: "To'lov qilgan" },
            { id: 'false', name: "To'lov qilmagan" },
          ]}
          query={'paid'}
          label={"Shartnoma to'lovi"}
          size='small'
        />
        <GenericSelectFilter
          options={[
            { id: 'true', name: 'Chegirmali' },
            { id: 'false', name: 'Chegirmasiz' },
          ]}
          query={'discount'}
          label={'Shartnoma chegirmasi'}
          size='small'
        />
        <GenericSelectFilter
          options={[
            { id: '1', name: "Ta'mirli" },
            { id: '0', name: "Ta'mirsiz" },
          ]}
          query={'isrepired'}
          label={"Ta'mir xolati"}
          size='small'
        />
      </div>
    </Drawer>
  );
};
export default memo(ContractFilterDrawer);
