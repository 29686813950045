import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const GenericSelectFilter = ({
  query,
  label,
  size = undefined,
  options,
  optionProp = { label: 'name', value: 'id' },
  className,
  marginContainer,
}) => {
  const { t } = useTranslation();
  const [selectedBlock, setSelectedBlock] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get(query || 'query_name'))
      setSelectedBlock(searchParams.get(query || 'query_name'));
  }, [searchParams.get(query || 'query_name')]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    searchParams.set(query || 'query_name', value);
    let localSearchParams = Object.fromEntries([...searchParams]);
    if (!isNaN(localSearchParams.page)) {
      searchParams.set('page', 1);
    }
    setSearchParams(searchParams);
    setSelectedBlock(value);
  };

  const handleClearSelectBox = () => {
    searchParams.delete(query || 'query_name');
    setSelectedBlock('');
    setSearchParams(searchParams);
  };

  return (
    <FormControl sx={{ margin: marginContainer }} fullWidth color='formColor'>
      <InputLabel id='single-block-filter-label'>{label}</InputLabel>
      <Select
        labelId='single-block-filter-label'
        id='single-block-filter-chip'
        label={label}
        value={selectedBlock}
        onChange={handleChange}
        color='formColor'
        variant='outlined'
        className={className}
        size={size}
        MenuProps={{
          id: 'single-block-filter-select-menu',
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
          disableScrollLock: true,
        }}
        endAdornment={
          <InputAdornment position='end' className='custom-endAdornment'>
            {selectedBlock && (
              <IconButton
                onClick={() => handleClearSelectBox()}
                variant='onlyIcon'
                className='select-box-end-adornment-button'
              >
                <i className='bi bi-x end-adornment-close-button' />
              </IconButton>
            )}
          </InputAdornment>
        }
      >
        {options && options.length > 0 ? (
          options.map((item, index) => (
            <MenuItem
              value={item?.[optionProp?.value]}
              key={`block-${index + 1}`}
            >
              {item?.[optionProp?.label]}
            </MenuItem>
          ))
        ) : (
          <div>
            <span className='no-data-found-wrapper select-box'>
              <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
              {t('common.global.noDataFound')}
            </span>
          </div>
        )}
      </Select>
    </FormControl>
  );
};

export default memo(GenericSelectFilter);
