import { Button } from '@mui/material';
import React from 'react';
import { useMutation } from 'react-query';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { useNavigate } from 'react-router-dom';
import useNotification from 'hooks/useNotification';
import { t } from 'i18next';

export default function OperatorActiveLead({ id }) {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const sendNotification = useNotification();

  const defineLead = useMutation({
    mutationFn: async function () {
      const response = await axiosPrivate.get('/crm/real-time/check');
      return response.data.data;
    },
  });

  const onClick = () => {
    defineLead.mutateAsync('', {
      onSuccess: (data) => {
        if (data?.crm_lead_id) {
          navigate(`/admin/crm/lead/edit/${data?.crm_lead_id}`);
        } else {
          sendNotification({
            msg: t('crm.activeLead.noActiveLead'),
            variant: 'warning',
          });
        }
      },
      onError: (error) => {
        sendNotification({
          msg: error?.response?.data?.message || error?.message,
          variant: 'error',
        });
      },
    });
  };

  return (
    <Button
      fullWidth
      size='small'
      color='warning'
      variant='contained'
      startIcon={<i className='bi bi-headset' />}
      onClick={onClick}
    >
      {t('crm.activeLead.title')}
    </Button>
  );
}
