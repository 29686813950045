import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  InputAdornment,
  LinearProgress,
  linearProgressClasses,
  styled,
  TextField,
} from '@mui/material';
import FormActionButtons from 'components/ui/form/FormActionButtons';
import { useFormik } from 'formik';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useNotification from 'hooks/useNotification';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import useDebounce from 'hooks/useDebounce';

const validationSchema = yup.object({
  password: yup
    .string()
    .min(3, { label: 'settings.staff.validation.passwordMin', value: 3 })
    .required('settings.staff.validation.password'),
});

const PasswordChangeModal = (props) => {
  const { open, setOpen, setRefetch } = props;
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [notMatch, setNotMatch] = useState(false);
  const sendNotification = useNotification();
  const [oldPassword, setOldPassword] = useState('');
  const [truePassword, setTruePassword] = useState();
  const [passwordQuality, setPasswordQuality] = useState(0);
  const oldPasswordDebounce = useDebounce(oldPassword, 400);

  const formik = useFormik({
    initialValues: {
      password: '',
      retypePassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (values?.password !== values?.retypePassword) {
        setNotMatch(true);
        return;
      }
      try {
        setIsSubmitting(true);
        const response = await axiosPrivate.post(
          '/admin/profile/updatepassword',
          JSON.stringify(values),
          { headers: { 'Content-Type': 'application/json' } }
        );
        if (response.data && response.data.status) {
          sendNotification({
            msg: response?.data?.message,
            variant: 'success',
          });
          setIsSubmitting(false);
          setRefetch(true);
          handleClose();
        }
      } catch (error) {
        sendNotification({
          msg: error?.response?.data?.message || error?.message,
          variant: 'error',
        });
        setIsSubmitting(false);
      }
    },
  });

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
  };

  const onChange = (e) => {
    setOldPassword(e.target.value);
    setTruePassword();
  };

  const checkPassword = async (value) => {
    if (oldPasswordDebounce && oldPasswordDebounce?.length > 5) {
      setIsChecking(true);
      try {
        const response = await axiosPrivate.get(
          `/admin/profile/check?password=${value}`,
          JSON.stringify({ password: value }),
          { headers: { 'Content-Type': 'application/json' } }
        );
        if (response.data && response.data.status) {
          setTruePassword(true);
        } else {
          setTruePassword(true);
        }
      } catch (error) {
        setTruePassword(false);
      }
      setIsChecking(false);
    }
  };

  const onKeyDown = async (e) => {
    const { value } = e.target;
    if (e.key === 'Enter') {
      e?.preventDefault();
      e?.stopPropagation();
      return checkPassword(value);
    }

    const timeOut = setTimeout(() => {
      checkPassword(value);

      return clearTimeout(timeOut);
    }, 300);
  };

  useEffect(() => {
    if (oldPasswordDebounce) {
      checkPassword(oldPassword);
    }
  }, [oldPasswordDebounce]);

  const onChanges = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);

    if (name === 'password') {
      let quality = 0;
      if (value?.length >= 5) {
        quality += 20;
      } else {
        setPasswordQuality(0);
        return;
      }
      if (value?.match(/[0-9]/)) {
        quality += 20;
      }
      if (value?.match(/[a-z]/)) {
        quality += 20;
      }
      if (value?.match(/[A-Z]/)) {
        quality += 20;
      }
      if (value?.match(/[_@$!#%*?&\\/+-]/)) {
        quality += 20;
      }
      setPasswordQuality(quality);
    }

    if (name === 'retypePassword' && formik?.values?.password === value)
      setNotMatch(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      disableEscapeKeyDown={true}
      PaperProps={{
        className: '!base-border !shadow-none !max-w-[520px]',
      }}
    >
      <DialogTitle id='alert-dialog-title' className='bg-gray-100'>
        <span>{t('profile.changeLogin')}</span>
      </DialogTitle>

      <DialogContent className='!mt-4'>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            spacing={{ xs: 2, sm: 3, lg: 3 }}
            rowSpacing={1}
            columns={{ xs: 12, sm: 12, lg: 12 }}
          >
            <Grid item={true} lg={12} sm={12} xs={12}>
              <TextField
                onChange={onChange}
                onKeyDown={onKeyDown}
                value={oldPassword}
                placeholder={t('profile.typeCurrentPassword')}
                fullWidth
                error={truePassword === false}
                color={truePassword ? 'success' : undefined}
                InputProps={{
                  endAdornment:
                    truePassword || isChecking ? (
                      <InputAdornment position='end'>
                        {isChecking ? (
                          <CircularProgress size={14} />
                        ) : (
                          <i className='bi bi-check-circle-fill text-green-600' />
                        )}
                      </InputAdornment>
                    ) : null,
                }}
                focused={truePassword}
                delay={0.1}
                label={t('profile.currentPassword')}
              />
              {truePassword === false && (
                <FormHelperText className='!text-red-600 !-mt-2'>
                  {t('profile.wrongPassword')}
                </FormHelperText>
              )}
            </Grid>
            <Grid item={true} lg={12} sm={12} xs={12}>
              <TextField
                onChange={onChanges}
                value={formik.values.password}
                name='password'
                fullWidth
                delay={0.1}
                InputProps={{
                  endAdornment:
                    passwordQuality === 100 ? (
                      <InputAdornment position='end'>
                        <i className='bi bi-check-circle-fill text-green-600' />
                      </InputAdornment>
                    ) : null,
                }}
                label={t('profile.newPassword')}
                disabled={!truePassword}
              />
            </Grid>
            <Grid item={true} lg={12} sm={12} xs={12}>
              <BorderLinearProgress
                sx={{
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: getQualityColor(passwordQuality),
                  },
                }}
                variant='determinate'
                value={passwordQuality}
              />
            </Grid>
            <Grid item={true} lg={12} sm={12} xs={12}>
              <TextField
                onChange={onChanges}
                value={formik.values.retypePassword}
                name='retypePassword'
                error={notMatch}
                onBlur={() => {
                  if (
                    formik?.values?.password !== formik?.values?.retypePassword
                  )
                    setNotMatch(true);
                }}
                fullWidth
                InputProps={{
                  endAdornment:
                    passwordQuality === 100 &&
                    formik?.values?.password ===
                      formik?.values?.retypePassword ? (
                      <InputAdornment position='end'>
                        <i className='bi bi-check-circle-fill text-green-600' />
                      </InputAdornment>
                    ) : null,
                }}
                delay={0.1}
                label={t('profile.retypeNewPassword')}
                disabled={!truePassword}
              />
              {notMatch && (
                <FormHelperText className='!text-red-600 !-mt-2'>
                  {t('profile.notMatchPassword')}
                </FormHelperText>
              )}
            </Grid>

            <Grid item={true} sm={12} xs={12}>
              <div className='flex justify-end'>
                <FormActionButtons
                  delay={0.3}
                  isSubmitting={isSubmitting}
                  formType='dialog'
                  setOpen={setOpen}
                  disabled={
                    !truePassword ||
                    formik?.values?.password !== formik?.values?.retypePassword
                  }
                  reset={formik.resetForm}
                />
              </div>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default PasswordChangeModal;

const getQualityColor = (quality) => {
  switch (quality) {
    case 100:
      return '#08f528';
    case 80:
      return '#1066b6';
    case 60:
      return '#ee8d0e';
    case 40:
      return '#f3db06';
    case 20:
      return '#f30606';
    default:
      return '#f30606';
  }
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
    ...theme.applyStyles('dark', {
      backgroundColor: '#308fe8',
    }),
  },
}));
