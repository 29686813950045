import React, { useState } from 'react';

// import SelectSourceModal from './selectSourceModal/SelectSourceModal';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import SettingTwoIcon from '../chart/chartContainer/SettingTwoIcon';
import ProgressDownIcon from './ProgressDownIcon';
import ProgressUpIcon from './ProgressUpIcon';
import { dataStatistics } from '../mock';

const Source = () => {
  const [selectSource, setSelectSource] = useState(false);
  const isLoading = false;
  const { t } = useTranslation();
  const data = dataStatistics.data;

  // handle select source
  const handleSelectSource = (visible) => {
    setSelectSource(visible);
  };

  return (
    <div
      className={
        'w-[36%] border border-[#eaeaea] py-[24px] px-[32px] rounded-lg'
      }
    >
      {isLoading ? (
        <div className='h-[260px] flex items-center justify-center'>
          <CircularProgress size={30} color='inherit' />
        </div>
      ) : (
        <>
          <div className={'flex items-center justify-between'}>
            <h2>{data?.companyLeadFieldName}</h2>
            <SettingTwoIcon
              onClick={() => handleSelectSource(true)}
              className={'cursor-pointer'}
            />
          </div>
          <div className={'mt-[32px]'}>
            <h3 className='text-[#464255] text-[56px] font-bold leading-[67px] m-0'>
              {data?.totalCount} {t('ta')}
            </h3>
            <p className='text-[16px] font-medium leading-[19px] mb-8'>
              {t('Umumiy muroojatlar soni')}
            </p>
            <hr />
          </div>
          {data?.fieldCountList.map((item, index) => {
            if (item.progress < 0) {
              return (
                <div
                  key={index}
                  className={'flex items-center justify-between mt-[32px]'}
                >
                  <div className={'styles.container_item_title'}>
                    <span>{item.name}</span>
                    <p>
                      {item.count} {t('ta')}
                    </p>
                  </div>
                  <div
                    className={
                      'flex items-center text-[#e74141] text-[16px] leading-[10px] font-medium ml-[10px]'
                    }
                  >
                    <ProgressDownIcon />
                    <span>{item.progress}%</span>
                  </div>
                </div>
              );
            }
            return (
              <div
                key={index}
                className={'flex items-center justify-between mt-[32px]'}
              >
                <div className={'styles.container_item_title'}>
                  <span>{item.name}</span>
                  <p>
                    {item.count} {t('ta')}
                  </p>
                </div>
                <div
                  className={
                    'flex items-center text-[#0caf61] text-[16px] leading-[10px] font-medium ml-[10px]'
                  }
                >
                  <ProgressUpIcon />
                  <span>+{item.progress}%</span>
                </div>
              </div>
            );
          })}
        </>
      )}
      {/* <SelectSourceModal
        id={data?.companyLeadFieldId}
        visible={selectSource}
        close={handleSelectSource}
      /> */}
    </div>
  );
};

export default Source;
