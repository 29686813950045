import { TextField } from '@mui/material';
import { motion } from 'framer-motion';
import { Fragment } from 'react';
import { NumericFormat } from 'react-number-format';
import formLocalizedHelperText from 'utils/formLocalizedHelperText';
import { fadeUp } from 'utils/motion';

const FormCurrencyField2 = ({
  delay = 0,
  duration = 0.5,
  fieldName,
  formik,
  label,
  prefix = '',
  readOnly = false,
  disabled = false,
  decimalScale = 3,
  variant = 'outlined',
  size = 'medium',
}) => {
  const getFieldValue = () => {
    if (fieldName && fieldName.includes('.')) {
      let a = fieldName.split('.');
      let b = formik.values;
      for (let i = 0; i < a.length; i++) {
        b = b?.[a?.[i]];
      }
      return b;
    }
    return formik.values?.[fieldName] || '';
  };

  const getFieldValidation = (formikF) => {
    if (fieldName && fieldName.includes('.')) {
      let a = fieldName.split('.');
      let b = formik[formikF];
      for (let i = 0; i < a.length; i++) {
        if (b?.[a?.[i]]) {
          b = b?.[a?.[i]];
        } else {
          b = false;
        }
      }
      return b;
    }
    return formik?.[formikF]?.[fieldName] || '';
  };
  return (
    <Fragment>
      {formik && formik.values && formik.touched && formik.errors && (
        <NumericFormat
          id={fieldName}
          name={fieldName}
          label={label}
          value={getFieldValue() || ''}
          delay={delay}
          size={size}
          onChange={(event) => {
            let formattedValue =
              event.target.value &&
              parseFloat(event.target.value.split(' ').join(''));
            formik.setFieldValue(fieldName, formattedValue, true);
          }}
          error={
            getFieldValidation('touched') &&
            Boolean(getFieldValidation('errors'))
          }
          helperText={
            getFieldValidation('touched') &&
            formLocalizedHelperText(getFieldValidation('errors'))
          }
          component={motion.div}
          variants={fadeUp(30, 'tween', delay, duration)}
          initial='hidden'
          animate='show'
          viewport={{ once: true, amount: 0.25 }}
          color='formColor'
          variant={variant}
          fullWidth
          customInput={TextField}
          InputProps={{ readOnly: readOnly, disabled: disabled }}
          allowNegative={false}
          thousandSeparator={' '}
          decimalScale={decimalScale}
          prefix={prefix}
        />
      )}
    </Fragment>
  );
};

export default FormCurrencyField2;
