import { Button, Drawer } from '@mui/material';
import DateTypeFilter from './items/dateTypeFilter';
import { useLocation, useNavigate } from 'react-router-dom';
import TaskTypeFilter from './items/taskTypeFilter';
import VaronkaStatusFilter from './items/VaronkaStatusFilter';
import StaffFilter from '../ui/filters/items/StaffFilter';
import TaskStatusFilter from './items/taskStatusFilter';
import TaskSourceFilter from './items/taskSourceFilter';

const TaskFilterDrawer = ({ open, setOpen, isLoading = false }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleResetFiler = () => {
    navigate(location.pathname, { replace: true });
  };

  return (
    <Drawer
      anchor='left'
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: { padding: '18px', background: 'transparent', boxShadow: 'none' },
      }}
    >
      <div className='dashboard-filter-drawer-wrapper first-letter bg-white h-full rounded-lg border border-[#E5E9EB]'>
        <div className='mb-3 text-end'>
          <Button
            color='error'
            variant='outlined'
            size='small'
            disabled={isLoading}
            onClick={() => handleResetFiler()}
          >
            <i className='bi bi-arrow-repeat text-lg mr-1' />
            Tozalash
          </Button>
        </div>
        <DateTypeFilter disabled={isLoading} />
        <TaskTypeFilter disabled={isLoading} />
        <VaronkaStatusFilter disabled={isLoading} />
        <TaskStatusFilter disabled={isLoading} />
        <TaskSourceFilter disabled={isLoading} />
        <StaffFilter
          disabled={isLoading}
          label='common.fields.staff'
          query='userId'
        />
        <StaffFilter
          disabled={isLoading}
          label='common.fields.authorStaff'
          query='created_by'
        />
      </div>
    </Drawer>
  );
};
export default TaskFilterDrawer;
