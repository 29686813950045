import moment from 'moment';

export const dateData = {
  '15_daqiqadan_song': {
    text: '15_daqiqadan_song',
    date: moment(new Date()).add(15, 'minute'),
    dateFormat: moment(new Date()).add(15, 'minute').format('DD.MM.YYYY HH:mm'),
  },
  '30_daqiqadan_song': {
    text: '30_daqiqadan_song',
    date: moment(new Date()).add(30, 'minute'),
    dateFormat: moment(new Date()).add(30, 'minute').format('DD.MM.YYYY HH:mm'),
  },
  '1_soatdan_song': {
    text: '1_soatdan_song',
    date: moment(new Date()).add(1, 'hour'),
    dateFormat: moment(new Date()).add(1, 'hour').format('DD.MM.YYYY HH:mm'),
  },
  Bugun: {
    text: 'Bugun',
    date: moment(new Date()),
    dateFormat: moment(new Date()).format('DD.MM.YYYY HH:mm'),
  },
  Ertaga: {
    text: 'Ertaga',
    date: moment(new Date()).add(1, 'day'),
    dateFormat: moment(new Date()).add(1, 'day').format('DD.MM.YYYY HH:mm'),
  },
  Hafta_oxiriga: {
    text: 'Hafta_oxiriga',
    date: moment(new Date()).endOf('week'),
    dateFormat: moment(new Date()).endOf('week').format('DD.MM.YYYY HH:mm'),
  },
  '1_haftadan_song': {
    text: '1_haftadan_song',
    date: moment(new Date()).add(1, 'week'),
    dateFormat: moment(new Date()).add(1, 'week').format('DD.MM.YYYY HH:mm'),
  },
  '1_oydan_song': {
    text: '1_oydan_song',
    date: moment(new Date()).add(1, 'month'),
    dateFormat: moment(new Date()).add(1, 'month').format('DD.MM.YYYY HH:mm'),
  },
  '1_yildan_song': {
    text: '1_yildan_song',
    date: moment(new Date()).add(1, 'year'),
    dateFormat: moment(new Date()).add(1, 'year').format('DD.MM.YYYY HH:mm'),
  },
};

export const dateDataDay = {
  Bugun: {
    text: 'Bugun',
    date: moment(new Date()),
    dateFormat: moment(new Date()).format('DD.MM.YYYY HH:mm'),
  },
  Ertaga: {
    text: 'Ertaga',
    date: moment(new Date()).add(1, 'day'),
    dateFormat: moment(new Date()).add(1, 'day').format('DD.MM.YYYY HH:mm'),
  },
  Kecha: {
    text: 'Kecha',
    date: moment(new Date()).add(-1, 'day'),
    dateFormat: moment(new Date()).add(-1, 'day').format('DD.MM.YYYY HH:mm'),
  },
};
