import { Button, CircularProgress } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';

import DoughnutChart from 'components/ui/charts/DoughnutChart';
import { filedEmployees, filedReasons } from '../../General/mock';

function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export default memo(function Doughnut({
  title,
  label,
  variant = 'horizontal',
  onClickTitle = () => {},
  dataList,
  isLoading,
}) {
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);
  const [colors, setColors] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (dataList?.length) {
      const data = dataList;
      const labels = data?.map((d) => d?.name);
      const values = data?.map((d) => d?.count);
      const colors = data?.map(() => getRandomColor());
      setData(
        data?.map(({ name, count }, i) => ({
          name,
          value: count,
          color: colors[i],
        }))
      );
      setTotal(data?.reduce((sum, d) => sum + d?.count, 0));
      setLabels(labels);
      setValues(values);
      setColors(colors);
    }
  }, [dataList]);

  return (
    <div className='income-by-residential-body mb-4 py-[24px] px-[32px] rounded-lg'>
      <div className=''>
        <div className='flex items-center justify-between mb-4'></div>
        {isLoading ? (
          <div className='h-[260px] flex items-center justify-center'>
            <CircularProgress size={30} color='inherit' />
          </div>
        ) : (
          <div
            className={`flex gap-4 ${
              variant === 'vertical' ? 'flex-col items-center' : 'flex-row'
            }`}
          >
            <div className='relative h-96 w-96 min-w-96'>
              <DoughnutChart
                chartLabels={labels}
                chartDatasets={[
                  {
                    data: values,
                    backgroundColor: colors,
                    borderWidth: 2,
                    hoverOffset: 15,
                    hoverBorderWidth: 0,
                    borderRadius: 4,
                  },
                ]}
                suffix=''
                optionPlugins={{
                  legend: {
                    display: false,
                  },
                }}
                options={{
                  layout: {
                    padding: {
                      top: 10,
                      bottom: 10,
                    },
                  },
                }}
              />
              {/* {totalTask > 0 && ( */}
              <div className='text-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                <h1 className='text-[35px]'>{total}</h1>
                <p className='text-sm text-gray-500'>{label || 'Jami'}</p>
              </div>
              {/* )} */}
            </div>
            {/* <div className='max-h-[50vh] my-scroll overflow-y-auto'> */}
            <div className='text-sm overflow-x-auto my-scroll w-full max-h-[500px]'>
              <Button
                fullWidth
                onClick={onClickTitle}
                className='!justify-between !text-gray-700 !bg-gray-100'
              >
                {title}
              </Button>

              {data?.map((item, i) => (
                <div
                  key={`${i}hodimlar`}
                  className='flex justify-between text-[16px] font-medium text-[#464255] w-full py-3 px-2 my-1.5 cursor-pointer hover:bg-gray-50 rounded-lg'
                >
                  <div className='flex items-center whitespace-nowrap'>
                    <div
                      className={`min-w-[18px] mr-1 h-[18px] border-4 rounded-full`}
                      style={{ borderColor: item?.color }}
                    />
                    {item?.name}
                  </div>
                  <div className='flex items-center whitespace-nowrap'>
                    {item?.value}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
