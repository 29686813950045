import { FormControl, FormControlLabel, Switch } from '@mui/material';
import React, { memo } from 'react';
import { useSearchParams } from 'react-router-dom';

const SwitchItemFilter = ({ label, queryName, disabled = false }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <FormControl color='formColor' fullWidth>
      <FormControlLabel
        control={
          <Switch
            id={queryName}
            name={queryName}
            checked={searchParams.get(queryName) ? true : false}
            disabled={disabled}
            onChange={(event) => {
              if (event.target.checked) searchParams.set(queryName, 'true');
              else searchParams.delete(queryName);
              setSearchParams(searchParams);
            }}
          />
        }
        labelPlacement='start'
        label={label}
      />
    </FormControl>
  );
};

export default memo(SwitchItemFilter);
