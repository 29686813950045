import React, { useState } from 'react';

import ShapeIconCrm from './ShapeIconCrm';
import { useTranslation } from 'react-i18next';

const StatisticsOfEmployee = ({ data = {} }) => {
  const [show, setShow] = useState(false);

  const { t } = useTranslation();

  // handle show
  const handleShow = () => {
    setShow(!show);
  };

  const getName = (name) => {
    if (name.length > 15) {
      return name.slice(0, 15) + '...';
    }
    return name;
  };

  return (
    <div className='transition-all'>
      {show && (
        <div className={'mb-4'}>
          {data?.factEmployeeDtoList?.map((factEmployee, index) => {
            const list = [];

            for (let key in factEmployee.factPipeStatusMap) {
              list.push({
                color: key,
                count: factEmployee.factPipeStatusMap[key],
              });
            }

            return (
              <div
                key={index}
                className={'flex mb-4 items-center justify-between'}
              >
                <span title={factEmployee.employeeCompactDto.fullName}>
                  {window.innerWidth <= 1280
                    ? getName(factEmployee.employeeCompactDto.fullName)
                    : factEmployee.employeeCompactDto.fullName}
                </span>
                <div className={'flex flex-wrap items-center'}>
                  {list?.map((item, i) => (
                    <div
                      key={i}
                      className={
                        'border border-[#f2f2f2] rounded-[20px] py-1 px-2 ml-2 flex items-center'
                      }
                    >
                      <ShapeIconCrm color={item.color} />
                      <span className={'text-[14px] leading-[17px] ml-1'}>
                        {item.count}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className='flex justify-center'>
        <div
          onClick={handleShow}
          className={
            'border border-[#f2f2f2] rounded-[40px] py-3 px-4 m-0 w-max bg-white cursor-pointer'
          }
        >
          {show ? (
            <div>
              <span>{t('Yopish')}</span>
              <i className={'ml-1 bi bi-chevron-up'} />
            </div>
          ) : (
            <div>
              <span>{t("Batafsil ko'rsatish")}</span>
              <i className={'ml-1 bi bi-chevron-down'} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StatisticsOfEmployee;
