import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, ButtonBase, Popover } from '@mui/material';

import FormPhoneField2 from 'components/ui/form/FormPhoneField2';
import useFormSubmit from 'hooks/useFormSubmit';

export default function ContactInputForm({
  index,
  formik,
  contact,
  handleAddPhone,
  contactInd,
}) {
  const { submit, isSubmitting } = useFormSubmit();
  const [isEditing, setIsEditing] = useState(
    !formik.values?.contacts?.[contactInd]?.phones?.[index]?.phone
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const { id: leadId } = useParams();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onEdit = () => {
    setAnchorEl(null);
    setIsEditing(true);
  };
  const onCopy = () => {
    navigator.clipboard.writeText(
      formik.values?.contacts?.[contactInd]?.phones?.[index]?.phone || ''
    );
  };

  const sendMessage = () => {
    const body = {
      phone: formik.values?.contacts?.[contactInd]?.phones?.[index]?.phone,
    };
    submit(
      { type: 'post', contentType: 'formData' },
      body,
      `/crm/crm-lead/send-sms/${leadId}`,
      body.phone,
      null,
      true,
      handleClose
    );
  };

  return (
    <div className='item-value contact-value phone-value'>
      {index == contact.phones.length - 1 ? (
        <ButtonBase
          className='add-phone-btn'
          onClick={() => handleAddPhone(contactInd)}
        >
          <i className='bi bi-plus-circle' />
        </ButtonBase>
      ) : (
        <div className='mr-6'></div>
      )}
      {isEditing && (
        <FormPhoneField2
          className='cursor-pointer'
          delay={0}
          duration={0}
          label=''
          fieldName={`contacts.${contactInd}.phones.${index}.phone`}
          formik={formik}
          variant='standard'
          autocomplete='off'
          onBlur={() =>
            formik.values?.contacts?.[contactInd]?.phones?.[index]?.phone
              ? setIsEditing(false)
              : null
          }
        />
      )}
      {!isEditing && (
        <div
          className='text-sm cursor-pointer border-b border-black w-full py-1 border-t border-t-transparent'
          onClick={handleClick}
          tabIndex={1}
        >
          {formik.values?.contacts?.[contactInd]?.phones?.[index]?.phone || ''}
        </div>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className='flex flex-col'>
          <Link
            className='add-phone-btn no-underline'
            to={`tel:${formik.values?.contacts?.[contactInd]?.phones?.[index]?.phone}`}
          >
            <Button
              size='small'
              fullWidth
              startIcon={<i className='bi bi-telephone' />}
              className='!px-3 !rounded-none !text-gray-700 hover:!bg-gray-200'
              sx={{ justifyContent: 'start' }}
            >
              Qo'ng'iroq qilish
            </Button>
          </Link>
          <Button
            size='small'
            fullWidth
            startIcon={
              isSubmitting ? (
                <i className='bi bi-arrow-repeat animate-spin' />
              ) : (
                <i className='bi bi-envelope' />
              )
            }
            className='!px-3 !rounded-none !text-gray-700 hover:!bg-gray-200'
            sx={{ justifyContent: 'start' }}
            onClick={sendMessage}
          >
            SMS yuborish
          </Button>
          <Link
            className='add-phone-btn no-underline'
            // target='_blank'
            to={`https://t.me/${formik.values?.contacts?.[contactInd]?.phones?.[index]?.phone}`}
          >
            <Button
              size='small'
              fullWidth
              startIcon={<i className='bi bi-send' />}
              className='!px-3 !rounded-none !text-gray-700 hover:!bg-gray-200'
              sx={{ justifyContent: 'start' }}
            >
              Telegramda ochish
            </Button>
          </Link>

          <Button
            size='small'
            fullWidth
            startIcon={<i className='bi bi-clipboard-check' />}
            className='!px-3 !rounded-none !text-gray-700 hover:!bg-gray-200'
            sx={{ justifyContent: 'start' }}
            onClick={onCopy}
          >
            Nusxa olish
          </Button>
          <Button
            size='small'
            fullWidth
            startIcon={<i className='bi bi-pencil' />}
            className='!px-3 !rounded-none !text-gray-700 hover:!bg-gray-200'
            sx={{ justifyContent: 'start' }}
            onClick={onEdit}
          >
            Tahrirlash
          </Button>
        </div>
      </Popover>
    </div>
  );
}
