import { Button } from '@mui/material';
import React, { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default memo(function Navigators() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const toBack = () => {
    if (state?.from === 'events-page') {
      return navigate(`${state?.path}${state?.search}`, {
        state: { allowed: true },
      });
    }
    navigate(-1, { state: { allowed: true } });
  };

  return (
    <div className='flex gap-[10px]'>
      <Button
        onClick={() => {
          toBack();
        }}
        size='small'
        variant='outlined'
        sx={{ minWidth: 30 }}
        disabled={window.history.state.idx == 0}
      >
        <i className='bi bi-arrow-left-short !text-lg !leading-[1.8rem]' />
      </Button>
      <Button
        onClick={() => navigate(1, { state: { allowed: true } })}
        size='small'
        variant='outlined'
        sx={{ minWidth: 30 }}
        disabled={window.history.state.idx == window.history.length - 1}
      >
        <i className='bi bi-arrow-right-short !text-lg !leading-[1.8rem]' />
      </Button>
    </div>
  );
});
