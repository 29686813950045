import { CircularProgress } from '@mui/material';
import DoughnutChart from 'components/ui/charts/DoughnutChart';
import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colorSeparators } from 'shared/colors';

function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const OrderStatistics = ({ pipes, staffs, isLoading }) => {
  const { t } = useTranslation();
  const [seeMore, setSeeMore] = useState(false);
  const [seeMoreUser, setSeeMoreUser] = useState(false);

  const coloredPipes = useMemo(() => {
    return pipes
      ?.sort((a, b) => b?.procent - a?.procent)
      ?.map((p) => ({ ...p, color: getRandomColor() }));
  }, [pipes]);

  const sortedStaffs = useMemo(() => {
    return staffs?.sort((a, b) => b?.procent - a?.procent);
  }, [pipes]);

  return (
    <div className='residential-complex-item total-statistics'>
      <div className='residential-complex-item-header'>
        <div className='residential-complex-item-title total-statistics-title text-lg font-semibold'>
          Bitimlar
        </div>
      </div>
      {isLoading ? (
        <div className='h-[300px] flex items-center justify-center'>
          <CircularProgress size={30} color='inherit' />
        </div>
      ) : (
        <div className='total-statistics-body grid grid-cols-[300px,1fr,300px]'>
          <div className='total-statistics-body-header'>
            <div className='total-statistics-body-title  my-2'>
              Bosqichlar bo'yicha
            </div>
            <div className='mt-2'>
              {coloredPipes
                ?.slice(0, seeMore ? coloredPipes?.length : 6)
                ?.map((pipe) => (
                  <div
                    key={pipe?.name}
                    className='grid grid-cols-[80px,50px,1fr] mb-2'
                  >
                    <div className='text-right'>{parseInt(pipe?.procent)}%</div>
                    <div className='relative mx-auto flex justify-center gap-0.5'>
                      <div
                        className={'h-4 mt-2 w-1 rounded-full'}
                        style={{ background: pipe?.color }}
                      />
                      <div
                        className={'h-5 mb-2 w-1 rounded-full'}
                        style={{ background: pipe?.color }}
                      />
                      <div
                        className={'h-4 mt-1.5 w-1 rounded-full'}
                        style={{ background: pipe?.color }}
                      />
                    </div>
                    <div>
                      <div>{pipe?.name}</div>
                      <div className='text-sm text-gray-500'>
                        {pipe?.count} ta bitim
                      </div>
                    </div>
                  </div>
                ))}
              {seeMore ? null : (
                <div className='grid grid-cols-[80px,50px,1fr]'>
                  <div className='text-right'>
                    {parseInt(
                      coloredPipes
                        ?.slice(5, coloredPipes?.length - 6)
                        ?.reduce((a, c) => a + c?.procent, 0)
                    )}
                    %
                  </div>
                  <div className='relative mx-auto flex justify-center gap-0.5'>
                    <div
                      className={'h-4 bg-orange-400 mt-2 w-1 rounded-full'}
                    />
                    <div
                      className={'h-5 bg-orange-400 mb-2 w-1 rounded-full'}
                    />
                    <div
                      className={'h-4 bg-orange-400 mt-1.5 w-1 rounded-full'}
                    />
                  </div>
                  <div>
                    <div
                      className='underline cursor-pointer'
                      onClick={() => setSeeMore(true)}
                    >
                      Hammasini ko'rish
                    </div>
                    <div className='text-sm text-gray-500'>
                      {parseInt(
                        coloredPipes
                          ?.slice(5, coloredPipes?.length - 6)
                          ?.reduce((a, c) => a + c?.count, 0)
                      )}{' '}
                      ta bitim
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {
            <div className='total-statistics-chart h-[300px]'>
              <div className='relative h-96 w-96 mx-auto'>
                <DoughnutChart
                  chartLabels={coloredPipes?.map((p) => p?.name)}
                  chartDatasets={[
                    {
                      data: coloredPipes?.map((p) => p?.count),
                      backgroundColor: coloredPipes?.map((p) => p?.color),
                      borderWidth: 2,
                      hoverOffset: 15,
                      hoverBorderWidth: 0,
                      borderRadius: 4,
                    },
                  ]}
                  suffix=''
                  optionPlugins={{
                    legend: {
                      display: false,
                    },
                  }}
                  options={{
                    layout: {
                      padding: {
                        top: 10,
                        bottom: 10,
                      },
                    },
                    parsing: {
                      key: 'total',
                    },
                  }}
                />
                <div className='text-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                  <p className='text-sm text-gray-500'>
                    {coloredPipes?.reduce((a, c) => a + c?.count, 0)}
                  </p>
                  <h1 className='text-[35px]'>
                    {coloredPipes?.reduce((a, c) => a + c?.count, 0)}
                  </h1>
                  <p className='text-sm text-gray-500'>Bitim</p>
                </div>
              </div>
            </div>
          }
          <div className='total-statistics-body-header'>
            <div className='total-statistics-body-title my-2'>
              Xodimlar bo'yicha
            </div>
            {
              <div className='mt-2'>
                {sortedStaffs
                  ?.slice(0, seeMoreUser ? sortedStaffs?.length : 6)
                  ?.map((pipe) => (
                    <div
                      key={pipe?.name}
                      className='grid grid-cols-[80px,50px,1fr] mb-2'
                    >
                      <div className='text-right'>
                        {parseInt(pipe?.procent)}%
                      </div>
                      <div className='h-[calc(100%-8px)] my-1 w-1.5 rounded-full bg-yellow-400 mx-auto' />
                      <div>
                        <div>{pipe?.name}</div>
                        <div className='text-sm text-gray-500'>
                          {pipe?.count} ta bitim
                        </div>
                      </div>
                    </div>
                  ))}
                {seeMoreUser ? null : (
                  <div className='grid grid-cols-[80px,50px,1fr]'>
                    <div className='text-right'>
                      {parseInt(
                        sortedStaffs
                          ?.slice(5, sortedStaffs?.length - 6)
                          ?.reduce((a, c) => a + c?.procent, 0)
                      )}
                      %
                    </div>
                    <div className='h-[calc(100%-8px)] my-1 w-1.5 bg-yellow-400 rounded-full mx-auto' />
                    <div>
                      <div
                        className='underline cursor-pointer'
                        onClick={() => setSeeMoreUser(true)}
                      >
                        Hammasini ko'rish
                      </div>
                      <div className='text-sm text-gray-500'>
                        {parseInt(
                          sortedStaffs
                            ?.slice(5, sortedStaffs?.length - 6)
                            ?.reduce((a, c) => a + c?.count, 0)
                        )}{' '}
                        ta bitim
                      </div>
                    </div>
                  </div>
                )}
              </div>
            }
          </div>
        </div>
      )}
    </div>
  );
};
export default memo(OrderStatistics);
