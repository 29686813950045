import React, { memo } from 'react';
import { Dialog, DialogTitle } from '@mui/material';
import { t } from 'i18next';
import { useFormik } from 'formik';
import useFormSubmit from 'hooks/useFormSubmit';
import { useAppInfo } from 'context';
import FormActionButtons from 'components/ui/form/FormActionButtons';

export default memo(function TaskDeleteModal({
  data,
  open,
  setOpen,
  refetchFn = () => {},
}) {
  const onClose = () => setOpen(null);
  const [{ refetchTaskCount }] = useAppInfo();
  const { submit, isSubmitting } = useFormSubmit();
  const formik = useFormik({
    onSubmit: async () => {
      if (data?.length) {
        const body = { ids: data };
        submit(
          { type: 'post', contentType: 'simple' },
          body,
          '/crm/task/delete',
          'delete task',
          null,
          true,
          () => {
            refetchFn();
            refetchTaskCount?.();
          }
        );
      }
    },
  });
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='lg'
      PaperProps={{ className: '!base-border !shadow-none !overflow-hidden' }}
    >
      <DialogTitle
        id='alert-dialog-title'
        className='!bg-[#CDCDCDC7] !text-center !border-b !border-b-[#E6E6E6]'
      >
        <span className='pr-5 font-semibold text-[21px] text-[#374957]'>
          {t('tasks.delete.title')}
        </span>
      </DialogTitle>
      <div className='py-4 px-8 min-w-[600px]'>
        <form onSubmit={formik.handleSubmit}>
          <div className='flex gap-2 items-center'>
            <div className='rounded-full w-12 h-12 bg-red-50 flex  items-center justify-center'>
              <div className='rounded-full w-8 h-8 bg-red-100 flex  items-center justify-center'>
                <i className='bi bi-trash text-lg text-red-600'></i>
              </div>
            </div>

            <span>{t('tasks.delete.description')}</span>
          </div>
          <div className='flex justify-center flex-col gap-4'>
            <div className='flex justify-end'>
              <FormActionButtons
                delay={0}
                isSubmitting={isSubmitting}
                formType='dialog'
                setOpen={setOpen}
                reset={formik.resetForm}
              />
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  );
});
