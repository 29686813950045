import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useNotification from 'hooks/useNotification';
import React, { useState } from 'react';
import moment from 'moment';
import { taskStatusTypeVariants } from 'shared/tableColVariantsList';
import { useTranslation } from 'react-i18next';
import TASK_STATUS_TYPE from 'shared/taskStatusTypeList';

const CRMTasksModal = (props) => {
  const { open, setOpen, tasks, refetchFn = () => {} } = props;
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const sendNotification = useNotification();
  const axiosPrivate = useAxiosPrivate();

  const setOrderStatus = (item) => {
    let result = taskStatusTypeVariants.filter(
      (variant) => variant.code == item
    );
    if (result.length > 0) {
      return (
        <Chip
          label={t(result[0].label)}
          variant='tableBadge'
          color={result[0].color}
          sx={{
            height: '24px',
            fontSize: '0.875rem',
          }}
        />
      );
    }
    return '';
  };

  const handleTaskDone = async (taskId) => {
    try {
      setIsSubmitting(true);
      let response = await axiosPrivate.post(`/crm/task/done/${taskId}`);
      if (response.data && response.data.status) {
        sendNotification({
          msg: 'Topshiriq muvaffaqiyatli bajarildi!',
          variant: 'success',
        });
        refetchFn();
      }
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
    }
  };
  const handleTaskReject = () => {};

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      disableEscapeKeyDown={true}
    >
      <DialogTitle id='order-view-dialog-title'>
        <div className='text-xl text-center'>Topshiriqlar</div>
        <div className='close-btn-wrapper'>
          <IconButton variant='onlyIcon' color='primary' onClick={handleClose}>
            <i className='bi bi-x' />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        <div className='my-2 min-w-[500px]'>
          {tasks && Object.keys(tasks).length > 0 ? (
            Object.keys(tasks).map((taskItem, taskIndex) => (
              <div className='date-task-wrapper' key={`date-task-${taskIndex}`}>
                <div className='cursor-default select-none text-center'>
                  {moment(taskItem, 'DD_MM_YYYY').format('DD.MM.YYYY')}
                </div>
                <div className='date-items-wrapper'>
                  {tasks[taskItem] &&
                    tasks[taskItem].map((item, itemIndex) => (
                      <div
                        className='my-shadow-1 p-3 rounded-lg my-4'
                        key={`date-task-items-${itemIndex}`}
                      >
                        <div className='text-sm text-gray-500 flex flex-col'>
                          <div>
                            Yaratilgan sana:{' '}
                            {moment(item?.created_at).format(
                              'DD.MM.YYYY HH:mm'
                            )}
                          </div>
                          <div className='flex fex-row items-center'>
                            Biriktirilgan xodim:{' '}
                            <div className='border-2 border-base-color-light text-base-color-light text-sm leading-4 font-medium py-0.5 px-1 rounded-lg ml-1'>
                              {item?.name}
                            </div>
                          </div>
                          <div>
                            Bajarilishi kerak bo'lgan sana:{' '}
                            {moment(item?.date).format('DD.MM.YYYY HH:mm')}
                          </div>
                          <div>Status: {setOrderStatus(item?.status)}</div>
                        </div>
                        <div className='mt-2 pt-2 border-t-2 border-gray-300'>
                          {item?.title}
                        </div>
                        <div className='flex items-center justify-end mt-1'>
                          {item.status ==
                            TASK_STATUS_TYPE.STATUS_ACTIVE.code && (
                            <Button
                              color='success'
                              variant='outlined'
                              size='small'
                              onClick={() => handleTaskDone(item?.id)}
                              disabled={isSubmitting}
                            >
                              {isSubmitting ? (
                                <CircularProgress
                                  size={15}
                                  color='inherit'
                                  className='mr-1'
                                />
                              ) : (
                                <i className='bi bi-check2-circle text-lg mr-1'></i>
                              )}
                              Bajarildi
                            </Button>
                          )}

                          {/* <Button
									variant="contained"
									color="inherit"
									size="small"
									onClick={() => handleTaskReject()}
									disabled={isSubmitting}
								>
									Rad etish
								</Button> */}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))
          ) : (
            <div className='no-data-found-wrapper'>
              <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
              {t('common.global.noDataFound')}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CRMTasksModal;
