import React, { useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import DayItem from './DayItem';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import DateSlider from './DateSlider';
import { useNavigate } from 'react-router-dom';

const DATE_WITH_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const DATE_FORMAT = 'YYYY-MM-DD';

const DayView = ({
  data,
  date,
  dateRange,
  setDateRange,
  setSelected,
  isLoading,
}) => {
  const calendarRef = useRef(null);
  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    if (data && data?.length) {
      const events = [];
      data.forEach((item) => {
        const dateEvent = new Date(
          moment(item.date, DATE_WITH_TIME_FORMAT).format()
        );
        events.push({
          id: String(item.id),
          resourceId: item,
          start: dateEvent,
          end: dateEvent,
        });
      });
      const calendarApi = calendarRef?.current?.getApi();

      calendarApi?.gotoDate(
        new Date(moment(date, DATE_FORMAT).format()).toISOString()
      );
      calendarApi?.removeAllEventSources();
      calendarApi?.addEventSource([...events]);
    }
    if (data?.length === 0) {
      const calendarApi = calendarRef?.current?.getApi();

      calendarApi?.gotoDate(
        new Date(moment(date, DATE_FORMAT).format()).toISOString()
      );
      calendarApi?.removeAllEventSources();
    }
  }, [data, calendarRef, date]);

  // handle open complete modal
  const handleOpenCompleteModal = (task) => {
    // if (!task.closedDate) setSelected(task);
    if (task?.crm_lead?.id)
      navigate(`/admin/crm/lead/edit/${task?.crm_lead?.id}`);
  };

  return (
    <div>
      <DateSlider dateRange={dateRange} setDateRange={setDateRange} />
      <FullCalendar
        ref={calendarRef}
        initialView='timeGridDay'
        height={'calc(100vh - 230px)'}
        allDayText={t('tasks.title')}
        locale={'ru-Ru'}
        eventMinHeight={20}
        progressiveEventRendering
        allDaySlot={true}
        dayMaxEvents={true}
        eventClassNames={'c_TimePicker'}
        viewClassNames={'c_container'}
        dayCellClassNames={'m_fc_AllDay'}
        selectMirror={true}
        dragScroll={true}
        eventContent={(e) => {
          return <DayItem data={e?.event?._def?.extendedProps?.resourceId} />;
        }}
        eventClick={(e) => {
          handleOpenCompleteModal(e?.event?._def?.extendedProps?.resourceId);
        }}
        moreLinkContent={(e) => {
          return (
            <div>
              {e.shortText} {t('tasks.title')}
            </div>
          );
        }}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={false}
        dayHeaders={false}
        slotLabelFormat={[
          {
            hour: '2-digit',
            minute: '2-digit',
          },
        ]}
      />
    </div>
  );
};

export default DayView;
