import React, { useState } from 'react';
import CRMFeatureTaskMessage from './message-types/CRMFeatureTaskMessage';

export default function FeatureTasks({ tasks, refetch }) {
  const [openId, setOpenId] = useState('');
  return tasks?.map((task) => (
    <CRMFeatureTaskMessage
      openId={openId}
      setOpenId={setOpenId}
      key={task?.id}
      data={task}
      refetchFn={refetch}
    />
  ));
}
