const colors = {
  blackColor: '#000000',
  whiteColor: '#ffffff',
  baseColor: '#7947F8',
  baseColorLight: '#ad97f7',
  baseColorLightF15: '#7947F826',
  baseColorLightHover: '#7947F826',
  baseColorDisabled: '#406190',
  baseColorHover: '#7947F8',
  baseColorActive: '#7947F8',
  baseColorOutline: '#7947F8',
  baseColorDeprecatedBg: '#478DEF',
  baseColorDeprecatedBorder: '#7947F8',
  base1: '#dadee4',
  text1: '#5C5E6C',
  base2: '#d7c6df',
  base3: '#478DEF',
  base4: '#7947F8',
  baseColorActiveDeprecatedF30: '#dbc8e64e',
  baseColorActiveDeprecatedD02: '#d5c5de',
  warningColor: '#f8a62a',
  successColor: '#57c820',
  infoColor: '#0288d1',
  errorColor: '#f40606',
  shadowColor: '#478DEF85',
  formColor: '#007bb2',
  footerColor: '#333333',
  grayColor: '#9ca3af',
};

const colorSeparators = {
  blackColor: '0, 0, 0',
  whiteColor: '255, 255, 255',
  baseColor: '121, 71, 248',
  baseColorLight: '173, 151, 247',
  baseColorLightF15: '121, 71, 248, 0.15',
  baseColorLightHover: '121, 71, 248, 0.15',
  baseColorDisabled: '64, 97, 144',
  baseColorHover: '121, 71, 248',
  baseColorActive: '121, 71, 248',
  baseColorOutline: '121, 71, 248',
  baseColorDeprecatedBg: '71, 141, 239',
  baseColorDeprecatedBorder: '121, 71, 248',
  base1: '218, 222, 228',
  base2: '215, 198, 223',
  base3: '71, 141, 239',
  base4: '122, 26, 174',
  baseColorActiveDeprecatedF30: '219, 200, 230, 0.306',
  baseColorActiveDeprecatedD02: '213, 197, 222',
  warningColor: '248, 166, 42',
  successColor: '87, 200, 32',
  infoColor: '2, 136, 209',
  errorColor: '245, 5, 5',
  formColor: '0, 123, 178',
  footerColor: '51, 51, 51',
};

module.exports = { colors, colorSeparators };
