import { Avatar, Button, CircularProgress, Grid } from '@mui/material';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import useAxiosPrivate from 'hooks/useAxiosPrivate';
import NameChangeModal from './NameChangeModal';
import LoginChangeModal from './LoginChangeModal';
import PasswordChangeModal from './PasswordChangeModal';
import ProfileSale from './ProfileSale';

export default memo(function PersonalInfo() {
  const axiosPrivate = useAxiosPrivate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);

  const profileData = useQuery({
    queryKey: ['/admin/profile/edit'],
    queryFn: async () => {
      const response = await axiosPrivate.get('/admin/profile/edit');
      return response?.data?.data;
    },
    onError: (err) => {},
  });

  const data = profileData?.data || {};

  return (
    <>
      <Grid container spacing={2} columns={12}>
        <Grid item xs={6}>
          <div className='bg-white base-border p-6 px-8'>
            {profileData?.isFetching || profileData?.isLoading ? (
              <div className='h-full p-28 flex justify-center'>
                <CircularProgress size={40} />
              </div>
            ) : (
              <div className='flex gap-2'>
                <div className='profile-avatar flex flex-col items-center justify-center'>
                  <div className='profile-image'>
                    <Avatar
                      alt={data?.name}
                      src={data?.image}
                      sx={{ width: 120, height: 120 }}
                    />
                  </div>
                  <div className='mt-4'>
                    <Button>{t('common.button.update')}</Button>
                    <Button className='!text-[#FF6D6D]'>
                      {t('common.button.delete')}
                    </Button>
                  </div>
                </div>
                <div className='profile-infos ml-2'>
                  <div>
                    <div className='title text-gray-500 ml-2'>
                      {t('profile.fullName')}
                    </div>
                    <div className='ml-2 mt-2'>{data?.name}</div>
                    <Button onClick={() => setOpen(true)}>
                      {t('profile.changeName')}
                    </Button>
                  </div>
                  <div className='mt-4'>
                    <div className='title text-gray-500 ml-2'>
                      {t('profile.position')}
                    </div>
                    <div className='ml-2 mt-2'>{data?.role_name}</div>
                    <div className='title mt-2 text-gray-500 ml-2'>
                      {t('profile.username')}
                    </div>
                    <div className='ml-2 mt-2'>@{data?.login}</div>
                    <Button onClick={() => setOpenLogin(true)}>
                      {t('profile.changeLogin')}
                    </Button>
                  </div>
                  <div className='mt-4'>
                    <div className='title text-gray-500 ml-2'>
                      {t('common.fields.password')}
                    </div>
                    <Button onClick={() => setOpenPassword(true)}>
                      {t('profile.changePassword')}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className='bg-white base-border p-6 px-8'>
            <ProfileSale />
          </div>
        </Grid>
      </Grid>

      {profileData?.data ? (
        <NameChangeModal
          open={open}
          setOpen={setOpen}
          data={data}
          setRefetch={profileData.refetch}
        />
      ) : null}
      {profileData?.data ? (
        <LoginChangeModal
          open={openLogin}
          setOpen={setOpenLogin}
          data={data}
          setRefetch={profileData.refetch}
        />
      ) : null}
      {profileData?.data ? (
        <PasswordChangeModal
          open={openPassword}
          setOpen={setOpenPassword}
          data={data}
          setRefetch={profileData.refetch}
        />
      ) : null}
    </>
  );
});
