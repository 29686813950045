import { Button, Tooltip } from '@mui/material';
import { t } from 'i18next';
import React from 'react';

export default function TableSelectActions({
  selectedRows = [],
  setActionType,
}) {
  return (
    <div
      className={`header-actions filter-box flex items-center justify-between bg-white px-4 py-1 w-full ${
        selectedRows?.length
          ? 'base-border h-[45px] min-h-[45px] opacity-100 mb-4'
          : 'h-0 opacity-0'
      } overflow-hidden transition-all`}
    >
      <div>
        {selectedRows?.length} {t('tasks.actions.checkedCount')}
      </div>
      <div className='flex items-center gap-1'>
        <Tooltip placement='top' title={t('tasks.actions.complete')}>
          <div>
            <Button title='check' onClick={() => setActionType('complete')}>
              <i className='bi bi-calendar-check' />
            </Button>
          </div>
        </Tooltip>
        <Tooltip placement='top' title={t('tasks.actions.delete')}>
          <div>
            <Button onClick={() => setActionType('delete')}>
              <i className='bi bi-trash3' />
            </Button>
          </div>
        </Tooltip>
        <Tooltip placement='top' title={t('tasks.actions.changeStaff')}>
          <div>
            <Button onClick={() => setActionType('staff')}>
              <i className='bi bi-person-add' />
            </Button>
          </div>
        </Tooltip>
        <Tooltip placement='top' title={t('tasks.actions.changeTime')}>
          <div>
            <Button onClick={() => setActionType('time')}>
              <i className='bi bi-clock' />
            </Button>
          </div>
        </Tooltip>
        <Tooltip placement='top' title={t('tasks.actions.changeType')}>
          <div>
            <Button onClick={() => setActionType('type')}>
              <i className='bi bi-circle-square' />
            </Button>
          </div>
        </Tooltip>
      </div>
    </div>
  );
}
