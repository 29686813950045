import { useEffect, useState } from 'react';
import FilterAccordion from '../FilterAccordion';
import { Button } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { t } from 'i18next';

const HomeStageFilter = ({ filterStages = 1, disabled = false }) => {
  const [stages, setStages] = useState(
    Array.from({ length: filterStages }, (value, index) => index + 1)
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedStages, setSelectedStages] = useState([]);

  const handleStageChange = (value) => {
    let entries = Object.fromEntries(searchParams);
    Object.keys(entries).forEach((item) => {
      if (item && item.includes('stage')) {
        searchParams.delete(item);
      }
    });
    if (isSelected(value)) {
      selectedStages.splice(selectedStages.indexOf(value), 1);
    } else {
      selectedStages.push(value);
    }
    selectedStages.forEach((item, index) => {
      searchParams.set(`stage[${index}]`, item);
    });
    setSearchParams(searchParams);
    setSelectedStages(selectedStages);
  };

  const isSelected = (stage) => {
    return selectedStages.includes(stage);
  };

  const setDefaultStages = () => {
    let entries = Object.fromEntries(searchParams);
    let newStages = [];
    Object.keys(entries).forEach((item) => {
      if (item && item.includes('stage')) {
        newStages.push(parseInt(searchParams.get(item)));
      }
    });
    setSelectedStages(newStages);
  };

  useEffect(() => {
    setDefaultStages();
  }, [searchParams]);

  return (
    <div className='mt-3'>
      <div className='filter-title mb-2'>{t('shaxmatka.homeDetail.stage')}</div>
      {stages && stages.length > 0 && (
        <div className='home-stages-wrapper'>
          {stages.map((item) => (
            <Button
              className={`stage-item${
                isSelected(item) ? ' item-selected !bg-base-color-light' : ''
              } !bg-gray-100 !min-w-[35px] !h-[25px]`}
              color='primary'
              variant={isSelected(item) ? 'contained' : 'outlined'}
              key={`home-filter-stage-${item}`}
              onClick={() => handleStageChange(item)}
              sx={{
                border: '0',
                borderRadius: '15px',
                minWidth: '35px',
                width: 35,
                maxWidth: 'auto',
              }}
              disabled={disabled}
            >
              {item}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};
export default HomeStageFilter;
