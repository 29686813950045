import {
  Button,
  ButtonGroup,
  Chip,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
// import ImagePreviewDialog from 'components/ui/dialogs/ImagePreviewDialog';
import LightBox from 'components/ui/dialogs/LightBox';
import CurrencyFormat from 'components/ui/text-formats/CurrencyFormat';
import PhoneFormat from 'components/ui/text-formats/PhoneFormat';
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { Link } from 'react-router-dom';
import HOME_TYPE from 'shared/homeTypeList';
import {
  homeTypeVariants,
  repairTypeVariants,
  residentTypeVariants,
} from 'shared/tableColVariantsList';
import IMAGE_TYPE from 'shared/imageTypeList';
import REPAIR_TYPE from 'shared/repairTypeList';
import { formatMultiplies } from 'utils/formatOddDecimal';
import ShaxmatkaMoreHomeDetailsModal from './ShaxmatkaMoreHomeDetailsModal';

const ShaxmatkaHomeDetail = ({
  selectedHome = [],
  blocks = [],
  object = {},
  refetchFn = () => {},
}) => {
  const { t } = useTranslation();
  const [homeData, setHomeData] = useState({});
  const [homes, setHomes] = useState([]);
  const [selectedHomeIndex, setSelectedHomeIndex] = useState(0);
  const [blockData, setBlockData] = useState({});
  const [refetch, setRefetch] = useState(false);
  const [openHomeLayoutImageDialog, setOpenHomeLayoutImageDialog] =
    useState(false);
  const [homeImageType, setHomeImageType] = useState(IMAGE_TYPE['2D'].code);
  const [openMoreDetailModal, setOpenMoreDetailModal] = useState(false);

  useEffect(() => {
    if (selectedHome.length > 0 && blocks.length > 0) {
      const { blockIndex, padezdIndex, id } = selectedHome[0];
      let blockD = { ...blocks[blockIndex] };
      let entranceD = { ...blocks?.[blockIndex]?.padezd?.[padezdIndex] };

      let homeD = blocks?.[blockIndex]?.padezd?.[padezdIndex]?.homes?.find(
        (item) => item.id == id
      );
      homeD = { ...homeD, blockIndex, padezdIndex, id };
      if (entranceD.hasOwnProperty('homes')) delete entranceD.homes;
      if (blockD.hasOwnProperty('padezd')) {
        delete blockD.padezd;
      }

      homeD.entrance = entranceD;
      homeD.blocks = blockD;
      homeD.blocks.objects = object;
      setBlockData(blockD);
      if (
        (homeD?.contract && homeD?.contract?.id) ||
        homeD?.status == HOME_TYPE.TIME.code ||
        homeD?.status == HOME_TYPE.DISABLED.code
      ) {
        return setHomeData(homeD);
      }
      if (homes?.length) {
        if (homes?.every((home) => home?.id !== homeD?.id)) {
          setHomeData(homeD);
          setHomes((homes) =>
            homes?.map((home, i) => (i == selectedHomeIndex ? homeD : home))
          );
        }
      } else {
        setHomeData(homeD);
        setHomes([homeD]);
      }
    }
  }, [selectedHome]);

  useEffect(() => {
    if (refetch) {
      refetchFn();
      setRefetch(false);
    }
  }, [refetch]);

  const images = useMemo(() => {
    const imgs = [];
    const titles = [];
    const captions = [];
    if (homeData?.plan?.link) {
      const link = `${process.env.REACT_APP_BACKEND_URL}/${homeData?.plan?.link}`;
      imgs.push(link);
      titles.push('3d image');
      captions.push('3d image');
    }
    if (homeData?.plan?.link2d) {
      const link = `${process.env.REACT_APP_BACKEND_URL}/${homeData?.plan?.link2d}`;
      imgs.push(link);
      titles.push('2d image');
      captions.push('2d image');
    }
    if (homeData?.plan?.location) {
      const link = `${process.env.REACT_APP_BACKEND_URL}/${homeData?.plan?.location}`;
      imgs.push(link);
      titles.push('location');
      captions.push('location');
    }
    return { imgs, titles, captions };
  }, [homeData?.plan?.link, homeData?.plan?.link2d, homeData?.plan?.location]);

  const setHomeType = (item, typeArr) => {
    const result = typeArr.filter((variant) => variant.code == item);
    if (result.length > 0) {
      return (
        <Chip
          label={t(result[0].label)}
          variant='tableBadge'
          color={result[0].color}
        />
      );
    }
    return '';
  };

  const onAddNewHome = () => {
    if (homes?.at(-1) !== null) {
      setSelectedHomeIndex(homes?.length);
      setHomes((homes) => [...homes, null]);
    }
  };
  const onRemoveHome = useCallback(
    (e, i) => {
      e?.stopPropagation();
      e?.preventDefault();
      setHomes((homes) => {
        const newHomes = [...homes];
        newHomes?.splice(i, 1);
        return newHomes;
      });
      setSelectedHomeIndex(0);
      setBlockData(homes?.[0]);
      onSelectHome(0);
    },
    [homes]
  );

  const onSelectHome = useCallback(
    (i) => {
      document
        .querySelectorAll('.home-item.item-selected')
        ?.forEach((item) => item.classList.remove('item-selected'));
      setSelectedHomeIndex(i);
      if (homes?.[i] !== null) {
        const { blockIndex, padezdIndex, id } = homes?.[i];
        document
          ?.querySelector(`.block-${blockIndex}-${padezdIndex}-home#home-${id}`)
          ?.classList?.toggle('item-selected');
        setHomeData(homes?.[i]);
      }
    },
    [homes]
  );

  return (
    <div className='sheet-actions-home-detail-wrapper'>
      <div className='flex gap-3 flex-wrap py-2'>
        {homes?.map((home, i) =>
          home ? (
            <div
              key={home?.id}
              className={`relative w-8 min-w-8 h-8 cursor-pointer bg-base-color text-white text-[12px] font-semibold flex justify-center items-center ${
                i === selectedHomeIndex ? 'border-2 border-orange-500' : ''
              }`}
              onClick={() => onSelectHome(i)}
            >
              {homes?.length > 1 && (
                <div
                  onClick={(e) => onRemoveHome(e, i)}
                  className='absolute -top-2 -right-2 w-[14px] h-[14px] rounded-full bg-red-500 text-white flex items-center justify-center'
                >
                  -
                </div>
              )}
              {home?.number}
            </div>
          ) : (
            <div
              key={'new-area-for-select'}
              className={`relative w-8 min-w-8 h-8 cursor-pointer text-gray-500 text-[12px] font-semibold flex justify-center items-center ${
                i === selectedHomeIndex
                  ? 'border-2 border-orange-500 border-solid'
                  : 'border border-dashed border-base-color'
              }`}
              onClick={() => onSelectHome(i)}
            >
              {homes?.length}
            </div>
          )
        )}
        <div
          className='w-8 min-w-8 h-8 border border-dashed border-gray-600 text-gray-500 text-[16px] font-semibold flex justify-center items-center cursor-pointer'
          onClick={onAddNewHome}
        >
          +
        </div>
      </div>
      {blockData && Object.keys(homeData).length > 0 && (
        <Fragment>
          {homeData?.contract && homeData?.contract?.id ? (
            <div className='mb-3'>
              <Link
                to={`/admin/contract/view/${homeData.contract.id}`}
                className='no-underline'
                // target='_blank'
              >
                <Button
                  color='error'
                  variant='contained'
                  fullWidth
                  className='h-10'
                  size='small'
                >
                  Shartnoma №{homeData?.contract?.name}
                </Button>
              </Link>
            </div>
          ) : homeData?.status == HOME_TYPE.TIME.code ? (
            <div className='mb-3'>
              <ButtonGroup variant='contained' fullWidth disableElevation>
                {homeData?.order && homeData?.order?.id && (
                  <Button
                    color='warning'
                    variant='contained'
                    fullWidth
                    className='!p-0 h-10'
                    size='small'
                  >
                    <Link
                      to={`/admin/order/view/${homeData.order.id}`}
                      className='no-underline py-1.5 px-3.5 whitespace-nowrap'
                      // target='_blank'
                    >
                      Buyurtma №{homeData?.order?.id}
                    </Link>
                  </Button>
                )}

                <Button
                  color='success'
                  variant='contained'
                  fullWidth
                  onClick={() => setOpenMoreDetailModal(true)}
                  className='h-10'
                  size='small'
                >
                  {t('shaxmatka.homeDetail.more')}
                </Button>
              </ButtonGroup>
            </div>
          ) : homeData?.status == HOME_TYPE.ACTIVE.code ? (
            <div className='mb-3'>
              <Button
                color='success'
                variant='contained'
                fullWidth
                onClick={() => setOpenMoreDetailModal(true)}
                className='h-10'
                size='small'
              >
                {t('shaxmatka.homeDetail.more')}
              </Button>
            </div>
          ) : (
            ''
          )}

          <div className='home-detail-header'>
            <div className='home-detail-title'>
              {t('shaxmatka.homeDetail.title', {
                blockName: homeData?.blocks?.name,
                homeNumber: homeData?.number,
              })}
            </div>
          </div>
          <div className='home-detail-body'>
            {homeData?.plan && homeData?.plan?.link ? (
              <Fragment>
                <div className='home-detail home-image-wrapper'>
                  <img
                    className='cursor-pointer'
                    src={`${process.env.REACT_APP_BACKEND_URL}/${
                      homeImageType == IMAGE_TYPE['2D'].code
                        ? homeData?.plan?.link2d
                        : homeData?.plan?.link
                    }`}
                    onClick={() => setOpenHomeLayoutImageDialog(true)}
                    alt={homeData?.plan?.name || 'home-2d-image'}
                  />
                  <Button
                    type='button'
                    initial='hidden'
                    animate='show'
                    viewport={{ once: true, amount: 0.25 }}
                    variant='action'
                    className='home-image-view-btn'
                    onClick={() => setOpenHomeLayoutImageDialog(true)}
                  >
                    <i className='bi bi-image' />
                  </Button>
                </div>
                <div>
                  <ToggleButtonGroup
                    variant='simple'
                    color='primary'
                    value={homeImageType}
                    exclusive={true}
                    aria-label='simple-toggle-button-group'
                    size='small'
                    fullWidth={true}
                  >
                    <ToggleButton
                      color='primary'
                      value={IMAGE_TYPE['2D'].code}
                      onClick={() => setHomeImageType(IMAGE_TYPE['2D'].code)}
                    >
                      <i className='bi bi-grid-fill text-base mr-1' />
                      {IMAGE_TYPE['2D'].label}
                    </ToggleButton>
                    <ToggleButton
                      color='primary'
                      value={IMAGE_TYPE['3D'].code}
                      onClick={() => setHomeImageType(IMAGE_TYPE['3D'].code)}
                    >
                      <i className='bi bi-box-fill text-base mr-1' />
                      {IMAGE_TYPE['3D'].label}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </Fragment>
            ) : (
              <div className='home-detail home-image-wrapper'>
                <img
                  src={require('assets/images/placeholder-image.jpg')}
                  alt='placeholder-image'
                />
              </div>
            )}

            {homeData?.contract?.id && (
              <Fragment>
                <div className='home-detail'>
                  <div className='home-detail-item'>
                    {t('shaxmatka.homeDetail.contractNumber')}:
                  </div>
                  <div className='home-detail-item-value'>
                    {homeData?.contract?.name || '—'}
                  </div>
                </div>
                <Divider />
                <div className='home-detail'>
                  <div className='home-detail-item'>
                    {t('shaxmatka.homeDetail.custom')}:
                  </div>
                  <div className='home-detail-item-value'>
                    {homeData?.contract?.custom ? (
                      <Link
                        to={`/admin/client/view/${homeData.contract.custom?.id}`}
                        className='underline font-medium'
                        // target='_blank'
                      >
                        {homeData.contract.custom?.surname}{' '}
                        {homeData.contract.custom?.name}
                      </Link>
                    ) : (
                      '—'
                    )}
                  </div>
                </div>
                <Divider />
                <div className='home-detail'>
                  <div className='home-detail-item'>
                    {t('shaxmatka.homeDetail.phone')}:
                  </div>
                  <div className='home-detail-item-value'>
                    {homeData?.contract?.custom?.phone ? (
                      <PhoneFormat value={homeData.contract.custom.phone} />
                    ) : (
                      '—'
                    )}
                  </div>
                </div>
                <Divider />
                {homeData?.contract?.custom?.phone2 && (
                  <Fragment>
                    <div className='home-detail'>
                      <div className='home-detail-item'>
                        {t('shaxmatka.homeDetail.phone2')}:
                      </div>
                      <div className='home-detail-item-value'>
                        <PhoneFormat value={homeData.contract.custom.phone2} />
                      </div>
                    </div>
                    <Divider />
                  </Fragment>
                )}
              </Fragment>
            )}

            <div className='home-detail'>
              <div className='home-detail-item'>
                {t('shaxmatka.homeDetail.homeNumber')}:
              </div>
              <div className='home-detail-item-value'>
                {homeData?.number || '—'}
              </div>
            </div>
            <Divider />
            <div className='home-detail'>
              <div className='home-detail-item'>
                {t('shaxmatka.homeDetail.stage')}:
              </div>
              <div className='home-detail-item-value'>
                {homeData?.stage || '—'}
              </div>
            </div>
            <Divider />
            <div className='home-detail'>
              <div className='home-detail-item'>
                {t('shaxmatka.homeDetail.rooms')}:
              </div>
              <div className='home-detail-item-value'>
                {homeData?.rooms || '—'}
              </div>
            </div>
            <Divider />

            {homeData?.contract?.id ? (
              <Fragment>
                <div className='home-detail'>
                  <div className='home-detail-item'>
                    {t('shaxmatka.homeDetail.areaAll')}:
                  </div>
                  <div className='home-detail-item-value'>
                    {homeData?.contract?.square ? (
                      <>
                        <NumericFormat
                          value={homeData?.contract?.square}
                          allowNegative={false}
                          displayType={'text'}
                          decimalScale={2}
                          className='w-full text-right'
                        />{' '}
                        <Trans i18nKey='common.global.meter'>
                          m<sup>2</sup>
                        </Trans>
                      </>
                    ) : (
                      '—'
                    )}
                  </div>
                </div>
                <Divider />
                <div className='home-detail'>
                  <div className='home-detail-item'>
                    <Trans i18nKey='shaxmatka.homeDetail.priceForSquare'>
                      m<sup>2</sup> narxi
                    </Trans>
                    :
                  </div>
                  <div className='home-detail-item-value'>
                    {homeData?.contract?.sum &&
                    homeData?.contract?.discount &&
                    homeData?.contract?.square ? (
                      <CurrencyFormat
                        value={
                          (parseFloat(homeData.contract.sum) -
                            parseFloat(homeData.contract.discount)) /
                          parseFloat(homeData.contract.square)
                        }
                        suffix={homeData?.isvalute == '1' ? ' $' : ' UZS'}
                        decimalScale={1}
                      />
                    ) : (
                      '—'
                    )}
                  </div>
                </div>
                <Divider />
                <div className='home-detail'>
                  <div className='home-detail-item'>
                    {t('shaxmatka.homeDetail.startPrice')}:
                  </div>
                  <div className='home-detail-item-value'>
                    {homeData?.contract?.start_price ? (
                      <CurrencyFormat
                        value={homeData.contract.start_price}
                        suffix={homeData?.isvalute == '1' ? ' $' : ' UZS'}
                        decimalScale={1}
                      />
                    ) : (
                      '—'
                    )}
                  </div>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div className='home-detail'>
                  <div className='home-detail-item'>
                    {t('shaxmatka.homeDetail.areaAll')}:
                  </div>
                  <div className='home-detail-item-value'>
                    {homeData?.square ? (
                      <>
                        <NumericFormat
                          value={homeData?.square}
                          allowNegative={false}
                          displayType={'text'}
                          decimalScale={2}
                          className='w-full text-right'
                        />{' '}
                        <Trans i18nKey='common.global.meter'>
                          m<sup>2</sup>
                        </Trans>
                      </>
                    ) : (
                      '—'
                    )}
                  </div>
                </div>
                <Divider />
                <div className='home-detail'>
                  <div className='home-detail-item'>
                    <Trans i18nKey='shaxmatka.homeDetail.repaired'>
                      m<sup>2</sup> ta'mirli
                    </Trans>
                    :
                  </div>
                  <div className='home-detail-item-value'>
                    {homeData?.repaired ? (
                      <CurrencyFormat
                        value={homeData.repaired}
                        suffix={homeData?.isvalute == '1' ? ' $' : ' UZS'}
                        decimalScale={1}
                      />
                    ) : (
                      '—'
                    )}
                  </div>
                </div>
                <Divider />
                <div className='home-detail'>
                  <div className='home-detail-item'>
                    <Trans i18nKey='shaxmatka.homeDetail.norepaired'>
                      m<sup>2</sup> ta'mirsiz
                    </Trans>
                    :
                  </div>
                  <div className='home-detail-item-value'>
                    {homeData?.norepaired ? (
                      <CurrencyFormat
                        value={homeData.norepaired}
                        suffix={homeData?.isvalute == '1' ? ' $' : ' UZS'}
                        decimalScale={1}
                      />
                    ) : (
                      '—'
                    )}
                  </div>
                </div>
                <Divider />
                <div className='home-detail'>
                  <div className='home-detail-item'>
                    <Trans i18nKey='shaxmatka.homeDetail.dealPrice'>
                      m<sup>2</sup> kelishilgan
                    </Trans>
                    :
                  </div>
                  <div className='home-detail-item-value'>
                    {homeData?.deal_price ? (
                      <CurrencyFormat
                        value={homeData.deal_price}
                        suffix={homeData?.isvalute == '1' ? ' $' : ' UZS'}
                        decimalScale={1}
                      />
                    ) : (
                      '—'
                    )}
                  </div>
                </div>
                <Divider />
                <div className='home-detail'>
                  <div className='home-detail-item'>
                    {t('shaxmatka.homeDetail.startPrice')}:
                  </div>
                  <div className='home-detail-item-value whitespace-nowrap'>
                    {homeData?.start ? (
                      <CurrencyFormat
                        value={parseFloat(
                          (homeData?.start *
                            (homeData?.isrepaired == REPAIR_TYPE.REPAIRED.code
                              ? formatMultiplies(
                                  [homeData?.repaired, homeData?.square],
                                  2
                                )
                              : formatMultiplies(
                                  [homeData?.norepaired, homeData?.square],
                                  2
                                ))) /
                            100
                        )}
                        suffix={homeData?.isvalute == '1' ? ' $' : ' UZS'}
                        decimalScale={1}
                      />
                    ) : (
                      '—'
                    )}
                  </div>
                </div>
              </Fragment>
            )}

            <Divider />
            <div className='home-detail'>
              <div className='home-detail-item'>
                {t('shaxmatka.homeDetail.residentType')}:
              </div>
              <div className='home-detail-item-value'>
                {homeData?.islive
                  ? setHomeType(homeData.islive, residentTypeVariants)
                  : '—'}
              </div>
            </div>
            <Divider />
            <div className='home-detail'>
              <div className='home-detail-item'>
                {t('shaxmatka.homeDetail.repairType')}:
              </div>
              <div className='home-detail-item-value'>
                {homeData?.isrepaired?.toString()
                  ? setHomeType(homeData.isrepaired, repairTypeVariants)
                  : '—'}
              </div>
            </div>
            <Divider />
            <div className='home-detail'>
              <div className='home-detail-item'>
                {t('shaxmatka.homeDetail.position')}:
              </div>
              <div className='home-detail-item-value'>
                {homeData?.status
                  ? setHomeType(homeData.status, homeTypeVariants)
                  : '—'}
              </div>
            </div>
          </div>
        </Fragment>
      )}

      {openMoreDetailModal && (
        <ShaxmatkaMoreHomeDetailsModal
          open={openMoreDetailModal}
          setOpen={setOpenMoreDetailModal}
          homeData={homeData}
          homesData={homes}
          object={object}
          setRefetch={setRefetch}
        />
      )}

      {openHomeLayoutImageDialog && images?.imgs?.length > 0 ? (
        // yet-another-react-lightbox
        <LightBox
          isOpen={openHomeLayoutImageDialog}
          style={{ zIndex: 1040 }}
          captions={images?.captions}
          titles={images?.titles}
          images={images?.imgs}
          photoIndex={homeImageType == IMAGE_TYPE['2D'].code ? 1 : 0}
          setIsOpen={setOpenHomeLayoutImageDialog}
        />
      ) : null}
    </div>
  );
};

export default memo(ShaxmatkaHomeDetail);
