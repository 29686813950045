import React, { useState } from 'react';

import ArrowDownCrmIcon from './ArrowDownCrmIcon';
import ProfileTwoUserIcon from './ProfileTwoUserIcon';
// import CostDrawer from './costDrawer/CostDrawer';
import { useTranslation } from 'react-i18next';
import { mathFloor } from '../Pipes';
import { CircularProgress } from '@mui/material';
import SettingTwoIcon from '../chart/chartContainer/SettingTwoIcon';
import { dataOne } from '../mock';
const PriceOneClient = () => {
  const [openChangeLeadType, setOpenChangeLeadType] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [costTypeLead, setCostTypeLead] = useState(true);
  const isLoading = false;
  const data = dataOne.data;

  const { t } = useTranslation();

  // handleOpenChangeLeadType
  const handleOpenChangeLeadType = (isOpen) => {
    setOpenChangeLeadType(isOpen);
  };

  // handle change type cost
  const handleChangeTypeCost = (val) => {
    handleOpenChangeLeadType(false);
  };

  // handle drawer
  const handleDrawer = (visible) => {
    setOpenDrawer(visible);
  };

  return (
    <div
      className={
        'w-[36%] border border-[#eaeaea] py-[24px] px-[32px] rounded-lg'
      }
    >
      {isLoading ? (
        <div className='h-[260px] flex items-center justify-center'>
          <CircularProgress size={30} color='inherit' />
        </div>
      ) : (
        <>
          <div className={'flex items-center justify-between'}>
            <div className={'flex items-center'}>
              <h2>{t('Bir_dona')}</h2>
              {/* <Dropdown
                visible={openChangeLeadType}
                overlayClassName={styles.drop_down}
                overlay={
                  <>
                    <div onClick={() => handleChangeTypeCost(false)}>
                      {t('boss_crm.mijoz')}
                    </div>
                    <div onClick={() => handleChangeTypeCost(true)}>
                      {t('boss_crm.lead')}
                    </div>
                  </>
                }
                onVisibleChange={(e) => handleOpenChangeLeadType(e)}
                trigger={['click']}
                placement={'bottomCenter'}
              >
                <div
                  onClick={() => handleOpenChangeLeadType(true)}
                  className={styles.container_header_select}
                >
                  <span>
                    {costTypeLead ? t('boss_crm.lead') : t('boss_crm.mijoz')}
                  </span>
                  <ArrowDownCrmIcon />
                </div>
              </Dropdown> */}
            </div>
            <SettingTwoIcon
              onClick={() => handleDrawer(true)}
              className={'cursor-pointer'}
            />
          </div>
          <div className={'mt-[32px]'}>
            <h3 className='text-[#464255] text-[56px] font-bold leading-[67px] m-0'>
              {mathFloor(data?.averageSum || 0, 0).toLocaleString('ru')}
            </h3>
            <p className='text-[16px] font-medium leading-[19px] mb-8'>
              {/* {t('Bir_dona')} {costTypeLead ? t('lead') : t('mijoz')}{' '}
              {t('uchun_ortacha_tolov')} */}
              Bir dona mijoz uchun o'rtacha to'lov
            </p>
            <hr />
          </div>
          {data?.oneLeadCostCountList.map((item, index) => (
            <div key={index} className={'flex items-end justify-between mt-8'}>
              <div>
                <span className='text-[14px] leading-[17px] mb-1'>
                  {item.name}
                </span>
                <div className={'flex items-center'}>
                  <p
                    className={
                      'text-[#464255] text-[24px] font-semibold leading-[29px] mr-[20px]'
                    }
                  >
                    {mathFloor(item.averageAmount, 0).toLocaleString('ru')}{' '}
                    {/* {currency?.ccy} */}
                    UZS
                  </p>
                  <div className={'flex items-center'}>
                    <ProfileTwoUserIcon />
                    <span
                      className={
                        'text-[#464255] text-[24px] font-semibold leading-[29px] mr-[20px]'
                      }
                    >
                      {item.count}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={
                  'text-[#464255] text-[24px] font-semibold leading-[29px] mr-[20px]'
                }
              >
                {mathFloor(item.totalAmount, 0).toLocaleString('ru')}{' '}
                {/* {currency?.ccy} */}
                UZS
              </div>
            </div>
          ))}
        </>
      )}
      {/* <CostDrawer visible={openDrawer} onClose={handleDrawer} /> */}
    </div>
  );
};

export default PriceOneClient;
