import React, { memo, useState } from 'react';
import SecurityTable from '../../components/ui/tables/SecurityTable';
import { t } from 'i18next';

export default memo(function Security() {
  const [refetch, setRefetch] = useState(false);
  const [open, setOpen] = useState(false);
  return (
    <div>
      <SecurityTable
        dataPath='admin/profile/security'
        emitRefetch={{ refetch, setRefetch }}
        emitTableColumns={{ open, setOpen }}
        tableName='profile-security'
        headCells={[
          { code: 'id', label: '№' },
          { code: 'last_action', label: t('common.table.lastAction') },
          { code: 'name', label: t('common.table.deviceName') },
          { code: 'ip', label: t('common.table.ipAddress') },
          { code: 'location', label: t('common.table.location') },
          { code: 'active', label: t('common.table.active') },
        ]}
        columns={[
          { code: 'id' },
          { code: 'last_action', type: 'dateTime' },
          { code: 'name' },
          { code: 'ip' },
          { code: 'location' },
          { code: 'active' },
        ]}
      />
    </div>
  );
});
