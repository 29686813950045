import { Box, Button, CircularProgress, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { t } from 'i18next';

import useAxiosPrivate from 'hooks/useAxiosPrivate';
import DiscountSettingsAddModal from './DiscountSettingsAddModal';
import useFormSubmit from 'hooks/useFormSubmit';
import DeleteConfirmDialog from 'components/ui/dialogs/DeleteConfirmDialog';
import useNotification from 'hooks/useNotification';

export default function DiscountSettings() {
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [discounts, setDiscounts] = useState([]);
  const [discountId, setDiscountId] = useState(null);
  const { submit, isSubmitting } = useFormSubmit();
  const [loading, setLoading] = useState(false);
  const sendNotification = useNotification();

  const handleChangeTab = (event, newValue) => {
    setDiscountId(newValue);
  };

  const { isLoading, isFetching, refetch, data } = useQuery({
    queryKey: ['/admin/discount/index'],
    queryFn: async function () {
      const response = await axiosPrivate.get('/admin/discount/index');
      return response.data.data;
    },
    onSuccess: (data) => {
      if (data && data?.lead) {
      }
    },
    enabled: !hasError,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const {
    isLoading: isLoadingDiscount,
    isFetching: isFetchingDiscount,
    refetch: refetchDiscount,
    data: dataDiscount,
  } = useQuery({
    queryKey: ['/admin/discount/edit/{tabIndex}', discountId],
    queryFn: async function () {
      const response = await axiosPrivate.get(
        `/admin/discount/edit/${discountId}`
      );
      return response?.data?.data;
    },
    onSuccess: (data) => {
      if (data && data?.discounts) {
        setDiscounts(data?.discounts);
      }
    },
    enabled: !hasError && !!discountId,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const onSubmitDiscounts = () => {
    submit(
      { type: 'post', contentType: 'simple' },
      discounts,
      `/admin/discount/updatediscount/${dataDiscount?.id}`,
      {
        title: t('settings.discounts.addDiscountModal.alert.success', {
          value: dataDiscount?.name,
        }),
      },
      dataDiscount?.id,
      true,
      refetchDiscount
    );
  };

  const handleAction = (action) => {
    if (action == 'cancel') setDeleteOpen(false);
    if (action == 'delete') {
      setLoading(true);
      axiosPrivate
        .post(`admin/discount/delete/${dataDiscount?.id}`, { data: data })
        .then((response) => {
          if (response?.data?.status) {
            sendNotification({
              msg: t('common.alerts.success.delete'),
              variant: 'success',
            });
            setDeleteOpen(false);
            setDiscountId(null);
            refetch();
          }
        })
        .catch((err) => {
          sendNotification({
            msg: t('common.alerts.error.delete'),
            variant: 'error',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onChange = (e, i) => {
    if (e?.target?.value <= 100)
      setDiscounts((prev) =>
        prev.map((item, index) =>
          index === i ? { ...item, [e.target.name]: e.target.value } : item
        )
      );
  };
  const onDelete = (id) => {
    setDiscounts((prev) => prev.filter((item) => item?.id !== id));
  };

  return (
    <div className='base-border bg-white p-5'>
      <div className='grid grid-cols-[250px,1fr] gap-5'>
        <div>
          <Box
            sx={{
              background: '#fff',
              padding: '5px',
              borderRadius: '15px',
            }}
            className='!base-border'
          >
            {isLoading || isFetching ? (
              <div className='flex justify-center p-3'>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Tabs
                value={discountId}
                orientation='vertical'
                onChange={handleChangeTab}
                sx={{
                  '.MuiTabs-indicator': {
                    width: '100%',
                    borderRadius: '10px',
                  },
                  marginBottom: '15px',
                }}
              >
                {data?.length > 0 &&
                  data?.map((discount) => (
                    <Tab
                      label={discount?.name}
                      className='!whitespace-nowrap !items-start'
                      value={discount?.id}
                      sx={{
                        minHeight: '40px',
                        height: '40px',
                        '&.Mui-selected': { color: 'white' },
                      }}
                    />
                  ))}
              </Tabs>
            )}
            <div className='flex items-center justify-center'>
              <Button
                variant='contained'
                color='secondary'
                size='small'
                startIcon={<i className='bi bi-plus' />}
                onClick={() => setOpen(true)}
              >
                {t('settings.discounts.createDiscount')}
              </Button>
            </div>
          </Box>
        </div>
        {discountId ? (
          <div className='px-6'>
            <div className='header flex justify-between items-center mb-5'>
              <div className='flex items-center gap-2'>
                <div>{dataDiscount?.name}</div>
                <Button
                  variant='outlined'
                  size='small'
                  sx={{ minWidth: '40px', width: '40px' }}
                  disabled={isSubmitting}
                  onClick={() => {
                    setOpen(true);
                    setSelectedDiscount(dataDiscount);
                  }}
                >
                  <i className='bi bi-pen' />
                </Button>
                <Button
                  variant='outlined'
                  size='small'
                  color='error'
                  sx={{ minWidth: '40px', width: '40px' }}
                  onClick={() => setDeleteOpen(true)}
                >
                  <i className='bi bi-trash' />
                </Button>
              </div>
              <Button
                variant='contained'
                size='small'
                color='info'
                onClick={onSubmitDiscounts}
              >
                {t('common.button.save')}
              </Button>
            </div>
            <div>
              <div className='w-full mb-2 max-h-[calc(100vh-230px)] pr-2 my-scroll overflow-y-auto'>
                {isLoadingDiscount || isFetchingDiscount ? (
                  <div className='flex justify-center p-3'>
                    <CircularProgress size={30} />
                  </div>
                ) : (
                  <div className='w-full'>
                    <div>
                      {discounts?.length > 0 &&
                        discounts?.map((dis, i) => (
                          <div
                            key={i}
                            className='grid grid-cols-[1fr,1fr,1fr,50px] gap-8 mb-5 items-center'
                          >
                            <div className='h-full'>
                              <h2 className='mb-1'>
                                {t('contract.add.step.payment')} (%)
                              </h2>
                              <input
                                onChange={(e) => onChange(e, i)}
                                value={dis?.payment || ''}
                                name={'payment'}
                                type='number'
                                className='base-border rounded-md py-1 px-2 w-full'
                              />
                            </div>
                            <div className='h-full'>
                              <h2 className='mb-1'>
                                {t('payment.add.contract.discount')} (%)
                              </h2>
                              <input
                                onChange={(e) => onChange(e, i)}
                                value={dis?.discount || ''}
                                name={'discount'}
                                type='number'
                                className='base-border rounded-md py-1 px-2 w-full'
                              />
                            </div>
                            <div className='h-full'>
                              <h2 className='mb-1'>
                                {t('shaxmatka.homeDetail.month')}
                                {t('shaxmatka.moreHomeDetails.monthValue', {
                                  value: ' (',
                                })}
                                &nbsp;)
                              </h2>
                              <input
                                onChange={(e) => onChange(e, i)}
                                value={dis?.month || ''}
                                name={'month'}
                                type='number'
                                className='base-border rounded-md py-1 px-2 w-full'
                              />
                            </div>
                            <div className='h-full flex items-end justify-end'>
                              <Button
                                variant='outlined'
                                size='small'
                                color='error'
                                sx={{ minWidth: '40px', width: '40px' }}
                                onClick={() => onDelete(dis?.id)}
                              >
                                <i className='bi bi-trash' />
                              </Button>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className='flex items-center justify-center'>
                <Button
                  variant='outlined'
                  color='primary'
                  size='medium'
                  className='!border-dashed'
                  fullWidth
                  startIcon={<i className='bi bi-plus' />}
                  onClick={() =>
                    setDiscounts((dis) => [
                      ...dis,
                      { id: new Date()?.toString() },
                    ])
                  }
                >
                  {t('settings.discounts.addDiscount')}
                </Button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <DiscountSettingsAddModal
        open={open}
        setOpen={setOpen}
        selectedDiscount={selectedDiscount}
        setSelectedDiscount={setSelectedDiscount}
        refetch={() => {
          refetch();
          if (discountId == selectedDiscount?.id) {
            refetchDiscount();
          }
        }}
      />
      <DeleteConfirmDialog
        open={deleteOpen}
        setOpen={setDeleteOpen}
        handleAction={handleAction}
        loading={loading}
      />
    </div>
  );
}
