import React from 'react';
import moment from 'moment';

const DATE_WITH_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const ONE_DAY_UNIX_VALUE = 86340;

const DateItem = ({ data }) => {
  const calculateDate = () => {
    const allDay =
      moment(data.deadlineFinish, DATE_WITH_TIME_FORMAT).unix() -
        moment(data.deadlineStart, DATE_WITH_TIME_FORMAT).unix() ===
      ONE_DAY_UNIX_VALUE;
    if (allDay) return '';
    return data.date;
  };

  if (
    moment(data.deadlineFinish, DATE_WITH_TIME_FORMAT).unix() <
      moment(new Date()).unix() &&
    !data.closedDate
  )
    return (
      <p className={''}>
        {data?.leadName || data?.contractNumber} {data.crm_lead.name}{' '}
        {calculateDate()}
        {data.title && ': '}
        {data.title}
      </p>
    );

  return (
    <p className={''}>
      {data?.leadName || data?.contractNumber}{' '}
      <span>
        {calculateDate()} {data.crm_lead.name}
        {data.title && ': '}
      </span>
      {data.title}
    </p>
  );
};

export default DateItem;
