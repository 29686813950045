import React, { useEffect, useRef, useState } from 'react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

import { dateData } from './dateData';

import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const StyledStaticDatePicker = styled(StaticDatePicker)({
  minWidth: '320px',
  '.MuiPickersToolbar-root, .MuiDialogActions-root': {
    display: 'none',
  },
});

const MDatePicker = ({
  visibleTime,
  onChangeMPicker = () => {},
  data = [],
  setStarEndNew,
  startEndNew,
}) => {
  const calendarRef = useRef(null);
  const [dateValue, setDateValue] = useState(moment());
  const [startEnd, setStarEnd] = useState({
    start: '00:00',
    end: '23:59',
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (startEndNew && calendarRef?.current) {
      const newEvent = {
        id: 'new',
        title: `${t('Topshiriq')}: ${moment(startEndNew?.start).format(
          'HH:mm'
        )} ${moment(startEndNew?.end).format('HH:mm')} `,
        start: moment(
          startEndNew?.start.length > 5 ? '00:00' : startEndNew?.start,
          'hh:mm'
        )?.toISOString(),
        end: moment(
          startEndNew?.end.length > 5 ? '00:00' : startEndNew?.end,
          'hh:mm'
        )?.toISOString(),
        allDay: startEndNew?.start === '00:00' && startEndNew?.end === '23:59',
      };

      setStarEnd(startEndNew);

      const calendarApi = calendarRef?.current?.getApi();

      calendarApi?.removeAllEventSources();
      calendarApi?.addEventSource([...data, newEvent]);
    }
    if (data && data.length > 0) {
      setDateValue(moment(new Date(data[0]?.start)));
    }
    // eslint-disable-next-line
  }, [startEndNew, data]);

  function handleDateClick(arg) {
    if (arg.allDay) {
      onChangeMPicker(
        dateValue.format('YYYY-MM-DD'),
        moment().hour(0).minute(0).format('HH:mm'),
        moment().hour(23).minute(59).format('HH:mm')
      );
      setStarEnd({
        start: moment().hour(0).minute(0).format('HH:mm'),
        end: moment().hour(23).minute(59).format('HH:mm'),
      });
    }
  }
  function handleDateSelect(selectInfo) {
    if (calendarRef.current) {
      onChangeMPicker(
        dateValue.format('YYYY-MM-DD'),
        moment(selectInfo.start).format('HH:mm'),
        moment(selectInfo.end).format('HH:mm')
      );
      setStarEnd({
        start: moment(selectInfo?.start).format('HH:mm'),
        end: moment(selectInfo?.end).format('HH:mm'),
      });
    }
    selectInfo.view.calendar.unselect();

    const newEvent = {
      id: 'new',
      title: `${t('Topshiriq')}: ${moment(selectInfo?.startStr).format(
        'HH:mm'
      )} ${moment(selectInfo?.endStr).format('HH:mm')} `,
      start: selectInfo?.startStr,
      end: selectInfo?.endStr,
      allDay: selectInfo?.allDay,
    };

    const calendarApi = calendarRef?.current?.getApi();

    calendarApi?.removeAllEventSources();
    calendarApi?.addEventSource([...data, newEvent]);
  }

  function renderEventContent(eventInfo) {
    return eventInfo.event.allDay ? (
      <div>
        <h1 className='text-black'>{t('tasks.columns.sinceDay')}</h1>
      </div>
    ) : (
      <div>
        <p className='text-black'>{`${moment(eventInfo?.event.start).format(
          'HH:mm'
        )} ${moment(eventInfo?.event.end).format('HH:mm')} `}</p>
        <h1 className='text-black'>{t('Aloqaga')}</h1>
      </div>
    );
  }

  function handleEventDrop(checkInfo) {
    if (calendarRef.current) {
      onChangeMPicker(
        dateValue.format('YYYY-MM-DD'),
        moment(checkInfo.event.start).format('HH:mm'),
        moment(checkInfo.event.end).format('HH:mm')
      );
      setStarEnd({
        start: moment(checkInfo.event.start).format('HH:mm'),
        end: moment(checkInfo.event.end).format('HH:mm'),
      });
    }
    checkInfo.event.mutate({
      standardProps: {
        title: `Topshiriq: ${moment(checkInfo?.event.start).format(
          'HH:mm'
        )} ${moment(checkInfo?.event.end).format('HH:mm')} `,
      },
    });
  }

  function handleEventResize(checkInfo) {
    if (calendarRef.current) {
      onChangeMPicker(
        dateValue.format('YYYY-MM-DD'),
        moment(checkInfo.event.start).format('HH:mm'),
        moment(checkInfo.event.end).format('HH:mm')
      );
      setStarEnd({
        start: moment(checkInfo.event.start).format('HH:mm'),
        end: moment(checkInfo.event.end).format('HH:mm'),
      });
    }
    checkInfo.event.mutate({
      standardProps: {
        title: `${t('Topshiriq')}: ${moment(checkInfo?.event.start).format(
          'HH:mm'
        )} ${moment(checkInfo?.event.end).format('HH:mm')} `,
      },
    });
  }

  const dateChange = (date) => {
    onChangeMPicker(date.format('YYYY-MM-DD'), startEnd.start, startEnd.end);
    setDateValue(date);
  };

  const handleSelectDate = (date) => {
    onChangeMPicker(
      date.format('YYYY-MM-DD'),
      moment()
        .hour(Number(date.format('HH')))
        .minute(Number(date.format('mm')))
        .format('HH:mm'),
      moment()
        .hour(Number(date.format('HH')))
        .minute(Number(date.format('mm')) + 15)
        .format('HH:mm')
    );
    setDateValue(date);
    const calendarApi = calendarRef?.current?.getApi();
    const newEvent = {
      id: 'new',
      title: `${t('Topshiriq')}: ${moment()
        .hour(Number(date.format('HH')))
        .minute(Number(date.format('mm')))
        .format('HH:mm')} ${moment()
        .hour(Number(date.format('HH')))
        .minute(Number(date.format('mm')) + 30)
        .format('HH:mm')} `,
      start: moment()
        .hour(Number(date.format('HH')))
        .minute(Number(date.format('mm')))
        .toISOString(),
      end: moment()
        .hour(Number(date.format('HH')))
        .minute(Number(date.format('mm')) + 30)
        .toISOString(),
    };

    calendarApi?.removeAllEventSources();
    calendarApi?.addEventSource([...data, newEvent]);
  };

  useEffect(() => {
    const calendarApi = calendarRef?.current?.getApi();

    if (dateValue) {
      calendarApi?.gotoDate(dateValue.toISOString());
    }
  }, [calendarRef, dateValue]);

  return (
    <div
      className='MDatePicker flex bg-white !text-black'
      onClick={(e) => e.stopPropagation()}
    >
      <div className='MDatePicker_customDate border-r py-2'>
        {Object.values(dateData).map((item, index) => (
          <div
            key={index}
            className='cursor-pointer text-black text-sm whitespace-nowrap p-2 hover:bg-gray-200'
            onClick={() => handleSelectDate(item.date)}
          >
            {t(`${item.text?.replaceAll('_', ' ')}`)}
          </div>
        ))}
      </div>
      <div style={{ width: 320 }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <StyledStaticDatePicker onChange={dateChange} value={dateValue} />
        </LocalizationProvider>
      </div>
      <div style={{ width: 302 }}>
        <FullCalendar
          height='100%'
          eventMinHeight={20}
          ref={calendarRef}
          allDayText={t('tasks.columns.sinceDay')}
          locale='ru-Ru'
          eventClassNames='m_fc_TimePicker !bg-blue-100 !border-l-0 !border-r-0 !border-dashed hover:!border-solid'
          viewClassNames='m_fc_Container !my-scroll'
          slotLaneClassNames='m_fc_Lane !my-scroll'
          slotLabelClassNames='m_fc_Label !my-scroll'
          dayCellClassNames='m_fc_AllDay !bg-gray-50 !my-scroll'
          dragScroll={true}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={false}
          initialView='timeGridDay'
          editable={visibleTime}
          selectable={visibleTime}
          progressiveEventRendering
          dayHeaders={false}
          dayMaxEvents={visibleTime}
          allDaySlot={visibleTime}
          slotLabelFormat={[
            {
              hour: '2-digit',
              minute: '2-digit',
            },
          ]}
          initialEvents={data}
          select={handleDateSelect}
          eventContent={renderEventContent}
          eventDrop={handleEventDrop}
          eventResize={handleEventResize}
          dateClick={handleDateClick}
        />
      </div>
    </div>
  );
};

export default MDatePicker;
