import { CircularProgress } from '@mui/material';
import { Fragment, memo, useRef } from 'react';
import { useDraggable } from 'react-use-draggable-scroll';
import ShaxmatkaBlockItem from './block/ShaxmatkaBlockItem';

export default memo(function Shaxmatka2({
  blocksQuery,
  objectQuery,
  blocks,
  toggleSelectionItem,
}) {
  const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
  const { events } = useDraggable(ref);
  return (
    <div className='sheet-wrapper'>
      <div className='sheet-base-area'>
        <div className='sheet-grid gap-2' {...events} ref={ref}>
          {(blocksQuery.isLoading || blocksQuery.isFetching) &&
          (objectQuery.isLoading || objectQuery.isFetching) ? (
            <div className='circular-progress-box min-h-[500px] h-full w-full'>
              <CircularProgress size={50} />
            </div>
          ) : (
            blocksQuery.data &&
            blocksQuery.data.length > 0 &&
            blocks && (
              <Fragment>
                {blocks.map(
                  (block, index) =>
                    block?.padezd?.length > 0 && (
                      <div
                        className='sheet-column flex gap-0'
                        key={`block-${block?.id}-columns`}
                        id={`block-${block?.id}-columns`}
                      >
                        <ShaxmatkaBlockItem
                          blockItem={block}
                          blockIndex={index}
                          toggleSelectionItem={toggleSelectionItem}
                        />
                      </div>
                    )
                )}
              </Fragment>
            )
          )}
        </div>
      </div>
    </div>
  );
});
