import React from 'react';
import moment from 'moment';
import { Button } from '@mui/material';

export const DATE_WITH_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const DateSlider = ({ dateRange, setDateRange }) => {
  // handle add date
  const handleAddDate = () => {
    const newDate = moment(dateRange.from, DATE_WITH_TIME_FORMAT).add(1, 'day');
    setDateRange({
      from: newDate.startOf('day').format(DATE_WITH_TIME_FORMAT),
      till: newDate.endOf('day').format(DATE_WITH_TIME_FORMAT),
    });
  };

  // handle delete date
  const handleDeleteDate = () => {
    const newDate = moment(dateRange.from, DATE_WITH_TIME_FORMAT).subtract(
      1,
      'day'
    );
    setDateRange({
      from: newDate.startOf('day').format(DATE_WITH_TIME_FORMAT),
      till: newDate.endOf('day').format(DATE_WITH_TIME_FORMAT),
    });
  };

  return (
    <div className={'flex items-center justify-center px-2 gap-12'}>
      <Button onClick={handleDeleteDate} sx={{ minWidth: 80 }}>
        <i className='bi bi-chevron-left' />
      </Button>
      <span>
        {moment(dateRange.from, DATE_WITH_TIME_FORMAT).format('dddd D')}
      </span>
      <Button onClick={handleAddDate} sx={{ minWidth: 80 }}>
        <i className='bi bi-chevron-right' />
      </Button>
    </div>
  );
};

export default DateSlider;
