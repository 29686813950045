import { useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { t } from 'i18next';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import useAxiosPrivate from 'hooks/useAxiosPrivate';
import TaskFilterDrawer from 'components/dashboard/TaskFilterDrawer';
import ColumnTaskCard from './ColumnTaskCard';

export default function Columns() {
  const { search } = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['/crm/task{search}', search],
    queryFn: async function () {
      const response = await axiosPrivate.get(`/crm/task${search || ''}`);
      return response?.data?.data;
    },
    enabled: !hasError,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  return (
    <div className='component-list-wrapper bg-[#f0f2f5] rounded-lg'>
      <div className='tasks-wrapper'>
        <div className='tasks-columns-wrapper'>
          {isLoading || isFetching ? (
            <div className='circular-progress-box min-h-[500px] h-full w-full'>
              <CircularProgress size={40} />
            </div>
          ) : (
            data &&
            Object.keys(data).length > 0 && (
              <Grid container spacing={3} className='tasks-columns-grid'>
                <div className='flex tasks-columns-grid'>
                  <Grid
                    item={true}
                    sm={1}
                    xs={1}
                    className='tasks-grid-column'
                    id='tasks-wrapper'
                  >
                    <div className='tasks-grid-column-title-wrapper'>
                      <div className='tasks-grid-column-title'>
                        {t('tasks.columns.left')}
                        <center className='text-[10px] -mb-2 text-gray-400'>
                          {(data?.left && data.left.length) || 0}
                        </center>
                      </div>
                    </div>

                    <div className='tasks-grid-column-body'>
                      <div className='h-full'>
                        {data.left &&
                          data.left.length > 0 &&
                          data.left.map((item, itemIndex) => (
                            <ColumnTaskCard
                              item={item}
                              key={`data-past-${itemIndex}`}
                            />
                          ))}
                      </div>
                    </div>
                  </Grid>
                  <Grid item={true} sm={1} xs={1} className='tasks-grid-column'>
                    <div className='tasks-grid-column-title-wrapper'>
                      <div className='tasks-grid-column-title'>
                        {t('tasks.columns.today')}
                        <center className='text-[10px] -mb-2 text-gray-400'>
                          {(data?.today && data.today.length) || 0}
                        </center>
                      </div>
                    </div>

                    <div className='tasks-grid-column-body'>
                      <div className='h-full'>
                        {data.today &&
                          data.today.length > 0 &&
                          data.today.map((item, itemIndex) => (
                            <ColumnTaskCard
                              item={item}
                              key={`data-today-${itemIndex}`}
                            />
                          ))}
                      </div>
                    </div>
                  </Grid>
                  <Grid item={true} sm={1} xs={1} className='tasks-grid-column'>
                    <div className='tasks-grid-column-title-wrapper'>
                      <div className='tasks-grid-column-title'>
                        {t('tasks.columns.tomorrow')}
                        <center className='text-[10px] -mb-2 text-gray-400'>
                          {(data?.tomorrow && data.tomorrow.length) || 0}
                        </center>
                      </div>
                    </div>

                    <div className='tasks-grid-column-body'>
                      <div className='h-full'>
                        {data.tomorrow &&
                          data.tomorrow.length > 0 &&
                          data.tomorrow.map((item, itemIndex) => (
                            <ColumnTaskCard
                              item={item}
                              key={`data-tomorrow-${itemIndex}`}
                            />
                          ))}
                      </div>
                    </div>
                  </Grid>
                  <Grid item={true} sm={1} xs={1} className='tasks-grid-column'>
                    <div className='tasks-grid-column-title-wrapper'>
                      <div className='tasks-grid-column-title'>
                        {t('tasks.columns.future')}
                        <center className='text-[10px] -mb-2 text-gray-400'>
                          {(data?.future && data.future.length) || 0}
                        </center>
                      </div>
                    </div>

                    <div className='tasks-grid-column-body'>
                      <div className='h-full'>
                        {data.future &&
                          data.future.length > 0 &&
                          data.future.map((item, itemIndex) => (
                            <ColumnTaskCard
                              item={item}
                              key={`data-future-${itemIndex}`}
                            />
                          ))}
                      </div>
                    </div>
                  </Grid>
                </div>
              </Grid>
            )
          )}
        </div>
      </div>
      {!hasError && (
        <TaskFilterDrawer
          open={expanded}
          setOpen={setExpanded}
          // filterData={filterData}
          // isLoading={isFilterLoading}
        />
      )}
    </div>
  );
}
