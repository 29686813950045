import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Checkbox, LinearProgress, Pagination, TableHead } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import useAxiosPrivate from 'hooks/useAxiosPrivate';
import setTableCellType from 'utils/setTableCellType';
import TableColumnToggleDrawer from 'components/TableColumnToggleDrawer';
import useColumnToggle from 'hooks/useColumnToggle';
import addActiveInTableCell from 'utils/addActiveInTableCell';

export default function CrmStaffConnectionTable({
  dataPath = '',
  tableName = '',
  emitTableColumns = {},
  emitRefetch = {},
  selectedRows,
  setSelectedRows,
}) {
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const headCells = [
    { code: 'name', label: t('common.table.name') },
    { code: 'homes', label: t('common.table.homeNumber') },
  ];
  const columns = [{ code: 'name' }, { code: 'date', type: 'date' }];
  const { tableData, tableHeadCells, tableColumnCells, setTableHeadCells } =
    useColumnToggle(
      tableName,
      addActiveInTableCell(headCells),
      addActiveInTableCell(columns)
    );
  const [queryPath, setQueryPath] = React.useState('');

  const [hasError, setHasError] = React.useState(false);
  const {
    error,
    data: rows,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useQuery({
    queryKey: dataPath + 'Key',
    queryFn: async function () {
      const response = await axiosPrivate.get(queryPath);
      return response?.data?.data;
    },
    onSettled: () => {
      emitRefetch.setRefetch(false);
    },
    onSuccess: () => {
      setSelectedRows([]);
    },
    enabled: false,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const ids = React.useMemo(() => {
    if (!rows?.data?.length) return [];
    return rows?.data?.map(({ id }) => id);
  }, [rows?.data]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = React.useState(
    (searchParams.get('page') && parseInt(searchParams.get('page'))) || 1
  );

  React.useEffect(() => {
    if (queryPath && queryPath.length > 0) {
      refetch();
    }
  }, [queryPath]);

  React.useEffect(() => {
    createQueryPath();
  }, [searchParams]);

  React.useEffect(() => {
    if (emitRefetch && emitRefetch.refetch) {
      refetch();
    }
  }, [emitRefetch.refetch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    searchParams.set('page', newPage);
    setSearchParams(searchParams);
  };

  const createQueryPath = () => {
    let newQueryPath = `/${dataPath}`;
    let localSearchParams = Object.fromEntries([...searchParams]);
    const search = Object.keys(localSearchParams)?.filter(
      (key) => key != 'staff' && key != 'block'
    );
    search.forEach((item, index) => {
      if (item != 'staff' && item != 'block')
        if (index == 0) {
          newQueryPath += `?${item}=${localSearchParams[item]}`;
        } else {
          newQueryPath += `&${item}=${localSearchParams[item]}`;
        }
    });
    setQueryPath(newQueryPath);
    if (!isNaN(localSearchParams.page)) {
      setPage(parseInt(localSearchParams.page));
    }
  };

  const onChange = (e) => {
    const { checked, name } = e?.target;
    const id = Number(name);
    const setData = new Set(selectedRows);
    if (checked) {
      setData.add(id);
    } else {
      setData.delete(id);
    }
    setSelectedRows(Array.from(setData));
  };

  const onChangeAll = (e) => {
    const { checked } = e?.target;
    if (checked) {
      setSelectedRows(ids);
    } else {
      setSelectedRows([]);
    }
  };

  const isAllChecked = React.useMemo(() => {
    return ids?.every((el) => selectedRows?.includes(el));
  }, [selectedRows, ids]);

  return (
    <Box className='base-table w-full h-full flex flex-col'>
      <TableContainer className='flex-auto h-full'>
        <Table
          stickyHeader
          sx={{ minWidth: 750, height: 'max-content' }}
          aria-labelledby='tableTitle'
        >
          {tableHeadCells && tableColumnCells && (
            <React.Fragment>
              <TableHead>
                <TableRow>
                  <TableCell align={'left'}>
                    <Checkbox
                      checked={isAllChecked}
                      value={'all'}
                      onChange={onChangeAll}
                    />
                  </TableCell>
                  <TableCell align={'left'}>{t('common.table.name')}</TableCell>
                  <TableCell align={'left'}>{t('common.table.date')}</TableCell>
                </TableRow>
              </TableHead>
              {isLoading || isFetching ? (
                <TableBody className='overflow-hidden'>
                  <TableRow>
                    <TableCell colSpan={tableHeadCells.length + 1}>
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : isError ? (
                <TableBody className='overflow-hidden'>
                  <TableRow>
                    <TableCell colSpan={tableHeadCells.length + 1}>
                      <div className='flex flex-col items-center'>
                        {error?.response?.data?.message && (
                          <span className='text-red-600 font-medium'>
                            {error?.response?.data?.message}
                          </span>
                        )}
                        {error?.response?.data?.details &&
                          error?.response?.data?.details[0]?.message && (
                            <div>
                              <span className='text-red-600 font-medium'>
                                {t('common.errors.message')}
                              </span>
                              <span>
                                {error?.response?.data?.details[0]?.message}
                              </span>
                            </div>
                          )}
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : rows && rows?.data && rows?.data?.length > 0 ? (
                <React.Fragment>
                  <TableBody className='overflow-hidden'>
                    {rows.data.map((row, rowIndex) => {
                      return (
                        <TableRow hover tabIndex={-1} key={'row-' + rowIndex}>
                          <TableCell align={'left'}>
                            <Checkbox
                              name={`${row?.id}`}
                              value={`${row?.id}`}
                              onChange={onChange}
                              checked={selectedRows?.includes(row?.id)}
                            />
                          </TableCell>
                          {tableColumnCells.map((column, columnIndex) => (
                            <TableCell
                              align={
                                tableHeadCells[columnIndex]?.numeric
                                  ? 'right'
                                  : 'left'
                              }
                              key={'column-' + columnIndex}
                            >
                              {column.type
                                ? setTableCellType(
                                    column,
                                    row[column.code],
                                    row
                                  )
                                : row[column.code]}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </React.Fragment>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={tableHeadCells.length + 1}>
                      <span className='no-data-found-wrapper'>
                        <i className='bi bi-exclamation-octagon text-xl leading-4 mr-1' />{' '}
                        {t('common.global.noDataFound')}
                      </span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </React.Fragment>
          )}
        </Table>
      </TableContainer>
      {rows && rows?.data && rows?.data?.length > 0 && (
        <div className='relative p-3 mb-2 mt-4 flex items-center justify-center rounded-lg !shadow-none border border-[#E5E9EB] bg-[#FFFFFF]'>
          <Pagination
            count={Math.ceil(rows.total / rows.per_page) || 1}
            page={page}
            onChange={handleChangePage}
            // variant='outlined'
            shape='rounded'
            color='primary'
            showFirstButton
            showLastButton
          />
          {rows?.total ? (
            <div className='absolute top-1/2 -translate-y-1/2 right-10'>
              {t('common.table.total')}: {rows?.total || '0'}
            </div>
          ) : (
            ''
          )}
        </div>
      )}

      {emitTableColumns && Object.keys(emitTableColumns).length == 2 && (
        <TableColumnToggleDrawer
          open={emitTableColumns.open}
          setOpen={emitTableColumns.setOpen}
          tableData={tableData}
          setTableHeadCells={setTableHeadCells}
        />
      )}
    </Box>
  );
}
