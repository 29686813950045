import { Button } from '@mui/material';
import BaseTable from 'components/ui/tables/BaseTable';
import AccounterFilter from 'components/ui/filters/items/AccounterFilter';
import useTopPanel from 'hooks/useTopPanel';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { paymentTypeVariants } from 'shared/tableColVariantsList';
import DailyPayments from './DailyPayments';
import { Link, useNavigate } from 'react-router-dom';
import PaymentFilterDrawer from '../../../components/dashboard/PaymentFilterDrawer';

const Payment = () => {
  const [openFilter, setOpenFilter] = useState(false);
  const { setComponent } = useTopPanel();
  const { t, i18n } = useTranslation();
  const [refetch, setRefetch] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const onClickRow = (row) => {
    navigate(`/admin/contract/view/${row?.contract_id}`);
  };

  useEffect(() => {
    setComponent(<div className='component-title'>{t('payment.title')}</div>);
  }, [i18n.language]);

  return (
    <div className='component-list-wrapper'>
      <div className='component-list-header mb-2'>
        <div className='header-actions-container py-3 flex flex-row items-center'>
          <div className='header-actions filter-box flex items-center my-shadow-2 rounded-lg px-4 w-full h-14'>
            <Button
              variant='filterOutlined'
              color='primary'
              startIcon={<i className='bi bi-filter' />}
              className='!mr-2'
              onClick={() => setOpenFilter((prev) => !prev)}
            >
              {t('common.button.filter')}
            </Button>
            <div className='mr-3 lg:w-[200px] w-[150px]'>
              <AccounterFilter />
            </div>
          </div>
          <div className='header-actions action-buttons-box py-3 px-4 my-shadow-2 rounded-lg flex items-center justify-center ml-4'>
            <Link
              to={`${process.env.REACT_APP_BACKEND_URL}/excel/payments`}
              className='no-underline !ml-2'
              // target='_blank'
            >
              <Button variant='action' color='success'>
                <i className='bi bi-download' />
              </Button>
            </Link>
            <Button
              variant='action'
              color='success'
              className='!mx-2'
              onClick={() => navigate('/admin/payment/add')}
            >
              <i className='bi bi-plus-circle' />
            </Button>
            <Button
              variant='action'
              color='default'
              onClick={() => setOpen(true)}
            >
              <i className='bi bi-gear' />
            </Button>
          </div>
        </div>
      </div>

      <DailyPayments isRefetch={refetch} />

      <div className='component-table-wrapper'>
        <BaseTable
          emitRefetch={{ refetch, setRefetch }}
          dataPath='dictionary/payments'
          emitTableColumns={{ open, setOpen }}
          tableName='payments'
          headCells={[
            { code: 'paymentId', label: t('payment.paymentId') },
            { code: 'number', label: t('common.table.paymentId') },
            { code: 'fullname', label: t('common.table.custom') },
            { code: 'contract', label: t('common.table.contractNumber') },
            { code: 'date', label: t('common.table.date') },
            { code: 'sum', label: t('common.table.paymentAmount') },
            { code: 'type_id', label: t('common.table.typeId') },
            { code: 'staff', label: t('common.table.staff') },
          ]}
          columns={[
            { code: 'id' },
            { code: 'number' },
            { code: 'fullname' },
            { code: 'contract', type: 'nested', childStr: 'name' },
            { code: 'date', type: 'date' },
            { code: 'sum', type: 'priceCurrency' },
            {
              code: 'type_id',
              type: 'customStatus',
              variants: paymentTypeVariants,
            },
            { code: 'staff', type: 'nested', childStr: 'name' },
          ]}
          actionGetCheque={true}
          onClickRow={onClickRow}
        />
      </div>
      <PaymentFilterDrawer open={openFilter} setOpen={setOpenFilter} />
    </div>
  );
};

export default memo(Payment);
