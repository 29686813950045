import React, { memo } from 'react';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

const DateRangeFilter = ({
  labels = { start: 'from', end: 'till' },
  format = 'DD.MM.YYYY',
  queries = ['from', 'till'],
  size = '',
  onChange: onChangeDate = () => {},
}) => {
  // const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const values = [
    searchParams.get(queries?.[0]),
    searchParams.get(queries?.[1]),
  ];
  const onChange = (values) => {
    if (values?.[0]?._isValid && values?.[1]?._isValid) {
      const dateFrom = moment(values?.[0]).format('YYYY-MM-DD');
      const dateTo = moment(values?.[1]).format('YYYY-MM-DD');
      searchParams.set(queries?.[0], dateFrom);
      searchParams.set(queries?.[1], dateTo);
      onChangeDate(values, searchParams);
    }
    if (values?.[0] == null || values?.[0] == undefined) {
      searchParams.delete(queries?.[0]);
    }
    if (values?.[1] == null || values?.[1] == undefined) {
      searchParams.delete(queries?.[1]);
    }
    setSearchParams(searchParams);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} localeText={labels}>
      <DateRangePicker
        value={values}
        onChange={onChange}
        inputFormat={format}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <TextField {...startProps} size={size} />
            <Box sx={{ mx: 0.2 }}></Box>
            <TextField {...endProps} size={size} />
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  );
};

export default memo(DateRangeFilter);
