import { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Checkbox, LinearProgress, Pagination, TableHead } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import setTableCellType from 'utils/setTableCellType';
import TableColumnToggleDrawer from 'components/TableColumnToggleDrawer';
import useColumnToggle from 'hooks/useColumnToggle';
import addActiveInTableCell from 'utils/addActiveInTableCell';
import TableSelectActions from './TableSelectActions';
import TaskCompleteModal from './TaskCompleteModal';
import TaskDeleteModal from './TaskDeleteModal';
import TaskStaffModal from './TaskStaffModal';
import TaskTimeModal from './TaskTimeModal';
import TaskTypeModal from './TaskTypeModal';

export default function TableTab() {
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [actionType, setActionType] = useState('');

  const headCells = [
    { code: 'name', label: t('common.table.name') },
    { code: 'date', label: t('common.table.name') },
    { code: 'homes', label: t('common.table.homeNumber') },
    { code: 'client', label: t('common.table.name') },
    { code: 'client', label: t('common.table.name') },
    { code: 'client', label: t('common.table.name') },
  ];
  const columns = [
    { code: 'name' },
    { code: 'date', type: 'date' },
    { code: 'client' },
    { code: 'client' },
    { code: 'client' },
    { code: 'client' },
  ];
  const { tableData, tableHeadCells, tableColumnCells, setTableHeadCells } =
    useColumnToggle(
      'task-table',
      addActiveInTableCell(headCells),
      addActiveInTableCell(columns)
    );
  const [queryPath, setQueryPath] = useState('');
  const {
    error,
    data: rows,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['crm/tasks/table', queryPath],
    queryFn: async function () {
      if (queryPath) {
        const response = await axiosPrivate.get(queryPath);
        return response?.data?.data?.tasks;
      } else return {};
    },
    // onSettled: () => {
    //   setRefetch(false);
    // },
    onSuccess: () => {
      setSelectedRows([]);
    },
    enabled: !hasError,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const ids = useMemo(() => {
    if (!rows?.data?.length) return [];
    return rows?.data?.map(({ id }) => id);
  }, [rows?.data]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(
    (searchParams.get('page') && parseInt(searchParams.get('page'))) || 1
  );

  useEffect(() => {
    if (queryPath && queryPath.length > 0) {
      refetch();
    }
  }, [queryPath]);

  useEffect(() => {
    createQueryPath();
  }, [searchParams]);

  //   useEffect(() => {
  //     if (emitRefetch && emitRefetch.refetch) {
  //       refetch();
  //     }
  //   }, [emitRefetch.refetch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    searchParams.set('page', newPage);
    setSearchParams(searchParams);
  };

  const createQueryPath = () => {
    let newQueryPath = `/crm/task/table`;
    let localSearchParams = Object.fromEntries([...searchParams]);
    const search = Object.keys(localSearchParams)?.filter(
      (key) => key !== 'staff' && key !== 'block'
    );
    search.forEach((item, index) => {
      if (item !== 'staff' && item !== 'block')
        if (index === 0) {
          newQueryPath += `?${item}=${localSearchParams[item]}`;
        } else {
          newQueryPath += `&${item}=${localSearchParams[item]}`;
        }
    });
    setQueryPath(newQueryPath);
    if (!isNaN(localSearchParams.page)) {
      setPage(parseInt(localSearchParams.page));
    }
  };

  const onChange = (e) => {
    const { checked, name } = e?.target;
    const id = Number(name);
    const setData = new Set(selectedRows);
    if (checked) {
      setData.add(id);
    } else {
      setData.delete(id);
    }
    setSelectedRows(Array.from(setData));
  };

  const onChangeAll = (e) => {
    const { checked } = e?.target;
    if (checked) {
      setSelectedRows(ids);
    } else {
      setSelectedRows([]);
    }
  };

  const isAllChecked = useMemo(() => {
    if (!ids?.length) return false;
    return ids?.every((el) => selectedRows?.includes(el));
  }, [selectedRows, ids]);
  return (
    <Box className='base-table w-full h-[calc(100%-75px)] flex flex-col'>
      <TableSelectActions
        selectedRows={selectedRows}
        setActionType={setActionType}
      />
      <TableContainer
        className={`flex-auto h-[calc(100%-${
          selectedRows?.length ? 120 : 70
        }px)]`}
      >
        <Table
          stickyHeader
          sx={{ minWidth: 750, height: 'max-content' }}
          aria-labelledby='tableTitle'
          size='small'
        >
          {tableHeadCells && tableColumnCells && (
            <Fragment>
              <TableHead>
                <TableRow>
                  <TableCell align={'left'}>
                    <Checkbox
                      checked={isAllChecked}
                      value={'all'}
                      onChange={onChangeAll}
                    />
                  </TableCell>
                  <TableCell align={'left'}>
                    {t('common.table.custom')}
                  </TableCell>
                  <TableCell align={'left'}>{t('common.table.date')}</TableCell>
                  <TableCell align={'left'}>
                    {t('common.table.taskType')}
                  </TableCell>
                  <TableCell align={'left'}>
                    {t('common.fields.comment')}
                  </TableCell>
                  <TableCell align={'left'}>
                    {t('common.table.taskStatus')}
                  </TableCell>
                  <TableCell align={'left'}>
                    {t('common.table.staff')}
                  </TableCell>
                </TableRow>
              </TableHead>
              {isLoading || isFetching ? (
                <TableBody className='overflow-hidden'>
                  <TableRow>
                    <TableCell colSpan={tableHeadCells.length + 1}>
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : isError ? (
                <TableBody className='overflow-hidden'>
                  <TableRow>
                    <TableCell colSpan={tableHeadCells.length + 1}>
                      <div className='flex flex-col items-center'>
                        {error?.response?.data?.message && (
                          <span className='text-red-600 font-medium'>
                            {error?.response?.data?.message}
                          </span>
                        )}
                        {error?.response?.data?.details &&
                          error?.response?.data?.details[0]?.message && (
                            <div>
                              <span className='text-red-600 font-medium'>
                                {t('common.errors.message')}
                              </span>
                              <span>
                                {error?.response?.data?.details[0]?.message}
                              </span>
                            </div>
                          )}
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : rows && rows?.data && rows?.data?.length > 0 ? (
                <Fragment>
                  <TableBody className='overflow-hidden'>
                    {rows.data.map((row, rowIndex) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={'row-' + rowIndex}
                          htmlFor={`${row?.id}`}
                          component={'label'}
                          className='!cursor-pointer'
                        >
                          <TableCell align={'left'}>
                            <Checkbox
                              id={`${row?.id}`}
                              name={`${row?.id}`}
                              value={`${row?.id}`}
                              onChange={onChange}
                              checked={selectedRows?.includes(row?.id)}
                            />
                          </TableCell>
                          {tableColumnCells.map((column, columnIndex) => (
                            <TableCell
                              align={
                                tableHeadCells[columnIndex]?.numeric
                                  ? 'right'
                                  : 'left'
                              }
                              key={'column-' + columnIndex}
                            >
                              {column.type
                                ? setTableCellType(
                                    column,
                                    row[column.code],
                                    row
                                  )
                                : row[column.code]}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Fragment>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={tableHeadCells.length + 1}>
                      <span className='no-data-found-wrapper'>
                        <i className='bi bi-exclamation-octagon text-xl leading-4 mr-1' />{' '}
                        {t('common.global.noDataFound')}
                      </span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Fragment>
          )}
        </Table>
      </TableContainer>
      {rows && rows?.data && rows?.data?.length > 0 && (
        <div className='p-3 mb-2 mt-4 flex items-center justify-center base-border bg-white'>
          <Pagination
            count={Math.ceil(rows?.total / rows?.per_page) || 1}
            page={page}
            onChange={handleChangePage}
            variant='outlined'
            color='primary'
            showFirstButton
            showLastButton
          />
        </div>
      )}
      <TaskTypeModal
        open={actionType === 'type'}
        setOpen={setActionType}
        data={selectedRows}
        setData={setActionType}
        refetchFn={refetch}
      />
      <TaskTimeModal
        open={actionType === 'time'}
        setOpen={setActionType}
        data={selectedRows}
        setData={setActionType}
        refetchFn={refetch}
      />
      <TaskStaffModal
        open={actionType === 'staff'}
        setOpen={setActionType}
        data={selectedRows}
        setData={setActionType}
        refetchFn={refetch}
      />
      <TaskDeleteModal
        open={actionType === 'delete'}
        setOpen={setActionType}
        data={selectedRows}
        refetchFn={refetch}
      />
      <TaskCompleteModal
        open={actionType === 'complete'}
        setOpen={setActionType}
        data={selectedRows}
        refetchFn={refetch}
      />
      <TableColumnToggleDrawer
        open={open}
        setOpen={setOpen}
        tableData={tableData}
        setTableHeadCells={setTableHeadCells}
      />
    </Box>
  );
}
