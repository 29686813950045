import React, { useEffect, useState } from 'react';
import { ResponsiveRadialBar } from '@nivo/radial-bar';

import ShapeIconCrm from './ShapeIconCrm';
import { mathFloor } from '../../Pipes';
import { useTranslation } from 'react-i18next';

const ChartContainer = ({ data = {} }) => {
  const [chartData, setChartData] = useState([]);

  const { t } = useTranslation();

  const priceSeparator = (price) => {
    if (price < 1000) {
      return price;
    }
    if (price < 1000000) {
      return (price / 1000).toFixed(1) + t('ming');
    }
    if (price < 1000000000) {
      return (price / 1000000).toFixed(1) + t('mln');
    }
    if (price < 1000000000000) {
      return (price / 1000000000).toFixed(1) + t('mlrd');
    }
    return (price / 1000000000000).toFixed(1) + t('trln');
  };

  useEffect(() => {
    if (data) {
      let newData = [];
      let maxPlan = 1;

      data?.factPipeStatusList?.forEach((item) => {
        if (item?.planLeadCount && item.planLeadCount > maxPlan) {
          maxPlan = item.planLeadCount;
        }
      });
      data?.factPipeStatusList?.forEach((item) => {
        let percent =
          mathFloor(
            (Number(item?.actualLeadCount) || 1) /
              (item?.planLeadCount || maxPlan),
            2
          ) * 100;
        let residue = 0;
        if (percent >= 100) {
          percent = 100;
        } else {
          residue = 100 - percent;
        }
        newData.push({
          id: item.pipeStatusResponse.name,
          data: [
            {
              x: item.pipeStatusResponse.name,
              y: percent,
              color: item.pipeStatusResponse.color,
            },
            {
              x: item.pipeStatusResponse.name + '$20$',
              y: residue,
              color: '#F0F3FF',
            },
          ],
        });
      });
      setChartData(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className={'w-[425px] h-[415px] relative m-0'}>
      <ResponsiveRadialBar
        data={chartData}
        startAngle={-180}
        endAngle={180}
        maxValue={100}
        innerRadius={0.5}
        padding={0.6}
        cornerRadius={4}
        tracksColor='#F0F3FF'
        margin={{ top: 32, right: 32, bottom: 32, left: 32 }}
        colors={{ datum: 'data.color' }}
        enableRadialGrid={false}
        isInteractive={true}
        tooltip={(e) => {
          let name = e.bar.category;
          name = name.replace('$20$', '');
          const pipe = data?.factPipeStatusList.find(
            (item) => item.pipeStatusResponse.name === name
          );
          return (
            <div className={'bg-white py-1 px-2 rounded-md'}>
              <div className={'flex gap-1 items-center'}>
                <ShapeIconCrm color={pipe?.pipeStatusResponse.color} />
                <span className={'text-[12px] leading-[15px] font-medium'}>
                  {pipe?.pipeStatusResponse.name}
                </span>
                <span className={'text-[12px] leading-[15px] font-medium'}>
                  {pipe?.planLeadCount} / {pipe?.actualLeadCount}
                </span>
              </div>
            </div>
          );
        }}
        radialAxisStart={null}
        enableCircularGrid={false}
        circularAxisOuter={null}
      />
      <div
        className={
          'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
        }
      >
        <span
          className={
            'text-[#4d968a] block text-[16px] leading-[19px] font-medium text-center'
          }
        >
          {priceSeparator(Number(data?.planAmount))}
        </span>
        <span
          className={
            'text-[#4d968a] block text-[16px] leading-[19px] font-medium text-center'
          }
        >
          {t('Sotuv summasi')}
        </span>
        <span
          className={
            'text-[#006858] block text-[32px] leading-[38px] font-bold text-center'
          }
        >
          {priceSeparator(Number(data?.actualAmount))}
        </span>
      </div>
    </div>
  );
};

export default ChartContainer;
