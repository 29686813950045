import React, { useState } from 'react';
import { useQuery } from 'react-query';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useNotification from 'hooks/useNotification';
import useCurrency from 'hooks/useCurrency';
import useSearch from 'hooks/useSearch';
import moment from 'moment/moment';
import { CircularProgress } from '@mui/material';
import DateRangeFilter from 'components/ui/filters/items/DateRangeFilter';
import { NumericFormat } from 'react-number-format';
import { t } from 'i18next';

export default function ProfileSale() {
  const axiosPrivate = useAxiosPrivate();
  const sendNotification = useNotification();
  const { currencyData } = useCurrency();
  const date = {
    from: moment().startOf('month').format('YYYY-MM-DD'),
    till: moment().endOf('month').format('YYYY-MM-DD'),
  };
  const query = useSearch();
  const from = query.get('from');
  const till = query.get('till');

  const saleData = useQuery({
    queryKey: ['/admin/profile/sale', from, till],
    queryFn: async () => {
      const response = await axiosPrivate.get(
        `/admin/profile/sale?from=${from || date?.from}&till=${
          till || date?.till
        }`
      );
      return response?.data?.data;
    },
    // enabled: false,
    retry: 1,
    onError: (err) => {},
  });

  const { data } = saleData;
  const sumUzs =
    data?.valute == 0
      ? data?.sum?.toFixed(2)
      : (data?.sum * currencyData?.sum)?.toFixed(2);
  const sumUsd =
    data?.valute == 1
      ? data?.sum?.toFixed(2)
      : (data?.sum / currencyData?.sum)?.toFixed(2);

  //   if (saleData?.isFetching || saleData?.isLoading)
  //     return (
  //       <div className='h-full p-28 flex justify-center'>
  //         <CircularProgress size={40} />
  //       </div>
  //     );

  return (
    <div>
      <h1 className='text-[20px]'>{t('profile.currentBalance')}</h1>
      <h1 className='text-base-color text-[30px]'>
        <NumericFormat
          value={sumUsd || 0}
          allowNegative={false}
          displayType={'text'}
          thousandSeparator={' '}
          decimalScale={1}
          className='font-medium text-inherit font-semibold mr-1'
          suffix={' USD'}
        />
        |
        <NumericFormat
          value={sumUzs || 0}
          allowNegative={false}
          displayType={'text'}
          thousandSeparator={' '}
          decimalScale={1}
          className='font-medium text-inherit font-semibold ml-1'
          suffix={' UZS'}
        />
      </h1>
      <div className='flex justify-end'>
        <DateRangeFilter />
      </div>
    </div>
  );
}
